export enum EApiCallStateNames {
  DEMO = 'DEMO',
  ARCHIVE = 'ARCHIVE',
  ARCHIVE_SOLD = 'ARCHIVE_SOLD',
  FOLDER_DETAILS = 'FOLDER_DETAILS',
  FOLDERS_LIST = 'FOLDERS_LIST',
  LOANS_LIST = 'LOANS_LIST',
  LOANS_DETAILS = 'LOANS_DETAILS',
  SALES_LIST = 'SALES_LIST',
  SALES_DETAILS = 'SALES_DETAILS',
}

export enum EApiRequestPartKeys {
  SEARCH = 'SEARCH',
  PAGINATION = 'PAGINATION',
  FILTERS = 'FILTERS',
  SORTING = 'SORTING',
  ARCHIVE_YEAR_SORTING = 'ARCHIVE_YEAR_SORTING',
  ARCHIVE_LEVEL_1_SORTING = 'ARCHIVE_LEVEL_1_SORTING',
  ARCHIVE_LEVEL_2_SORTING = 'ARCHIVE_LEVEL_2_SORTING',
  ARCHIVE_LEVEL_3_SORTING = 'ARCHIVE_LEVEL_3_SORTING',
  FOLDER_NAME = 'FOLDER_NAME',
  FOLDER_ID_SORTING = 'FOLDER_ID_SORTING',
  FOLDER_NAME_SORTING = 'FOLDER_NAME_SORTING',
  FOLDER_IMAGE_SORTING = 'FOLDER_IMAGE_SORTING',
  FOLDER_CREATED_AT_SORTING = 'FOLDER_CREATED_AT_SORTING',
  LOAN_ID_SORTING = 'LOAN_ID_SORTING',
  LOAN_NAME_SORTING = 'LOAN_NAME_SORTING',
  LOAN_CREATED_AT_SORTING = 'LOAN_CREATED_AT_SORTING',
  LOAN_DATE_OUT_SORTING = 'LOAN_DATE_OUT_SORTING',
  LOAN_DATE_IN_SORTING = 'LOAN_DATE_IN_SORTING',
  LOAN_REFERENCE_SORTING = 'LOAN_REFERENCE_SORTING',
  SALE_ID_SORTING = 'SALE_ID_SORTING',
  SALE_NAME_SORTING = 'SALE_NAME_SORTING',
  SALE_DATE_SORTING = 'SALE_DATE_SORTING',
  SALE_REFERENCE_SORTING = 'SALE_REFERENCE_SORTING',
  SALE_CREATED_AT_SORTING = 'SALE_CREATED_AT_SORTING',
}
