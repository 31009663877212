<mpa-header *ngIf="storeService.store.activeBreakpoints.isWebLayout"
  [title]="'QR_SCANNER.TITLE' | translate"></mpa-header>

<div class="container-fluid py-3">
  <h1>{{'QR_SCANNER.TITLE' | translate}}</h1>

  <div class="row mt-5">

    <!-- Scan QR Code -->
    <div class="col-24 col-md-6">
      <h3>{{'QR_SCANNER.SCANNING_QR_CODE_TO_SEARCH' | translate}}</h3>

      <button mat-raised-button
        color="primary"
        (click)="onScan()">
        <mat-icon class="mr-2">qr_code_scanner</mat-icon>
        {{'QR_SCANNER.SCAN_QR_CODE' | translate}}
      </button>
    </div>

    <div class="col-24 col-md-2 d-flex flex-row flex-md-column my-4 my-md-0 align-items-center">
      <span class="mr-4 mr-md-0 mb-0 mb-md-4">{{'COMMON.LABEL.OR' | translate}}</span>
      <mat-divider class="w-100 d-block d-md-none"></mat-divider>
      <mat-divider class="h-100 d-none d-md-block"
        [vertical]="true"></mat-divider>
    </div>

    <!-- Search by inventory code -->
    <div class="col-24 col-md-11">
      <h3>{{'QR_SCANNER.TYPE_INVENTORY_CODE_AND_SEARCH' | translate}}</h3>

      <form (ngSubmit)="onSearchInventory()"
        [formGroup]="form"
        class="d-flex flex-column">
        <div>
          <mat-form-field appearance="outline">
            <mat-label>{{'COMMON.LABEL.INVENTORY' | translate}}</mat-label>
            <input matInput
              placeholder="{{'COMMON.LABEL.INVENTORY' | translate}}"
              formControlName="inventory">
          </mat-form-field>
        </div>

        <div>
          <button mat-stroked-button
            color="primary"
            [disabled]="!form.valid"
            type="submit">
            {{'COMMON.LABEL.SEARCH' | translate}}
          </button>
        </div>
      </form>
    </div>

  </div>

  <!-- Camera -->
  <div *ngIf="showCamera"
    class="camera-bg">
    <div class="w-100 position-fixed fixed-top d-flex justify-content-end pt-4 pr-4">
      <button mat-mini-fab
        (click)="onCloseCamera()">
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </div>

  <div class="video-container"
    [ngClass]="{'show-video': showCamera, 'hide-video': !showCamera}">
    <video #videoElem>
    </video>
  </div>

</div>