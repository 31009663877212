import {
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
  OnDestroy,
  OnChanges,
  SimpleChanges,
  Component,
} from '@angular/core';
import { EApiRequestPartKeys, EBitfApiRequestPartsNames } from '@web/enums';
import { ApiCallStateService } from '@web/core/services';
import { BitfApiRequestPart } from '../../../api-call-state/bitf-api-request-part';
import { IBitfApiPagination } from '../../../services/api/bitf-api.interface';

@Component({
  selector: 'bitf-infinite-scroll-trigger',
  template: '',
})
export class BitfInfiniteScrollTriggerComponent implements OnInit, OnDestroy, OnChanges {
  @Input() isLoading = false;
  @Input() isActive = false;
  @Input() paginationInfo = '';
  @Input() pagination: IBitfApiPagination;
  @Input() options?: IntersectionObserverInit;
  @Input() apiCallStateName?: string;
  @Input() apiRequestPartKey?: EApiRequestPartKeys = EApiRequestPartKeys.PAGINATION;
  @Input() apiRequestPartName?: EBitfApiRequestPartsNames;
  @Output() scrolled = new EventEmitter();

  @ViewChild('anchor', { static: true }) anchorEl: ElementRef<any>;

  observer: IntersectionObserver;
  requestPart: BitfApiRequestPart;

  constructor(private apiCallStateService: ApiCallStateService) {}

  ngOnInit() {
    if (this.apiCallStateName) {
      this.requestPart = this.apiCallStateService.getRequestPart(
        this.apiCallStateName,
        this.apiRequestPartKey
      );
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.isLoading && changes.isLoading.currentValue !== changes.isLoading.previousValue) {
      if (this.isLoading) {
        this.removeObserver();
      } else {
        const options: IntersectionObserverInit = {
          ...this.options,
        };
        this.observer = new IntersectionObserver(entries => {
          entries.forEach(entry => {
            if (entry.isIntersecting) {
              this.scrolled.emit();
              this.triggerApiCallSateUpdate();
            }
          });
        }, options);

        // this timeout is needed to avoid to show the infinite loader before the ui has rendered the
        // items in the list. this scenario is for the first load when the list it's empty.
        setTimeout(() => {
          this.observer.observe(this.anchorEl.nativeElement);

          if (changes.pagination && changes.pagination.currentValue !== changes.pagination.previousValue) {
            const isLastPage = this.pagination.page === this.pagination.totalPages;
            if (isLastPage) {
              this.removeObserver();
            }
          }
        }, 0);
      }
    }
  }

  private triggerApiCallSateUpdate() {
    if (this.apiCallStateName) {
      const page = this.pagination.page ? this.pagination.page + 1 : undefined;

      if (page !== undefined) {
        this.apiCallStateService.setStore(() => {
          this.requestPart.data = { ...this.requestPart.data, page };
        }, this.apiCallStateName);
      } else if (this.paginationInfo !== undefined) {
        this.apiCallStateService.setStore(() => {
          this.requestPart.data = { paginationInfo: this.paginationInfo };
        }, this.apiCallStateName);
      }
    }
  }

  private removeObserver() {
    if (this.observer) {
      this.observer.disconnect();
    }
  }

  ngOnDestroy() {
    this.removeObserver();
  }
}
