import { Injectable, Injector } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';

import { marker as bitfToTranslate } from '@biesbjerg/ngx-translate-extract-marker';

import { CONSTANTS } from '@constants';
import { BITF_CONFIGS } from '@configs';
import { IBitfUiMessages, IBitfApiUiMessage, IBitfToastData } from '@interfaces';
import {
  AppSessionService,
  ToastMessagesService,
  DialogsService,
  StoreService,
  AuthService,
} from '@services';
import { EBitfUiMessageType, EBitfUiMessageTarget } from '@enums';

@Injectable({
  providedIn: 'root',
})
export abstract class BitfUiMessagesListenerService {
  isSessionExpiredNotified = false;
  apiErrorsInterceptorStrategies = {};
  graphQlErrorsStrategies = {};
  uiMessagesStrategies = {};
  protected appSessionService: AppSessionService;
  protected translateService: TranslateService;
  protected router: Router;
  protected toastMessagesService: ToastMessagesService;
  protected dialogsService: DialogsService;
  protected storeService: StoreService;
  protected authService: AuthService;

  constructor(protected injector: Injector) {}

  init() {
    this.appSessionService = this.injector.get(AppSessionService);
    this.translateService = this.injector.get(TranslateService);
    this.router = this.injector.get(Router);
    this.toastMessagesService = this.injector.get(ToastMessagesService);
    this.dialogsService = this.injector.get(DialogsService);
    this.storeService = this.injector.get(StoreService);
    this.authService = this.injector.get(AuthService);
    this.initStrategies();
    this.parseUiMessages();
  }

  initStrategies() {
    this.initApiErrorsInterceptorSrategies();
    this.initUiMessagesStrategies();
  }

  parseUiMessages() {
    this.storeService.store.uiMessages$.subscribe((uiMessage: IBitfUiMessages) => {
      switch (uiMessage.type) {
        case 'BitfApiErrorsInterceptor':
          if (this.apiErrorsInterceptorStrategies[uiMessage.strategy]) {
            this.apiErrorsInterceptorStrategies[uiMessage.strategy](uiMessage);
          }
          break;
        case 'BitfUiMessages':
          if (this.uiMessagesStrategies[uiMessage.strategy]) {
            this.uiMessagesStrategies[uiMessage.strategy](uiMessage);
          }
          break;
      }
    });
  }

  initApiErrorsInterceptorSrategies() {
    const showErrorMEssage = ({ payload }) => {
      this.toastMessagesService.show({
        title: this.translateService.instant(bitfToTranslate('BITF.LABEL.ERROR')) || '',
        message: `${
          this.translateService.instant(bitfToTranslate('BITF.TOAST.HTTP_ERROR.DESCRIPTION')) || ''
        } : ${payload}`,
        type: EBitfUiMessageType.ERROR,
      });
    };
    this.apiErrorsInterceptorStrategies['4XX'] = showErrorMEssage;
    this.apiErrorsInterceptorStrategies['5XX'] = showErrorMEssage;
    this.apiErrorsInterceptorStrategies['0'] = showErrorMEssage;
  }

  initUiMessagesStrategies() {
    this.uiMessagesStrategies[EBitfUiMessageTarget.TOAST] = (uiMessage: IBitfUiMessages) => {
      this.displayToastMessage(uiMessage, { duration: BITF_CONFIGS.toastMessage.uiMessageDurationMs });
    };

    this.uiMessagesStrategies[EBitfUiMessageTarget.CONSOLE] = (uiMessage: IBitfUiMessages) => {
      const payload: IBitfApiUiMessage = uiMessage.payload;
      console.log(payload);
    };

    this.uiMessagesStrategies[EBitfUiMessageTarget.FULL_SCREEN] = (uiMessage: IBitfUiMessages) => {
      this.displayFullScreenMessage(uiMessage);
    };
  }

  displayToastMessage(uiMessage: IBitfUiMessages, options: any = {}) {
    const payload: IBitfToastData = uiMessage.payload;
    if (uiMessage.payload.durationMs != null) {
      options.duration = uiMessage.payload.durationMs;
    }
    this.toastMessagesService.show(
      Object.assign(
        {
          title: payload.title || '',
          message: payload.message || '',
          type: payload.type,
        },
        options
      )
    );
  }

  displayFullScreenMessage(uiMessage: IBitfUiMessages, options = {}) {
    const dialogSize = this.storeService.store.activeBreakpoints.isWebLayout ? 'auto' : '100%';
    const dialogSizeMax = this.storeService.store.activeBreakpoints.isWebLayout ? '750px' : '100%';
    const payload: IBitfApiUiMessage = uiMessage.payload;
    this.dialogsService.dialog.open(
      CONSTANTS.fullScreenMessageComponent,
      Object.assign(
        {
          width: dialogSize,
          maxWidth: dialogSizeMax,
          height: dialogSize,
          maxHeight: dialogSizeMax,
          data: payload,
        },
        options
      )
    );
  }
}
