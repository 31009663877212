import { IMAGE_ENTITY } from './images.gql';

export const SUIT_ENTITY = `
  fragment SuitEntity on SuitEntity {
    id
    attributes {
      name
      archives {
        data {
          id
          attributes {
            description
            inventory
          }
        }
      }
    }
  }
`;

export const SUIT_ENTITY_WITH_RELATIONS = `
  ${IMAGE_ENTITY}
  fragment SuitEntityWithRelations on SuitEntity {
    id
    attributes {
      name
      archives {
        data {
          id
          attributes {
            description
            inventory
            images {
              data {
                ...ImageEntity
              }
            }
          }
        }
      }
    }
  }
`;
