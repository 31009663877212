import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Archive, ArchiveLoan, Loan } from '@common/core/models';
import { ArchiveLoansService, LoaderService, LoansService, StoreService } from '@common/core/services';
import { EBitfCloseEventStatus, EBitfUiMessageType } from '@common/enums';
import { IBitfCloseEvent, IBitfGraphQlResponse, ICreateUpdateLoanDialogData } from '@common/interfaces';
import { marker as bitfToTranslate } from '@biesbjerg/ngx-translate-extract-marker';
import { TranslateService } from '@ngx-translate/core';
import { ToastMessagesService } from '@web/core/services';
import { finalize, switchMap, tap } from 'rxjs/operators';
import { CreateUpdateLoanComponent } from '../create-update-loan/create-update-loan.component';
@Component({
  selector: 'mpa-create-loan-dialog',
  templateUrl: './create-loan-dialog.component.html',
  styleUrls: ['./create-loan-dialog.component.scss'],
})
export class CreateLoanDialogComponent implements OnInit {
  @ViewChild(CreateUpdateLoanComponent) createUpdateLoanComponent: CreateUpdateLoanComponent;
  loan: Loan;

  constructor(
    public dialogRef: MatDialogRef<CreateLoanDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: ICreateUpdateLoanDialogData,
    private loansService: LoansService,
    private archiveLoansService: ArchiveLoansService,
    private loaderService: LoaderService,
    private storeService: StoreService,
    private toastMessagesService: ToastMessagesService,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.loan = this.dialogData.loan;
  }

  onSave() {
    const body = new Loan({}, { ...this.createUpdateLoanComponent.form.value });
    const validItems = this.dialogData.items.filter(item => !item.isInLoanOut);

    if (validItems.length < this.dialogData.items.length) {
      this.toastMessagesService.show({
        title: this.translateService.instant(bitfToTranslate('LOAN.SOME_ITEMS_ARE_ALREADY_IN_LOAN_AND_OUT')),
        type: EBitfUiMessageType.INFO,
      });
    }
    if (!validItems.length) {
      this.dialogRef.close({
        status: EBitfCloseEventStatus.OK,
      } as IBitfCloseEvent<void>);
      return;
    }

    this.loaderService.show();
    this.loansService
      .create({ body, disableHideLoader: true })
      .pipe(
        switchMap((response: IBitfGraphQlResponse<Loan>) => {
          this.loan = response.content;

          const observableList = validItems.map(item => {
            const archiveLoan = new ArchiveLoan(undefined, {
              archive: new Archive(item),
              loan: response.content,
              date_out: response.content.date_out,
            });
            return this.archiveLoansService
              .createItem({
                body: archiveLoan,
                disableHideLoader: true,
              })
              .pipe(
                // NOTE: we've to update the gallery items with the new archive_loans
                // to eventually show the loan icon
                tap((r: IBitfGraphQlResponse<ArchiveLoan>) => {
                  this.storeService.updateGalleryItem({ id: r.content.archive.id, disableHideLoader: true });
                })
              );
          });

          return this.archiveLoansService.getThrottledObservables(observableList);
        }),
        finalize(() => {
          this.loaderService.hide();
        })
      )
      .subscribe(() => {
        this.toastMessagesService.show({
          type: EBitfUiMessageType.SUCCESS,
          title: this.translateService.instant(bitfToTranslate('LOAN.TOAST.LOAN_CREATED'), {
            loan: body.name,
          }),
        });

        this.dialogRef.close({
          status: EBitfCloseEventStatus.OK,
          data: this.loan,
        } as IBitfCloseEvent<Loan>);
      });
  }

  onClose() {
    this.dialogRef.close({
      status: EBitfCloseEventStatus.CANCEL,
    } as IBitfCloseEvent<void>);
  }
}
