import { Archive } from './archive.model';
import { SuperStrapiModel } from './super-strapi.model';

export class Folder extends SuperStrapiModel {
  name: string;
  archives: Archive[];
  is_image_folder: boolean;
  is_visible_to_editor: boolean;
  is_visible_to_collaborator: boolean;
  is_visible_to_ufficio_stile: boolean;
  is_visible_to_viewer: boolean;

  get numberOfArchives() {
    return this.archives?.length || 0;
  }

  constructor(data, modelInstance?: Partial<Folder>) {
    super(data, modelInstance);

    if (modelInstance) {
      return;
    }

    if (data?.attributes?.archives) {
      this.archives = data.attributes.archives.data.map(archive => new Archive(archive));
    }
  }

  get serialised() {
    return {
      id: this.id,
      name: this.name,
      archives: !!this.archives ? this.archives.map(item => item.serialised.id) : this.archives,
      is_image_folder: this.is_image_folder,
      is_visible_to_editor: this.is_visible_to_editor,
      is_visible_to_collaborator: this.is_visible_to_collaborator,
      is_visible_to_ufficio_stile: this.is_visible_to_ufficio_stile,
      is_visible_to_viewer: this.is_visible_to_viewer,
    };
  }
}
