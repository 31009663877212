import { bitfFormatDateISO } from '@common/libs/bitforce/utils/bitf-dates.utils';
import { Sale } from './sale.model';
import { SuperStrapiModel } from './super-strapi.model';
import { Archive } from './archive.model';

export class ArchiveSale extends SuperStrapiModel<ArchiveSale> {
  note_out: string;
  sale: Sale;
  archive: Archive;

  constructor(data, modelInstance?: Partial<ArchiveSale>) {
    super(data, modelInstance);

    if (modelInstance) {
      return;
    }

    if (!data?.attributes?.note_out) {
      this.note_out = undefined;
    }

    if (data.attributes?.sale?.data) {
      this.sale = new Sale(data.attributes?.sale.data);
    }

    if (data.attributes?.archive) {
      this.archive = new Archive(data.attributes.archive.data);
    }
  }

  // NOTE: We need this otherwise an empty array will remove all relations
  prepareForBulkUpdate() {
    // The select can't send undefined, it casts to null, so we've to
    // set as undefined all null props. this happen for autocomplete too
    Object.keys(this).forEach(key => {
      if (this.hasOwnProperty(key)) {
        if (this[key] === null) {
          this[key] = undefined;
        }
      }
    });
  }

  get serialised() {
    return {
      id: this.id,
      note_out: this.note_out,
      sale: !!this.sale ? this.sale.serialised.id : this.sale,
      archive: !!this.archive ? this.archive.serialised.id : this.archive,
    };
  }
}
