export const IMAGE_ENTITY = `
  fragment ImageEntity on UploadFileEntity {
    id
    attributes {
      name
      formats
      width
      height
      ext
      placeholder
      url
    }
  }
`;
