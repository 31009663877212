import { BitfApiRequestPart } from '../bitf-api-request-part';
import { EApiRequestPartKeys, EBitfApiRequestPartsNames } from '@enums';

export abstract class BitfGraphQlSearchApiRequestPart extends BitfApiRequestPart {
  constructor({ key = EApiRequestPartKeys.SEARCH, partName = EBitfApiRequestPartsNames.SEARCH } = {}) {
    super(key, partName);
  }

  protected abstract getSingleKeySearchString(key: string): any;
  protected abstract getMultipleKeySearchString(): any;

  build(): void {
    let searchPart = '';
    const numberOfKeys = Object.keys(this.formValue).length;
    const firstKey = Object.keys(this.formValue)[0];
    if (numberOfKeys === 0) {
      return;
    }
    // NOTE the value is the same for all keys, if the first is empty all the others are empty
    if (this.formValue[firstKey] === undefined || this.formValue[firstKey] === '') {
      this.part = '';
      return;
    }
    if (numberOfKeys === 1) {
      const key = firstKey;
      searchPart = this.getSingleKeySearchString(key);
    } else if (numberOfKeys > 1) {
      searchPart = this.getMultipleKeySearchString();
    }
    this.part = searchPart;
  }
}
