import { SuperStrapiModel } from './super-strapi.model';

export class Shelf extends SuperStrapiModel {
  name: string;
  constructor(data = {}) {
    super(data);
  }
  get serialised() {
    return {
      id: this.id,
      name: this.name,
    };
  }
}
