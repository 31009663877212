import { Archive } from './archive.model';
import { SuitTypology } from './suit-typology.model';
import { SuperStrapiModel } from './super-strapi.model';

export class Suit extends SuperStrapiModel {
  name: string;
  s_t_1: SuitTypology;
  archives: Archive[];

  constructor(data?: any, modelInstance?: Partial<Suit>) {
    super(data, modelInstance);

    if (modelInstance) {
      return;
    }

    if (data?.attributes?.s_t_1?.data) {
      this.s_t_1 = new SuitTypology(data?.attributes?.s_t_1.data);
    }

    if (data?.attributes?.archives?.data) {
      this.archives = data?.attributes?.archives.data.map(archive => new Archive(archive));
    }
  }

  get serialised() {
    return {
      id: this.id,
      name: this.name,
      s_t_1: !!this.s_t_1 ? this.s_t_1.serialised.id : this.s_t_1,
      archives: !!this.archives ? this.archives.map(archive => archive.serialised.id) : this.archives,
    };
  }
}
