import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'bitfJoinArray',
})
export class BitfJoinArrayPipe implements PipeTransform {
  transform(array: any[], property: string = null): string {
    return (array || [])
      .map(item => {
        if (!property) {
          return item;
        } else {
          return item[property];
        }
      })
      .join(', ');
  }
}
