import {
  BitfGraphQlPaginationApiRequestPart,
  BitfGraphQlSortApiRequestPart,
} from '@common/libs/bitforce/core/api-call-state/bitf-graph-ql-api-call-state';
import { BitfStrapiGraphQlSearchApiRequestPart } from '@common/libs/bitforce/core/api-call-state/bitf-strapi-graph-ql-api-call-state';

import {
  EApiCallStateNames,
  EApiRequestPartKeys,
  EBitfApiRequestPartsNames,
  EBitfApiSortDirection,
} from '@enums';
import { IBitfApiCallState } from '@interfaces';
import { DefaultApiCallStateMapper } from './default-api-call-state.mapper';
import { FiltersApiRequestPart } from './filters-request-part.builder';

export const apiCallStateConfig = [
  {
    apiCallStateName: EApiCallStateNames.ARCHIVE,
    requestParts: [
      new BitfGraphQlPaginationApiRequestPart({
        key: EApiRequestPartKeys.PAGINATION,
        initialPagination: { page: 1, size: 24 },
        options: { pageIndexOffset: 1 },
      }),
      new BitfGraphQlSortApiRequestPart({
        key: EApiRequestPartKeys.ARCHIVE_LEVEL_1_SORTING,
        partName: EBitfApiRequestPartsNames.SORTING,
        defaultPartData: {
          data: { property: 'inventory', direction: EBitfApiSortDirection.ASC },
        },
      }),
      new BitfGraphQlSortApiRequestPart({
        key: EApiRequestPartKeys.ARCHIVE_LEVEL_2_SORTING,
        partName: EBitfApiRequestPartsNames.SORTING,
      }),
      new BitfGraphQlSortApiRequestPart({
        key: EApiRequestPartKeys.ARCHIVE_LEVEL_3_SORTING,
        partName: EBitfApiRequestPartsNames.SORTING,
      }),
      new FiltersApiRequestPart({
        defaultPartData: {
          formValue: {
            is_sold: false,
          },
        },
      }),
      new BitfStrapiGraphQlSearchApiRequestPart(),
    ],
    apiCallStateMapper: new DefaultApiCallStateMapper(),
  } as IBitfApiCallState,
  {
    apiCallStateName: EApiCallStateNames.ARCHIVE_SOLD,
    requestParts: [
      new BitfGraphQlPaginationApiRequestPart({
        key: EApiRequestPartKeys.PAGINATION,
        initialPagination: { page: 1, size: 24 },
        options: { pageIndexOffset: 1 },
      }),
      new BitfGraphQlSortApiRequestPart({
        key: EApiRequestPartKeys.ARCHIVE_LEVEL_1_SORTING,
        partName: EBitfApiRequestPartsNames.SORTING,
        defaultPartData: {
          data: { property: 'sale.date', direction: EBitfApiSortDirection.DESC },
        },
      }),
      new BitfGraphQlSortApiRequestPart({
        key: EApiRequestPartKeys.ARCHIVE_LEVEL_2_SORTING,
        partName: EBitfApiRequestPartsNames.SORTING,
      }),
      new BitfGraphQlSortApiRequestPart({
        key: EApiRequestPartKeys.ARCHIVE_LEVEL_3_SORTING,
        partName: EBitfApiRequestPartsNames.SORTING,
      }),
      new FiltersApiRequestPart({
        defaultPartData: {
          formValue: {
            is_sold: true,
          },
        },
      }),
      new BitfStrapiGraphQlSearchApiRequestPart(),
    ],
    fixedSortPartsOrder: true,
    apiCallStateMapper: new DefaultApiCallStateMapper(),
  } as IBitfApiCallState,

  {
    apiCallStateName: EApiCallStateNames.FOLDERS_LIST,
    requestParts: [
      new BitfGraphQlPaginationApiRequestPart({
        key: EApiRequestPartKeys.PAGINATION,
        initialPagination: { page: 1, size: 50 },
        options: { pageIndexOffset: 1 },
      }),
      new BitfGraphQlSortApiRequestPart({
        key: EApiRequestPartKeys.FOLDER_ID_SORTING,
        partName: EBitfApiRequestPartsNames.SORTING,
      }),
      new BitfGraphQlSortApiRequestPart({
        key: EApiRequestPartKeys.FOLDER_NAME_SORTING,
        partName: EBitfApiRequestPartsNames.SORTING,
      }),
      new BitfGraphQlSortApiRequestPart({
        key: EApiRequestPartKeys.FOLDER_IMAGE_SORTING,
        partName: EBitfApiRequestPartsNames.SORTING,
      }),
      new BitfGraphQlSortApiRequestPart({
        key: EApiRequestPartKeys.FOLDER_CREATED_AT_SORTING,
        partName: EBitfApiRequestPartsNames.SORTING,
        defaultPartData: {
          data: { property: 'createdAt', direction: EBitfApiSortDirection.DESC },
        },
      }),
      new FiltersApiRequestPart({
        key: EApiRequestPartKeys.FILTERS,
      }),
      new BitfStrapiGraphQlSearchApiRequestPart(),
    ],
    apiCallStateMapper: new DefaultApiCallStateMapper(),
  } as IBitfApiCallState,
  {
    apiCallStateName: EApiCallStateNames.FOLDER_DETAILS,
    requestParts: [
      new BitfGraphQlPaginationApiRequestPart({
        key: EApiRequestPartKeys.PAGINATION,
        initialPagination: { page: 1, size: 24 },
        options: { pageIndexOffset: 1 },
      }),
      new BitfGraphQlSortApiRequestPart({
        key: EApiRequestPartKeys.ARCHIVE_LEVEL_1_SORTING,
        partName: EBitfApiRequestPartsNames.SORTING,
        defaultPartData: {
          data: { property: 'id', direction: EBitfApiSortDirection.ASC },
        },
      }),
      new BitfGraphQlSortApiRequestPart({
        key: EApiRequestPartKeys.ARCHIVE_LEVEL_2_SORTING,
        partName: EBitfApiRequestPartsNames.SORTING,
      }),
      new BitfGraphQlSortApiRequestPart({
        key: EApiRequestPartKeys.ARCHIVE_LEVEL_3_SORTING,
        partName: EBitfApiRequestPartsNames.SORTING,
      }),
      new FiltersApiRequestPart(),
      new BitfStrapiGraphQlSearchApiRequestPart(),
    ],
    apiCallStateMapper: new DefaultApiCallStateMapper(),
  } as IBitfApiCallState,

  {
    apiCallStateName: EApiCallStateNames.LOANS_LIST,
    requestParts: [
      new BitfGraphQlPaginationApiRequestPart({
        key: EApiRequestPartKeys.PAGINATION,
        initialPagination: { page: 1, size: 12 },
        options: { pageIndexOffset: 1 },
      }),
      new BitfGraphQlSortApiRequestPart({
        key: EApiRequestPartKeys.LOAN_ID_SORTING,
        partName: EBitfApiRequestPartsNames.SORTING,
      }),
      new BitfGraphQlSortApiRequestPart({
        key: EApiRequestPartKeys.LOAN_NAME_SORTING,
        partName: EBitfApiRequestPartsNames.SORTING,
      }),
      new BitfGraphQlSortApiRequestPart({
        key: EApiRequestPartKeys.LOAN_DATE_OUT_SORTING,
        partName: EBitfApiRequestPartsNames.SORTING,
      }),
      new BitfGraphQlSortApiRequestPart({
        key: EApiRequestPartKeys.LOAN_DATE_IN_SORTING,
        partName: EBitfApiRequestPartsNames.SORTING,
      }),
      new BitfGraphQlSortApiRequestPart({
        key: EApiRequestPartKeys.LOAN_REFERENCE_SORTING,
        partName: EBitfApiRequestPartsNames.SORTING,
      }),
      new BitfGraphQlSortApiRequestPart({
        key: EApiRequestPartKeys.LOAN_CREATED_AT_SORTING,
        partName: EBitfApiRequestPartsNames.SORTING,
        defaultPartData: {
          data: { property: 'createdAt', direction: EBitfApiSortDirection.DESC },
        },
      }),
      new BitfStrapiGraphQlSearchApiRequestPart(),
      new FiltersApiRequestPart({
        key: EApiRequestPartKeys.FILTERS,
      }),
    ],
    apiCallStateMapper: new DefaultApiCallStateMapper(),
  } as IBitfApiCallState,
  {
    apiCallStateName: EApiCallStateNames.LOANS_DETAILS,
    requestParts: [
      new BitfGraphQlPaginationApiRequestPart({
        key: EApiRequestPartKeys.PAGINATION,
        initialPagination: { page: 1, size: 100 },
        options: { pageIndexOffset: 1 },
      }),
    ],
    apiCallStateMapper: new DefaultApiCallStateMapper(),
  } as IBitfApiCallState,

  {
    apiCallStateName: EApiCallStateNames.SALES_LIST,
    requestParts: [
      new BitfGraphQlPaginationApiRequestPart({
        key: EApiRequestPartKeys.PAGINATION,
        initialPagination: { page: 1, size: 12 },
        options: { pageIndexOffset: 1 },
      }),
      new BitfGraphQlSortApiRequestPart({
        key: EApiRequestPartKeys.SALE_ID_SORTING,
        partName: EBitfApiRequestPartsNames.SORTING,
      }),
      new BitfGraphQlSortApiRequestPart({
        key: EApiRequestPartKeys.SALE_NAME_SORTING,
        partName: EBitfApiRequestPartsNames.SORTING,
      }),
      new BitfGraphQlSortApiRequestPart({
        key: EApiRequestPartKeys.SALE_CREATED_AT_SORTING,
        partName: EBitfApiRequestPartsNames.SORTING,
      }),
      new BitfGraphQlSortApiRequestPart({
        key: EApiRequestPartKeys.SALE_DATE_SORTING,
        partName: EBitfApiRequestPartsNames.SORTING,
        defaultPartData: {
          data: { property: 'date', direction: EBitfApiSortDirection.DESC },
        },
      }),
      new BitfGraphQlSortApiRequestPart({
        key: EApiRequestPartKeys.SALE_REFERENCE_SORTING,
        partName: EBitfApiRequestPartsNames.SORTING,
      }),
      new BitfStrapiGraphQlSearchApiRequestPart(),
      new FiltersApiRequestPart({
        key: EApiRequestPartKeys.FILTERS,
      }),
    ],
    apiCallStateMapper: new DefaultApiCallStateMapper(),
  } as IBitfApiCallState,
  {
    apiCallStateName: EApiCallStateNames.SALES_DETAILS,
    requestParts: [
      new BitfGraphQlPaginationApiRequestPart({
        key: EApiRequestPartKeys.PAGINATION,
        initialPagination: { page: 1, size: 100 },
        options: { pageIndexOffset: 1 },
      }),
    ],
    apiCallStateMapper: new DefaultApiCallStateMapper(),
  } as IBitfApiCallState,
];
