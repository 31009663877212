import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';

import { gql } from 'apollo-angular';

import { BitfGraphQlService } from '@bitf/services/graph-ql/bitf-graph-ql.service';

import { IBitfGraphQlRequest, IBitfGraphQlResponse } from '@interfaces';
import { Folder } from '@models';
import { FOLDER_ENTITY, FOLDER_ENTITY_WITH_ARCHIVES } from './folders.gql';
import { tap } from 'rxjs/operators';
import { PAGINATION_ENTITY } from './strapi.gql';

@Injectable({
  providedIn: 'root',
})
// NOTE: Add methods following CRUD order
export class FoldersService extends BitfGraphQlService {
  constructor(public injector: Injector) {
    super(injector);
  }

  get(requestParams: IBitfGraphQlRequest): Observable<IBitfGraphQlResponse<Folder[]>> {
    requestParams.modelMapper = 'folders';
    requestParams.query = gql`
      ${FOLDER_ENTITY}
      ${PAGINATION_ENTITY}
      query Query($pagination: PaginationArg, $sort: [String], $filters: FolderFiltersInput) {
        folders(pagination: $pagination, sort: $sort, filters: $filters) {
          data {
            ...FolderEntity
          }
          meta {
            pagination {
              ...PaginationEntity
            }
          }
        }
      }
    `;

    return super.query<Folder[]>(requestParams);
  }

  create(requestParams: IBitfGraphQlRequest = {}) {
    requestParams.modelMapper = 'createFolder';
    requestParams.mutation = gql`
      ${FOLDER_ENTITY}
      mutation Create($input: FolderInput!) {
        createFolder(data: $input) {
          data {
            ...FolderEntity
          }
        }
      }
    `;

    return super.mutate<Folder>(requestParams);
  }

  getItemById(requestParams: IBitfGraphQlRequest): Observable<IBitfGraphQlResponse<Folder>> {
    requestParams.modelMapper = 'folder';
    requestParams.query = gql`
      ${FOLDER_ENTITY_WITH_ARCHIVES}
      query Query($id: ID) {
        folder(id: $id) {
          data {
            ...FolderEntityWithArchives
          }
        }
      }
    `;

    return super.query<Folder>(requestParams);
  }

  getList(requestParams: IBitfGraphQlRequest = {}): Observable<IBitfGraphQlResponse<Folder[]>> {
    requestParams.modelMapper = 'folders';
    requestParams.query = gql`
      ${FOLDER_ENTITY_WITH_ARCHIVES}
      ${PAGINATION_ENTITY}
      query Query($pagination: PaginationArg, $sort: [String], $filters: FolderFiltersInput) {
        folders(pagination: $pagination, sort: $sort, filters: $filters) {
          data {
            ...FolderEntityWithArchives
          }
          meta {
            pagination {
              ...PaginationEntity
            }
          }
        }
      }
    `;

    return super.query<Folder[]>(requestParams);
  }

  updateItem(
    requestParams: IBitfGraphQlRequest<Partial<Folder>> = {},
    folder?: Partial<Folder>
  ): Observable<IBitfGraphQlResponse<Folder>> {
    requestParams.modelMapper = 'updateFolder';
    requestParams.mutation = gql`
      ${FOLDER_ENTITY_WITH_ARCHIVES}
      mutation Update($id: ID!, $input: FolderInput!) {
        updateFolder(id: $id, data: $input) {
          data {
            ...FolderEntityWithArchives
          }
        }
      }
    `;
    return super.mutate<Folder>(requestParams, folder).pipe(
      tap((response: IBitfGraphQlResponse<Folder>) => {
        if (folder) {
          Object.assign(folder, response.content);
        }
      })
    );
  }

  deleteItem(requestParams: IBitfGraphQlRequest = {}) {
    requestParams.modelMapper = 'deleteFolder';
    requestParams.mutation = gql`
      mutation Mutate($id: ID!) {
        deleteFolder(id: $id) {
          data {
            id
          }
        }
      }
    `;
    return super.mutate<Folder>(requestParams);
  }
}
