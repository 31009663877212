import justClone from 'just-clone';

export abstract class SuperModel {
  id?: number;
  createdAt?: string | Date;
  updatedAt?: string | Date;

  constructor(object: any = {}) {
    Object.assign(this, object);
    if (!object) {
      return;
    }
  }

  abstract readonly serialised;

  get clone() {
    return justClone(this.serialised);
  }

  serialiseDate(date: Date) {
    if (!date || !(date instanceof Date)) {
      return '';
    }

    const monthNum = date.getMonth() + 1;
    const month = monthNum < 10 ? `0${monthNum}` : monthNum;
    const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
    return `${date.getFullYear()}-${month}-${day}`;
  }
}
