import { TYPOLOGY_NESTED_RELATIONS_ENTITY } from './cellar-typologies.gql';

export const FILTER = `
  ${TYPOLOGY_NESTED_RELATIONS_ENTITY}
  query Filters {
    typologies: t1S(pagination: { limit: 300 }, sort: ["name:ASC"]) {
      data {
        ...NestedTypologies
      }
    }
    
    apartments(pagination: { limit: 300 }, sort: ["isPriorityItem:DESC", "name:ASC"]) {
      data {
        id
        attributes {
          name
        }
      }
    }
    places(pagination: { limit: 300 }, sort: ["isPriorityItem:DESC", "name:ASC"]) {
      data {
        id
        attributes {
          name
        }
      }
    }
    shelves(pagination: { limit: 300 }, sort: ["isPriorityItem:DESC", "name:ASC"]) {
      data {
        id
        attributes {
          name
        }
      }
    }
    sectors(pagination: { limit: 300 }, sort: ["isPriorityItem:DESC", "name:ASC"]) {
      data {
        id
        attributes {
          name
        }
      }
    }
    boxes(pagination: { limit: 300 }, sort: ["isPriorityItem:DESC", "name:ASC"]) {
      data {
        id
        attributes {
          name
        }
      }
    }

    producers(pagination: { limit: 500 }, sort: ["name:ASC"]) {
      data {
        id
        attributes {
          name
          address
          city
          province
          zip
          country
          email
          phone
          vat_number
        }
      }
    }
    distributors(pagination: { limit: 500 }, sort: ["name:ASC"]) {
      data {
        id
        attributes {
          name
          address
          city
          province
          zip
          country
          email
          phone
          vat_number
        }
      }
    }
    races(pagination: { limit: 200 }, sort: ["name:ASC"]) {
      data {
        id
        attributes {
          name
          seasoning_months
        }
      }
    }
    sexs(pagination: { limit: 10 }, sort: ["name:ASC"]) {
      data {
        id
        attributes {
          name
        }
      }
    }
  }
`;
