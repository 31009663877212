import { EApiRequestPartKeys, EBitfApiRequestPartsNames } from '@enums';
import { BitfGraphQlSearchApiRequestPart } from '../bitf-graph-ql-api-call-state';

export class BitfStrapiGraphQlSearchApiRequestPart extends BitfGraphQlSearchApiRequestPart {
  constructor({ key = EApiRequestPartKeys.SEARCH, partName = EBitfApiRequestPartsNames.SEARCH } = {}) {
    super({ key, partName });
  }

  protected getSingleKeySearchString(key) {
    const value = this.formValue[key].replace('_', '\\_');
    if (key.includes('.')) {
      // NOTE: We need to create a nested object using the dot notation
      // e.g. "user.name" will be {user: {name: containsi: 'string'}}
      const keys = [...key.split('.'), 'containsi'];
      let obj = {};
      for (let i = keys.length - 1; i > 0; i--) {
        obj = { [keys[i]]: i === keys.length - 1 ? value : obj };
      }
      return { [keys[0]]: obj };
    } else {
      return { [key]: { containsi: value } };
    }
  }

  protected getMultipleKeySearchString() {
    return {
      or: Object.keys(this.formValue).map(key => this.getSingleKeySearchString(key)),
    };
  }
}
