import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { gql } from 'apollo-angular';

import { BitfGraphQlService } from '@common/libs/bitforce/core/services/graph-ql/bitf-graph-ql.service';
import { IBitfGraphQlRequest, IBitfGraphQlResponse } from '@interfaces';
import { BitfStrapiAuth } from '@bitf/core/models';

@Injectable({
  providedIn: 'root',
})
export class BitfStrapiJwtService extends BitfGraphQlService {
  constructor(public injector: Injector) {
    super(injector);
  }

  getToken(requestParams: IBitfGraphQlRequest = {}): Observable<IBitfGraphQlResponse<BitfStrapiAuth>> {
    requestParams.modelMapper = 'login';
    requestParams.mutation = gql`
      mutation Mutate($input: UsersPermissionsLoginInput!) {
        login(input: $input) {
          jwt
        }
      }
    `;
    return super.mutate<BitfStrapiAuth>(requestParams);
  }
}
