import { Component } from '@angular/core';
import { environment } from '@env/environment';
import { AppSessionService } from '@services';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  constructor(private appSessionService: AppSessionService) {
    this.appSessionService.init();

    this.updateAppIcons();
  }

  private updateAppIcons() {
    let faviconsPath = `assets/favicons/${environment.shortAppName.toLowerCase()}`;

    document.getElementById('favicon32')?.setAttribute('href', `${faviconsPath}/favicon-32x32.png`);
    document.getElementById('favicon16')?.setAttribute('href', `${faviconsPath}/favicon-16x16.png`);
    document.getElementById('faviconIco')?.setAttribute('href', `${faviconsPath}/favicon.ico`);
  }
}
