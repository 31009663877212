import { bitfFormatDateISO } from '@common/libs/bitforce/utils/bitf-dates.utils';
import { Archive } from './archive.model';
import { Loan } from './loan.model';
import { SuperStrapiModel } from './super-strapi.model';

export class ArchiveLoan extends SuperStrapiModel {
  date_in: Date;
  date_out: Date;
  note_in: string;
  note_out: string;
  office_note: string;
  loan: Loan;
  archive: Archive;

  get isIn() {
    const today = new Date();
    today.setHours(23, 59, 59);
    if (this.date_in && this.date_in.getTime() <= today.getTime()) {
      return true;
    }
    return false;
  }

  get isOut() {
    return !this.isIn;
  }

  constructor(data, modelInstance?: Partial<ArchiveLoan>) {
    super(data, modelInstance);

    if (modelInstance) {
      if (this.date_in && this.date_in instanceof Date === false) {
        this.date_in = new Date(this.date_in);
      }
      if (this.date_out && this.date_out instanceof Date === false) {
        this.date_out = new Date(this.date_out);
      }
      return;
    }

    if (!data?.attributes?.note_in) {
      this.note_in = undefined;
    }
    if (!data?.attributes?.note_out) {
      this.note_out = undefined;
    }
    if (!data?.attributes?.office_note) {
      this.office_note = undefined;
    }

    if (data.attributes?.loan?.data) {
      this.loan = new Loan(data.attributes?.loan.data);
    }

    if (data.attributes?.archive) {
      this.archive = new Archive(data.attributes.archive.data);
    }

    if (data.attributes?.date_in) {
      this.date_in = new Date(data.attributes?.date_in);
    } else {
      this.date_in = undefined;
    }
    if (data.attributes?.date_out) {
      this.date_out = new Date(data.attributes?.date_out);
    } else {
      this.date_out = undefined;
    }
  }

  // NOTE: We need this otherwise an empty array will remove all relations
  prepareForBulkUpdate() {
    // The select can't send undefined, it casts to null, so we've to
    // set as undefined all null props. this happen for autocomplete too
    Object.keys(this).forEach(key => {
      if (this.hasOwnProperty(key)) {
        if (this[key] === null) {
          this[key] = undefined;
        }
      }
    });
  }

  get serialised() {
    return {
      id: this.id,
      date_in: this.date_in && bitfFormatDateISO(this.date_in),
      date_out: this.date_out && bitfFormatDateISO(this.date_out),
      note_in: this.note_in,
      note_out: this.note_out,
      office_note: this.office_note,
      loan: !!this.loan ? this.loan.serialised.id : this.loan,
      archive: !!this.archive ? this.archive.serialised.id : this.archive,
    };
  }
}
