import { Injectable, Injector } from '@angular/core';
import { gql } from 'apollo-angular';

import { BitfGraphQlService } from '@bitf/services/graph-ql/bitf-graph-ql.service';
import { IBitfGraphQlRequest, IBitfGraphQlResponse } from '@interfaces';
import { Filter } from '@models';
// import { tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { environment } from '@env/environment';
import { EArchiveType } from '@web/enums';

@Injectable({
  providedIn: 'root',
})
// NOTE: Add methods following CRUD order
export class FiltersService extends BitfGraphQlService {
  constructor(public injector: Injector) {
    super(injector);
  }

  getAllFiltersValues(requestParams: IBitfGraphQlRequest = {}): Observable<IBitfGraphQlResponse<Filter>> {
    requestParams.modelMapper = 'filters';
    switch (environment.archiveType) {
      case EArchiveType.FASHION:
        requestParams.query = gql`
          ${require('@web/dynamic-modules/fashion/services/graph-ql/fashion-filters.gql').FILTER}
        `;
        break;
      case EArchiveType.MULTIMEDIA:
        requestParams.query = gql`
          ${require('@web/dynamic-modules/multimedia/services/graph-ql/multimedia-filters.gql').FILTER}
        `;
        break;
      case EArchiveType.CELLAR:
        requestParams.query = gql`
          ${require('@web/dynamic-modules/cellar/services/graph-ql/cellar-filters.gql').FILTER}
        `;
        break;
    }

    return super.query<Filter>(requestParams);
  }
}
