<form (ngSubmit)="onLink()"
  [formGroup]="form"
  class="mb-4"
  *ngIf="uiRoleManagerService.canI(eRoleActions.EDIT_ITEM)">
  <h4>{{'ITEM.DETAIL.LINK_NEW_ITEM' | translate}}</h4>

  <div class="d-flex align-items-center gap-2">
    <mat-form-field appearance="outline"
      class="form-field-condensed">
      <mat-label>{{'COMMON.LABEL.INVENTORY' | translate}}</mat-label>
      <input matInput
        formControlName="inventory">
    </mat-form-field>

    <button mat-raised-button
      color="primary"
      [disabled]="!form.valid"
      type="submit">
      {{'COMMON.LABEL.CREATE_LINK' | translate}}
    </button>
  </div>
</form>

<div class="items-list"
  *ngIf="selectedItem.links?.length">
  <h4>{{'ITEM.DETAIL.LINKED_ITEMS' | translate}}</h4>

  <mpa-linked-item-row *ngFor="let item of selectedItem.links"
    [item]="item"
    (removeLink)="onRemoveLink($event)"></mpa-linked-item-row>
</div>

<ng-container *ngIf="!selectedItem?.links?.length">
  <h4>{{'ITEM.DETAIL.EMPTY_ITEM_LINKS' | translate}}</h4>
</ng-container>