<div class="dialog-stretch">
  <!-- HEADER -->
  <div class="d-flex justify-content-between pt-2"
    mat-dialog-title>
    <h2 class="mb-0 mat-headline-6  w-100 d-flex align-items-center">{{'LOAN.CREATE' | translate}}</h2>
    <button mat-icon-button
      (click)="onClose()">
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <mat-dialog-content class="py-2">
    <mpa-create-update-loan [loan]="loan"></mpa-create-update-loan>
  </mat-dialog-content>

  <mat-dialog-actions align="end"
    class="d-flex align-items-center">
    <!-- ACTIONS -->
    <button mat-flat-button
      (click)="onClose()">
      {{ 'BITF.DIALOGS.BUTTON_CANCEL_TEXT' | translate }}
    </button>
    <button mat-flat-button
      color="primary"
      (click)="onSave()">
      {{ 'BITF.DIALOGS.BUTTON_OK_TEXT_TO_SAVE' | translate }}
    </button>
  </mat-dialog-actions>
</div>