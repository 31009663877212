import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Archive } from '@common/core/models';
import { EBitfCloseEventStatus, EBitfUiMessageType } from '@common/enums';
import { marker as bitfToTranslate } from '@biesbjerg/ngx-translate-extract-marker';
import { IBitfCloseEvent, ICreateUpdateSaleDialogData } from '@common/interfaces';
import { TranslateService } from '@ngx-translate/core';
import { ToastMessagesService } from '@web/core/services';

@Component({
  selector: 'mpa-quantity-sale-dialog',
  templateUrl: './quantity-sale-dialog.component.html',
  styleUrls: ['./quantity-sale-dialog.component.scss'],
})
export class QuantitySaleDialogComponent implements OnInit {
  items: Archive[];

  constructor(
    public dialogRef: MatDialogRef<QuantitySaleDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: ICreateUpdateSaleDialogData,
    private toastMessagesService: ToastMessagesService,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.items = this.dialogData.items.filter(item => !!item.quantity);

    if (this.items.length === 0) {
      this.toastMessagesService.show({
        title: this.translateService.instant(bitfToTranslate('SALE.NO_ITEMS_HAVE_QUANTITY')),
        type: EBitfUiMessageType.SUCCESS,
      });
      this.onClose();
      return;
    }

    if (this.items.length < this.dialogData.items.length) {
      this.toastMessagesService.show({
        title: this.translateService.instant(bitfToTranslate('SALE.SOME_ITEMS_DONT_HAVE_QUANTITY')),
        type: EBitfUiMessageType.SUCCESS,
      });
    }
  }

  onSave() {
    this.items.forEach(item => {
      item.selectedQuantity = item.selectedQuantity || 0;
    });
    const isAllValuesNonNegative = this.items.every(
      item => item.selectedQuantity >= 1 && item.selectedQuantity <= item.quantity
    );

    if (!isAllValuesNonNegative) {
      this.toastMessagesService.show({
        title: this.translateService.instant(bitfToTranslate('SALE.SOME_QUANTITIES_ARE_INVALID')),
        type: EBitfUiMessageType.SUCCESS,
      });
      return;
    }

    this.dialogRef.close({
      status: EBitfCloseEventStatus.OK,
      data: this.items,
    } as IBitfCloseEvent<Archive[]>);
  }

  onClose() {
    this.dialogRef.close({
      status: EBitfCloseEventStatus.CANCEL,
    } as IBitfCloseEvent<void>);
  }
}
