import { SuperStrapiModel } from './super-strapi.model';

export class Sex extends SuperStrapiModel {
  name: string;

  constructor(data = {}) {
    super(data);
  }

  get serialised() {
    return {
      id: this.id,
      name: this.name,
    };
  }
}
