<div class="p-3">

  <div class="row">
    <div *bitfUiRoleManager="{action : eRoleActions.EDIT_ITEM}"
      class="col-24 col-sm-12 border-right fixed-full-available-height-screen">
      <mpa-file-uploader (startUpload)="onUpload()"
        [fileUploaderConfig]="{extensions: ['jpg', 'jpeg', 'png'], concatenateMultipleFiles: true}">
      </mpa-file-uploader>
    </div>

    <div [ngClass]="uiRoleManagerService.canI(eRoleActions.EDIT_ITEM) ? 'col-24 col-sm-12': 'col'"
      class="mt-4 mt-sm-0">
      <ng-container *ngIf="selectedItem?.images?.length">
        <h4>{{'COMMON.LABEL.IMAGES' | translate}}</h4>

        <div class="border-bottom d-flex justify-content-between py-1 w-100"
          *ngFor="let image of selectedItem.images">
          <div class="img-details d-flex align-items-center flex-shrink-1">
            <div
              class="item-image rounded d-flex flex-shrink-0 align-items-center justify-content-center mr-3">
              <img [src]="image?.getImageByFormat('xsmall')?.url"
                [alt]="image.name">
            </div>
            <div class="image-name">
              {{image.name}}
            </div>
          </div>

          <div class="d-flex align-items-center justify-content-end">
            <button mat-icon-button
              color="primary"
              (click)="onDownloadImage(image)">
              <mat-icon>download</mat-icon>
            </button>
            <button mat-icon-button
              *ngIf="uiRoleManagerService.canI(eRoleActions.EDIT_ITEM)"
              color="primary"
              (click)="onRemoveImage(image)">
              <mat-icon>delete</mat-icon>
            </button>
          </div>
        </div>

      </ng-container>

      <ng-container *ngIf="!selectedItem?.images?.length">
        <h4>{{'ITEM.DETAIL.EMPTY_ITEM_IMAGES' | translate}}</h4>
      </ng-container>
    </div>
  </div>

</div>