import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { filter, switchMap } from 'rxjs/operators';

import { Archive } from '@common/core/models';
import { ArchivesService, LoaderService, StoreService, UiRoleManagerService } from '@common/core/services';
import { EBitfUiMessageType, ERoleActions } from '@common/enums';
import { IBitfApiResponse } from '@common/interfaces';
import { ToastMessagesService } from '@web/core/services';
import { TranslateService } from '@ngx-translate/core';
import { marker as bitfToTranslate } from '@biesbjerg/ngx-translate-extract-marker';

@Component({
  selector: 'mpa-copy-data-on-link',
  templateUrl: './copy-data-on-link.component.html',
  styleUrls: ['./copy-data-on-link.component.scss'],
})
export class CopyDataOnLinkComponent implements OnInit {
  form: UntypedFormGroup;
  eRoleActions = ERoleActions;

  constructor(
    public uiRoleManagerService: UiRoleManagerService,
    private formBuilder: UntypedFormBuilder,
    private archiveService: ArchivesService,
    private storeService: StoreService,
    private translateService: TranslateService,
    private toastMessagesService: ToastMessagesService,
    private loaderService: LoaderService
  ) {}

  ngOnInit(): void {
    this.initForm();
  }

  onCopyDataOnLink() {
    const inventoryToLink = this.form.value.inventory;
    if (inventoryToLink === this.storeService.store.selectedItem.inventory) {
      this.toastMessagesService.show({
        title: this.translateService.instant(bitfToTranslate('COMMON.LABEL.INVENTORY_NOT_VALID')),
        type: EBitfUiMessageType.ERROR,
      });
      return;
    }

    this.loaderService.show();
    this.archiveService
      .getItem({
        variables: {
          filters: { inventory: { eq: this.form.value.inventory } },
        },
      })
      .pipe(
        // NOTE: Check if the inventory exists
        filter((response: IBitfApiResponse<Archive>) => {
          if (!response.content) {
            this.toastMessagesService.show({
              title: this.translateService.instant(bitfToTranslate('COMMON.LABEL.ITEMS_NOT_FOUND')),
              type: EBitfUiMessageType.ERROR,
            });
            return false;
          }
          return true;
        }),
        switchMap((response: IBitfApiResponse<Archive>) => {
          this.loaderService.show();
          const propsNotToBeSynchronized = [
            'images',
            't_1s',
            't_2s',
            't_3s',
            't_4s',
            'links_from',
            'links_to',
            'lookbooks_from',
            'lookbooks_to',
            'suit',
            'folders',
            'inventory',
            'description',
            'archive_loans',
          ];
          const newItem = new Archive({}, this.storeService.store.selectedItem);
          propsNotToBeSynchronized.forEach(prop => {
            delete newItem[prop];
          });
          return this.archiveService.updateItem({
            id: response.content.id,
            body: newItem,
          });
        })
      )
      .subscribe((response: IBitfApiResponse<Archive>) => {
        this.toastMessagesService.show({
          title: this.translateService.instant(
            bitfToTranslate('ITEM.DETAIL.COPY_DATA_ON_LINK_UPDATED_MESSAGE')
          ),
          type: EBitfUiMessageType.SUCCESS,
        });
        this.storeService.updateGalleryItem({ id: response.content.id });
      });
  }

  private initForm() {
    this.form = this.formBuilder.group({
      inventory: [null, Validators.required],
    });
  }
}
