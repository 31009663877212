import { HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { IBitfEnvelopeMapper, IBitfEnvelopeMapperData, IBitfApiResponse } from '@interfaces';

export abstract class BitfRestEnvelopeMapper implements IBitfEnvelopeMapper {
  constructor() {}

  map({ req, event }: IBitfEnvelopeMapperData): HttpResponse<IBitfApiResponse<any>> {
    if (event instanceof HttpErrorResponse) {
      event = toHttpResponseLike(event) as any as HttpResponse<any>;
    }

    return event.clone({
      body: {
        content: undefined,
        pagination: undefined,
        sorting: undefined,
        metadata: { uiMessages: [] },
        status: event.status,
        statusText: event.statusText,
        url: event.url,
        headers: event.headers,
        httpParams: req.params,
        method: req.method,
        isMockResponse: false,
        originalBody: event.body,
      } as IBitfApiResponse<any>,
    });
  }
}

function cloneMixin(update: any = {}): HttpErrorResponse {
  // https://github.com/angular/angular/blob/5c18f23788ebd25e096d1030c913b1edfb0d39b5/packages/common/http/src/response.ts#LC277
  const newResponse = new HttpErrorResponse({
    error: update.body !== undefined ? update.body : this.error,
    headers: update.headers || this.headers,
    status: update.status !== undefined ? update.status : this.status,
    statusText: update.statusText || this.statusText,
    url: update.url || this.url || undefined,
  });

  return toHttpResponseLike(newResponse);
}

function toHttpResponseLike(errorResponse: HttpErrorResponse): HttpErrorResponse {
  Object.assign(errorResponse, {
    body: errorResponse.error,
    clone: cloneMixin.bind(errorResponse),
  });
  return errorResponse;
}
