<mat-tab-group animationDuration="0ms"
  color="accent"
  preserveContent
  (selectedTabChange)="onSelectTab($event)">
  <mat-tab [label]="'ITEM.DETAIL.GENERAL_DATA' | translate">
    <div class="p-3">
      <mpa-dynamic-item-data-form [isReadOnly]="isReadOnly"></mpa-dynamic-item-data-form>
    </div>
  </mat-tab>

  <mat-tab *ngIf="enabledTabs.indexOf('images') !== -1"
    [label]="'ITEM.DETAIL.IMAGES' | translate">
    <ng-template matTabContent>
      <mpa-archive-image-manager></mpa-archive-image-manager>
    </ng-template>
  </mat-tab>

  <mat-tab *ngIf="enabledTabs.indexOf('suit') !== -1"
    [label]="'COMMON.LABEL.SUIT'| translate">
    <ng-template matTabContent>
      <div class="p-3">
        <mpa-link-suit></mpa-link-suit>
      </div>
    </ng-template>
  </mat-tab>

  <mat-tab *ngIf="enabledTabs.indexOf('links') !== -1"
    [label]="'COMMON.LABEL.LINKS' | translate">
    <ng-template matTabContent>
      <div class="p-3">
        <mpa-link-items></mpa-link-items>
        <mat-divider></mat-divider>
        <mpa-link-externals></mpa-link-externals>
      </div>
    </ng-template>
  </mat-tab>

  <mat-tab *ngIf="enabledTabs.indexOf('lookbook') !== -1"
    [label]="'COMMON.LABEL.LOOKBOOK' | translate">
    <ng-template matTabContent>
      <div class="p-3">
        <mpa-link-lookbooks></mpa-link-lookbooks>
      </div>
    </ng-template>
  </mat-tab>

  <mat-tab *ngIf="enabledTabs.indexOf('neighbours') !== -1"
    [label]="'COMMON.LABEL.NEIGHBOURS' | translate">
    <ng-template matTabContent>
      <div class="p-3">
        <mpa-view-neighbours></mpa-view-neighbours>
      </div>
    </ng-template>
  </mat-tab>

  <mat-tab [label]="'COMMON.LABEL.UTILITY' | translate"
    *ngIf="!isReadOnly">
    <ng-template matTabContent>
      <div class="p-3">
        <mpa-copy-data-on-link></mpa-copy-data-on-link>
        <mpa-duplicate-archive></mpa-duplicate-archive>
      </div>
    </ng-template>
  </mat-tab>
</mat-tab-group>