import { ARCHIVE_LOAN, ARCHIVE_LOAN_ENTITY } from './archive-loans.gql';

export const LOAN = `
  fragment Loan on Loan {
    name
    date_out
    note
    reference
    is_visible_to_editor
    is_visible_to_collaborator
    is_visible_to_ufficio_stile
    is_visible_to_viewer
    createdAt
  }
`;

export const LOAN_ENTITY = `
  ${LOAN}
  fragment LoanEntity on LoanEntity {
    id
    attributes {
      ...Loan
    }
  }
`;

export const LOAN_WITH_ALL_RELATIONS_ENTITY = `
  ${LOAN}
  ${ARCHIVE_LOAN_ENTITY}
  fragment LoanWithAllRelationsEntity on LoanEntity {
    id
    attributes {
      ...Loan
      archive_loans(pagination: { limit: 200 }) {
        data {
          ...ArchiveLoanEntity
        }
      }
    }
  }
`;

export const LOAN_LIST_ENTITY = `
  ${LOAN}
  ${ARCHIVE_LOAN}
  fragment LoanListEntity on LoanEntity {
    id
    attributes {
      ...Loan
      archive_loans(pagination: { limit: 1000 }) {
        data {
          id
          attributes {
            ...ArchiveLoan
            archive {
              data {
                attributes {
                  is_visible_to_editor
                  is_visible_to_collaborator
                  is_visible_to_ufficio_stile
                  is_visible_to_viewer
                }
              }
            }
          }
        }
      }
    }
  }
`;
