import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

import { Archive, Filter, Typology } from '@common/core/models';
import { StoreService } from '@common/core/services';
import { environment } from '@env/environment';
import { EArchiveType } from '@web/enums';
import { marker as bitfToTranslate } from '@biesbjerg/ngx-translate-extract-marker';

interface IGroupedOptions {
  parentTypology: Typology;
  options: Typology[];
}

@Component({
  selector: 'mpa-item-type-selector',
  templateUrl: './item-type-selector.component.html',
  styleUrls: ['./item-type-selector.component.scss'],
})
export class ItemTypeSelectorComponent implements OnInit, OnChanges {
  @Input() form: UntypedFormGroup;
  @Input() model: Archive;
  @Input() fontSize = '12px';
  @Input() isReadOnly = false;
  @Output() typologyChanged = new EventEmitter();

  public t2: IGroupedOptions[] = [];
  public t3: IGroupedOptions[] = [];
  public t4: IGroupedOptions[] = [];
  environment = environment;
  eArchiveType = EArchiveType;
  typology1Label: string;
  typology2Label: string;
  typology3Label: string;
  typology4Label: string;
  numberOfTypologies = 4;

  constructor(public storeService: StoreService) {}

  ngOnInit(): void {
    switch (environment.archiveType) {
      case EArchiveType.MULTIMEDIA:
        this.typology1Label = bitfToTranslate('MULTIMEDIA.LABEL.TYPOLOGY');
        this.typology2Label = bitfToTranslate('MULTIMEDIA.LABEL.TYPOLOGY_2');
        this.numberOfTypologies = 2;
        break;
      case EArchiveType.FASHION:
        this.typology1Label = bitfToTranslate('FASHION.LABEL.TYPOLOGY');
        this.typology2Label = bitfToTranslate('FASHION.LABEL.TYPOLOGY_2');
        this.typology3Label = bitfToTranslate('FASHION.LABEL.TYPOLOGY_3');
        this.typology4Label = bitfToTranslate('FASHION.LABEL.TYPOLOGY_4');
        break;
      case EArchiveType.CELLAR:
        this.typology1Label = bitfToTranslate('CELLAR.LABEL.TYPOLOGY');
        this.typology2Label = bitfToTranslate('CELLAR.LABEL.TYPOLOGY_2');
        this.typology3Label = bitfToTranslate('CELLAR.LABEL.TYPOLOGY_3');
        this.typology4Label = bitfToTranslate('CELLAR.LABEL.TYPOLOGY_4');
        break;
      default:
        this.typology1Label = bitfToTranslate('COMMON.LABEL.TYPOLOGY');
        this.typology2Label = bitfToTranslate('COMMON.LABEL.TYPOLOGY_2');
        this.typology3Label = bitfToTranslate('COMMON.LABEL.TYPOLOGY_3');
        this.typology4Label = bitfToTranslate('COMMON.LABEL.TYPOLOGY_4');
        break;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.model && changes.model.currentValue !== changes.model.previousValue) {
      this.patchFormWithStoreFilters();
      this.updateOptions();
    }
  }

  patchFormWithStoreFilters() {
    if (this.form.value.t_1s && this.storeService.store.filters.typologies) {
      const t_1s = [];
      const t_2s = [];
      const t_3s = [];

      this.form.value.t_1s.forEach(t1 => {
        t_1s.push(this.storeService.store.filters.typologies.find(t => t.id === t1.id));
      });
      if (this.form.value.t_2s) {
        const store_t_2s =
          this.form.value.t_2s.length > 0
            ? this.storeService.store.filters.typologies.map(t => t.children).flat(1)
            : [];
        this.form.value.t_2s.forEach(t2 => {
          t_2s.push(store_t_2s.find(t => t.id === t2.id));
        });
        if (this.form.value.t_3s) {
          const store_t_3s = this.form.value.t_3s.length > 0 ? store_t_2s.map(t => t.children).flat(1) : [];
          this.form.value.t_3s.forEach(t3 => {
            t_3s.push(store_t_3s.find(t => t.id === t3.id));
          });
        }
      }
      this.form.patchValue({ t_1s, t_2s, t_3s }, { emitEvent: false });
    }
  }

  get filters(): Filter {
    return this.storeService.store.filters;
  }

  onT1Change(typologies: Typology[]) {
    this.pushChildrenOptions(typologies, this.t2);
    setTimeout(() => {
      if (this.form.get('t_2s')) {
        this.purgeFormValuesWithoutParent('t_1s', 't_2s');
        this.onT2Change(this.form.get('t_2s').value);
      }
    }, 0);
  }

  onT2Change(typologies: Typology[]) {
    this.pushChildrenOptions(typologies, this.t3);
    setTimeout(() => {
      if (this.form.get('t_3s')) {
        this.purgeFormValuesWithoutParent('t_2s', 't_3s');
        this.onT3Change(this.form.get('t_3s').value);
      }
    }, 0);
  }

  onT3Change(typologies: Typology[]) {
    this.pushChildrenOptions(typologies, this.t4);
    setTimeout(() => {
      if (this.form.get('t_4s')) {
        this.purgeFormValuesWithoutParent('t_3s', 't_4s');
        this.onT4Change();
      }
    }, 0);
  }

  onT4Change() {
    this.onTypologyChange();
  }

  private onTypologyChange() {
    this.typologyChanged.emit();
  }

  compareById(a, b) {
    return a?.id === b?.id;
  }

  private pushChildrenOptions(typologies: Typology[], options: IGroupedOptions[]) {
    options.length = 0;
    if (!typologies) {
      return;
    }
    typologies.forEach((typology: Typology) => {
      if (typology.children?.length) {
        options.push({
          parentTypology: typology,
          options: typology.children,
        });
      }
    });
  }

  private purgeFormValuesWithoutParent(parentFormControlName: string, childrenFormControlName: string) {
    const parentTypologies = this.form.get(parentFormControlName).value;
    const childrenTypologies = this.form.get(childrenFormControlName).value;
    if (!parentTypologies || !childrenTypologies) {
      return;
    }
    const validTypologies = childrenTypologies.filter(childrenTypology =>
      parentTypologies.some(parentTypology =>
        parentTypology.children?.find(pt => pt?.id === childrenTypology?.id)
      )
    );
    this.form.get(childrenFormControlName).patchValue(validTypologies, { emitEvent: false });
  }

  private updateOptions() {
    // NOTE: we get from the store filters the t_1s that contains all the children
    // then we select all the options values
    // Restore typology children
    const { t_1s, t_2s, t_3s } = this.model;
    if (t_1s && this.form.get('t_1s')) {
      const t1s = this.filters.typologies.filter(typology =>
        t_1s.some(modelTypology => modelTypology.id === typology.id)
      );
      this.pushChildrenOptions(t1s, this.t2);

      if (t_2s && this.form.get('t_2s')) {
        const t1Children = t1s.reduce((acc, t) => {
          acc.push(...t.children);
          return acc;
        }, []);
        const t2s = t1Children.filter(typology =>
          t_2s.some(modelTypology => modelTypology.id === typology.id)
        );
        this.pushChildrenOptions(t2s, this.t3);

        if (t_3s && this.form.get('t_3s')) {
          const t2Children = t2s.reduce((acc, t) => {
            acc.push(...t.children);
            return acc;
          }, []);
          const t3s = t2Children.filter(typology =>
            t_3s.some(modelTypology => modelTypology.id === typology.id)
          );
          this.pushChildrenOptions(t3s, this.t4);
        }
      }
    } else {
      this.t2 = [];
      this.t3 = [];
      this.t4 = [];
    }
  }
}
