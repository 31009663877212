import { Component, Input } from '@angular/core';
import { BitfInfiniteScrollTriggerComponent } from '../../bitf-infinite-scroll-trigger.component';

@Component({
  selector: 'bitf-mat-infinite-scroll-trigger',
  templateUrl: './bitf-mat-infinite-scroll-trigger.component.html',
  styleUrls: ['./bitf-mat-infinite-scroll-trigger.component.scss'],
})
export class BitfMatInfiniteScrollTriggerComponent extends BitfInfiniteScrollTriggerComponent {
  @Input() diameter = 30;
}
