import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { filter, switchMap, finalize, tap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

import { marker as bitfToTranslate } from '@biesbjerg/ngx-translate-extract-marker';

import { Archive } from '@common/core/models';
import { ArchivesService, LoaderService, StoreService, UiRoleManagerService } from '@common/core/services';
import { EBitfUiMessageType, ERoleActions } from '@common/enums';
import { IBitfApiResponse } from '@common/interfaces';
import { ToastMessagesService } from '@web/core/services';

@Component({
  selector: 'mpa-link-items',
  templateUrl: './link-items.component.html',
  styleUrls: ['./link-items.component.scss'],
})
export class LinkItemsComponent implements OnInit {
  form: UntypedFormGroup;
  eRoleActions = ERoleActions;

  get selectedItem(): Archive {
    return this.storeService.store.selectedItem;
  }

  constructor(
    public uiRoleManagerService: UiRoleManagerService,
    public storeService: StoreService,
    private translateService: TranslateService,
    private formBuilder: UntypedFormBuilder,
    private archivesService: ArchivesService,
    private loaderService: LoaderService,
    private toastMessagesService: ToastMessagesService
  ) {}

  ngOnInit(): void {
    this.initForm();
  }

  onLink() {
    const inventoryToLink = this.form.value.inventory;
    if (inventoryToLink === this.selectedItem.inventory) {
      this.toastMessagesService.show({
        title: this.translateService.instant(bitfToTranslate('COMMON.LABEL.INVENTORY_NOT_VALID')),
        type: EBitfUiMessageType.ERROR,
      });
      return;
    }

    this.loaderService.show();
    this.archivesService
      .getItem({
        variables: {
          filters: { inventory: { eq: this.form.value.inventory } },
        },
        disableHideLoader: true,
      })
      .pipe(
        // NOTE: Check if the inventory exists
        filter((response: IBitfApiResponse<Archive>) => {
          if (!response.content) {
            this.toastMessagesService.show({
              title: this.translateService.instant(bitfToTranslate('COMMON.LABEL.ITEMS_NOT_FOUND')),
              type: EBitfUiMessageType.ERROR,
            });
            return false;
          }
          return true;
        }),
        switchMap((response: IBitfApiResponse<Archive>) => {
          this.loaderService.show();

          return this.archivesService
            .updateItem({
              id: this.selectedItem.id,
              body: {
                links_to: [...this.selectedItem.serialised.links_to, response.content.id],
              },
            })
            .pipe(
              tap((selectedItemResponse: IBitfApiResponse<Archive>) => {
                // NOTE: Update the selectedItem
                this.storeService.updateSelectedItem({
                  links_to: selectedItemResponse.content.links_to,
                });

                // NOTE: Update the related item
                this.storeService.updateGalleryItem({ id: response.content.id });
              })
            );
        }),
        finalize(() => {
          this.loaderService.hide();
          this.form.patchValue({ inventory: '' });
          this.form.reset();
        })
      )
      .subscribe();
  }

  onRemoveLink(archive: Archive) {
    if (!window.confirm(this.translateService.instant('ITEM.DETAIL.REMOVE_LINKED_ITEM'))) {
      return;
    }
    this.loaderService.show();
    this.archivesService
      .updateItem({
        id: this.selectedItem.id,
        body: {
          links_to: this.selectedItem.serialised.links_to.filter(_archiveId => _archiveId !== archive.id),
          links_from: this.selectedItem.serialised.links_from.filter(_archiveId => _archiveId !== archive.id),
        },
      })
      .pipe(
        tap((response: IBitfApiResponse<Archive>) => {
          // NOTE: Update the selectedItem
          this.storeService.updateSelectedItem({
            links_to: response.content.links_to,
            links_from: response.content.links_from,
          });

          // NOTE: Update the related item
          this.storeService.updateGalleryItem({ id: archive.id });
        }),
        finalize(() => {
          this.loaderService.hide();
          this.form.patchValue({ inventory: '' });
          this.form.reset();
        })
      )
      .subscribe();
  }

  private initForm() {
    this.form = this.formBuilder.group({
      inventory: ['', Validators.required],
    });
  }
}
