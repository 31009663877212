import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EBitfCloseEventStatus } from '@common/enums';
import { IBitfCloseEvent } from '@common/interfaces';
import { CONSTANTS } from '@web/constants';

@Component({
  selector: 'mpa-select-print-page-dialog',
  templateUrl: './select-print-page-dialog.component.html',
  styleUrls: ['./select-print-page-dialog.component.scss'],
})
export class SelectPrintPageDialogComponent implements OnInit {
  printForm: FormGroup;
  pages = [];
  maxPrintableItems = CONSTANTS.MAX_PRINTABLE_ITEMS;

  constructor(
    public dialogRef: MatDialogRef<SelectPrintPageDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: number,
    private formBuilder: FormBuilder
  ) {}

  ngOnInit(): void {
    for (let index = 0; index < Math.ceil(this.dialogData / CONSTANTS.MAX_PRINTABLE_ITEMS); index++) {
      this.pages.push(index + 1);
    }
    this.initForm();
  }

  onPrint() {
    this.dialogRef.close({
      status: EBitfCloseEventStatus.OK,
      data: this.printForm.value.page,
    } as IBitfCloseEvent<void>);
  }

  getMin(a, b) {
    return Math.min(a, b);
  }

  private initForm() {
    this.printForm = this.formBuilder.group({
      page: [1, Validators.required],
    });
  }
}
