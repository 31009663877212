import { IBitfApiPagination, IBitfApiUiMessage, IBitfGraphQlRequest } from '@interfaces';
import { EBitfUiMessageTarget, EBitfUiMessageType } from '../../services/api/bitf-api.enum';
import { BitfGraphQlResponseMapper } from '../graph-ql-parser/bitf-graph-ql-response.mapper';

export class BitfStrapiGraphQlResponseMapper extends BitfGraphQlResponseMapper {
  constructor() {
    super();
  }

  protected isResponseArray(response): boolean {
    return Array.isArray(response.data);
  }

  protected getContentAsArray<T>(response, model): T[] {
    return response.data.map(item => new model(item));
  }

  protected calculatePagination(requestParams: IBitfGraphQlRequest, response: any): IBitfApiPagination {
    const pagination = response.meta.pagination;
    // NOTE: page starts from 1 to avoid mismatch between page and totalPages
    const size = requestParams.size;
    const page = requestParams.page;
    const totalItems = pagination.total;
    const totalPages = pagination.pageCount;
    const first = page === 1;
    const last = page === totalPages;
    let itemsInPage;
    if (last) {
      if (totalItems % size > 0) {
        itemsInPage = totalItems % size;
      } else {
        itemsInPage = size;
      }
    } else {
      if (size * page <= totalItems) {
        itemsInPage = size;
      } else {
        itemsInPage = 0;
      }
    }
    return {
      first,
      last,
      page,
      size,
      itemsInPage,
      totalItems,
      totalPages,
    };
  }

  // Mutation
  protected extractMutationResponseAndMetadata({ originalBody, responseEnveloped }) {
    if (originalBody.data === null) {
      // NOTE: Strapi in case the object not found returned {data: null}
      responseEnveloped.metadata.uiMessages.push({
        message: 'Item not found',
        type: EBitfUiMessageType.ERROR,
        target: EBitfUiMessageTarget.TOAST,
      } as IBitfApiUiMessage);
      return;
    }
    return originalBody;
  }
}
