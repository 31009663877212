import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';

import { gql } from 'apollo-angular';

import { BitfGraphQlService } from '@bitf/services/graph-ql/bitf-graph-ql.service';

import { IBitfGraphQlRequest, IBitfGraphQlResponse } from '@interfaces';
import { ArchiveLoan } from '@models';
import { ARCHIVE_LOAN_ENTITY } from './archive-loans.gql';
import { tap } from 'rxjs/operators';
import { PAGINATION_ENTITY } from './strapi.gql';

@Injectable({
  providedIn: 'root',
})
// NOTE: Add methods following CRUD order
export class ArchiveLoansService extends BitfGraphQlService {
  constructor(public injector: Injector) {
    super(injector);
  }

  createItem(requestParams: IBitfGraphQlRequest = {}) {
    requestParams.modelMapper = 'createArchiveLoan';
    requestParams.mutation = gql`
      ${ARCHIVE_LOAN_ENTITY}
      mutation Create($input: ArchiveLoanInput!) {
        createArchiveLoan(data: $input) {
          data {
            ...ArchiveLoanEntity
          }
        }
      }
    `;

    return super.mutate<ArchiveLoan>(requestParams);
  }

  getItemById(requestParams: IBitfGraphQlRequest): Observable<IBitfGraphQlResponse<ArchiveLoan>> {
    requestParams.modelMapper = 'archiveLoan';
    requestParams.query = gql`
      ${ARCHIVE_LOAN_ENTITY}
      query Query($id: ID) {
        archiveLoan(id: $id) {
          data {
            ...ArchiveLoanEntity
          }
        }
      }
    `;

    return super.query<ArchiveLoan>(requestParams);
  }

  getItems(requestParams: IBitfGraphQlRequest = {}): Observable<IBitfGraphQlResponse<ArchiveLoan[]>> {
    requestParams.modelMapper = 'archiveLoans';
    requestParams.query = gql`
      ${ARCHIVE_LOAN_ENTITY}
      ${PAGINATION_ENTITY}
      query Query($pagination: PaginationArg, $sort: [String], $filters: ArchiveLoanFiltersInput) {
        archiveLoans(pagination: $pagination, sort: $sort, filters: $filters) {
          data {
            ...ArchiveLoanEntity
          }
          meta {
            pagination {
              ...PaginationEntity
            }
          }
        }
      }
    `;

    return super.query<ArchiveLoan[]>(requestParams);
  }

  updateItem(
    requestParams: IBitfGraphQlRequest<Partial<ArchiveLoan>> = {},
    loanArchive?: Partial<ArchiveLoan>
  ): Observable<IBitfGraphQlResponse<ArchiveLoan>> {
    requestParams.modelMapper = 'updateArchiveLoan';
    requestParams.mutation = gql`
      ${ARCHIVE_LOAN_ENTITY}
      mutation Update($id: ID!, $input: ArchiveLoanInput!) {
        updateArchiveLoan(id: $id, data: $input) {
          data {
            ...ArchiveLoanEntity
          }
        }
      }
    `;
    return super.mutate<ArchiveLoan>(requestParams, loanArchive).pipe(
      tap((response: IBitfGraphQlResponse<ArchiveLoan>) => {
        if (loanArchive) {
          Object.assign(loanArchive, response.content);
        }
      })
    );
  }

  deleteItem(requestParams: IBitfGraphQlRequest = {}) {
    requestParams.modelMapper = 'deleteArchiveLoan';
    requestParams.mutation = gql`
      mutation Mutate($id: ID!) {
        deleteArchiveLoan(id: $id) {
          data {
            id
          }
        }
      }
    `;
    return super.mutate<ArchiveLoan>(requestParams);
  }
}
