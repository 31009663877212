import { SuperStrapiModel } from './super-strapi.model';

export class Distributor extends SuperStrapiModel {
  name: string;
  address: string;
  city: string;
  province: string;
  zip: string;
  country: string;
  email: string;
  phone: string;
  vat_number: string;

  constructor(data = {}) {
    super(data);
  }

  get description() {
    return `${this.vat_number ? this.vat_number + ', ' : ''}${this.email ? this.email + ', ' : ''}${
      this.phone ? this.phone + ', ' : ''
    }${this.address ? this.address + ', ' : ''}${this.zip ? this.zip + ', ' : ''}${
      this.city ? this.city + ', ' : ''
    }${this.province ? this.province + ', ' : ''}${this.country ? this.country + ', ' : ''}`.slice(0, -2);
  }

  get fullDescription() {
    return `${this.name} (${this.description})`;
  }

  get serialised() {
    return {
      id: this.id,
      name: this.name,
      address: this.address,
      city: this.city,
      province: this.province,
      zip: this.zip,
      country: this.country,
      email: this.email,
      phone: this.phone,
      vat_number: this.vat_number,
    };
  }
}
