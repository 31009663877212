import { NgModule, Optional, SkipSelf } from '@angular/core';
import { RouterModule } from '@angular/router';

import { PortalModule } from '@angular/cdk/portal';

import { SharedModule } from '@shared/shared.module';
import { CommonCoreModule } from '@common/core/common-core.module';

import { throwIfAlreadyLoaded } from './module-config';
import { NotFoundComponent } from './components/not-found/not-found.component';

const EXPORTS_COMPONENTS = [NotFoundComponent];
const COMPONENTS = [];

@NgModule({
  imports: [CommonCoreModule, RouterModule, SharedModule.forRoot(), PortalModule],
  exports: [...EXPORTS_COMPONENTS],
  declarations: [...EXPORTS_COMPONENTS, ...COMPONENTS],
  providers: [],
})
export class CoreModule {
  constructor(
    @Optional()
    @SkipSelf()
    parentModule: CoreModule
  ) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }
}
