import { Component, Input } from '@angular/core';

import { Archive } from '@common/core/models';
import { StoreService } from '@common/core/services';

@Component({
  selector: 'mpa-item-images',
  templateUrl: './item-images.component.html',
  styleUrls: ['./item-images.component.scss'],
})
export class ItemImagesComponent {
  @Input() item: Archive;
  @Input() imageSize: 'xlarge' | 'large' | 'medium' | 'small' | 'xsmall' = 'xsmall';
  @Input() showImagesCounter: boolean;

  constructor(public storeService: StoreService) {}

  showPreviousImage(event) {
    event.stopPropagation();
    if (this.item.galleryImageIndex === 0) {
      this.item.galleryImageIndex = this.item.gallery.length - 1;
    } else {
      this.item.galleryImageIndex--;
    }
  }

  showNextImage(event) {
    event.stopPropagation();
    if (this.item.galleryImageIndex === this.item.gallery.length - 1) {
      this.item.galleryImageIndex = 0;
    } else {
      this.item.galleryImageIndex++;
    }
  }
}
