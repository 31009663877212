<h2 mat-dialog-title>{{ 'FOLDERS.DELETE_FROM_FOLDER_DIALOG.TITLE' | translate }}</h2>
<mat-dialog-content>
  <span class="message"
    [innerHTML]="'FOLDERS.DELETE_FROM_FOLDER_DIALOG.MESSAGE' | translate">
  </span>
</mat-dialog-content>

<mat-dialog-actions class="mt-1 justify-content-end">
  <button mat-flat-button
    (click)="onCancel()">
    {{ 'BITF.LABEL.CANCEL' | translate }}
  </button>

  <button mat-flat-button
    color="primary"
    (click)="onOk()"
    class="ml-3">
    {{ 'BITF.LABEL.OK' | translate }}
  </button>
</mat-dialog-actions>