import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { gql } from 'apollo-angular';

import { BitfGraphQlService } from '@bitf/services/graph-ql/bitf-graph-ql.service';

import { IBitfGraphQlRequest, IBitfGraphQlResponse } from '@interfaces';
import { Suit } from '@models';
import { SUIT_ENTITY, SUIT_ENTITY_WITH_RELATIONS } from './suits.gql';

@Injectable({
  providedIn: 'root',
})
// NOTE: Add methods following CRUD order
export class SuitsService extends BitfGraphQlService {
  constructor(public injector: Injector) {
    super(injector);
  }

  create(
    requestParams: IBitfGraphQlRequest<Partial<Suit>> = {},
    suit?: Partial<Suit>
  ): Observable<IBitfGraphQlResponse<Suit>> {
    requestParams.modelMapper = 'createSuit';
    requestParams.mutation = gql`
      ${SUIT_ENTITY_WITH_RELATIONS}
      mutation Create($input: SuitInput!) {
        createSuit(data: $input) {
          data {
            ...SuitEntityWithRelations
          }
        }
      }
    `;
    return super.mutate<Suit>(requestParams, suit).pipe(
      tap((response: IBitfGraphQlResponse<Suit>) => {
        if (suit) {
          Object.assign(suit, response.content);
        }
      })
    );
  }

  update(
    requestParams: IBitfGraphQlRequest<Partial<Suit>> = {},
    suit?: Partial<Suit>
  ): Observable<IBitfGraphQlResponse<Suit>> {
    requestParams.modelMapper = 'updateSuit';
    requestParams.mutation = gql`
      ${SUIT_ENTITY_WITH_RELATIONS}
      mutation Update($id: ID!, $input: SuitInput!) {
        updateSuit(id: $id, data: $input) {
          data {
            ...SuitEntityWithRelations
          }
        }
      }
    `;
    return super.mutate<Suit>(requestParams, suit).pipe(
      tap((response: IBitfGraphQlResponse<Suit>) => {
        if (suit) {
          Object.assign(suit, response.content);
        }
      })
    );
  }

  delete(requestParams: IBitfGraphQlRequest<Partial<Suit>> = {}): Observable<IBitfGraphQlResponse<Suit>> {
    requestParams.modelMapper = 'deleteSuit';
    requestParams.mutation = gql`
      ${SUIT_ENTITY}
      mutation Mutate($id: ID!) {
        deleteSuit(id: $id) {
          data {
            ...SuitEntity
          }
        }
      }
    `;
    return super.mutate<Suit>(requestParams);
  }
}
