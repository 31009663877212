import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { finalize, tap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

import { Archive } from '@common/core/models';
import { ArchivesService, LoaderService, StoreService, UiRoleManagerService } from '@common/core/services';
import { ERoleActions } from '@common/enums';
import { IBitfApiResponse } from '@common/interfaces';

@Component({
  selector: 'mpa-link-externals',
  templateUrl: './link-externals.component.html',
  styleUrls: ['./link-externals.component.scss'],
})
export class LinkExternalsComponent implements OnInit {
  form: UntypedFormGroup;
  eRoleActions = ERoleActions;

  get selectedItem(): Archive {
    return this.storeService.store.selectedItem;
  }

  constructor(
    public uiRoleManagerService: UiRoleManagerService,
    public storeService: StoreService,
    private translateService: TranslateService,
    private formBuilder: UntypedFormBuilder,
    private archivesService: ArchivesService,
    private loaderService: LoaderService
  ) {}

  ngOnInit(): void {
    this.initForm();
  }

  onLink() {
    const link = this.form.value.link;

    this.loaderService.show();
    this.archivesService
      .updateItem({
        id: this.selectedItem.id,
        body: {
          external_links: [...this.selectedItem.serialised.external_links, link],
        },
      })
      .pipe(
        tap((selectedItemResponse: IBitfApiResponse<Archive>) => {
          // NOTE: Update the selectedItem
          this.storeService.updateSelectedItem({
            external_links: selectedItemResponse.content.external_links,
          });
        }),
        finalize(() => {
          this.loaderService.hide();
          this.form.patchValue({ link: '' });
          this.form.reset();
        })
      )
      .subscribe();
  }

  onRemoveLink(link: string) {
    if (!window.confirm(this.translateService.instant('ITEM.DETAIL.REMOVE_LINKED_EXTERNAL'))) {
      return;
    }
    this.loaderService.show();
    this.archivesService
      .updateItem({
        id: this.selectedItem.id,
        body: {
          external_links: this.selectedItem.serialised.external_links.filter(_link => _link !== link),
        },
      })
      .pipe(
        tap((response: IBitfApiResponse<Archive>) => {
          // NOTE: Update the selectedItem
          this.storeService.updateSelectedItem({
            external_links: response.content.external_links,
          });
        }),
        finalize(() => {
          this.loaderService.hide();
          this.form.patchValue({ link: '' });
          this.form.reset();
        })
      )
      .subscribe();
  }

  private initForm() {
    this.form = this.formBuilder.group({
      link: [
        '',
        [
          Validators.required,
          Validators.pattern(
            /^[A-Za-z][A-Za-z\d.+-]*:\/*(?:\w+(?::\w+)?@)?[^\s/]+(?::\d+)?(?:\/[\w#!:.?+=&%@\-/]*)?$/
          ),
        ],
      ],
    });
  }
}
