import { Component, Input, OnInit } from '@angular/core';

import { StoreService } from '@common/core/services';
import { environment } from '@env/environment';

@Component({
  selector: 'mpa-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  @Input() title: string;
  @Input() subTitle: string;
  @Input() backUrl: string[];

  appName = environment.appName;

  constructor(public storeService: StoreService) {}

  ngOnInit(): void {}
}
