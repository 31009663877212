import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { filter, switchMap, finalize, tap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

import { marker as bitfToTranslate } from '@biesbjerg/ngx-translate-extract-marker';

import { Archive } from '@common/core/models';
import { ArchivesService, LoaderService, StoreService, UiRoleManagerService } from '@common/core/services';
import { EBitfUiMessageType, ERoleActions } from '@common/enums';
import { IBitfApiResponse, IBitfGraphQlResponse } from '@common/interfaces';
import { ToastMessagesService } from '@web/core/services';

@Component({
  selector: 'mpa-link-lookbooks',
  templateUrl: './link-lookbooks.component.html',
  styleUrls: ['./link-lookbooks.component.scss'],
})
export class LinkLookbooksComponent implements OnInit {
  form: UntypedFormGroup;
  eRoleActions = ERoleActions;

  get selectedItem(): Archive {
    return this.storeService.store.selectedItem;
  }
  constructor(
    public uiRoleManagerService: UiRoleManagerService,
    public storeService: StoreService,
    private translateService: TranslateService,
    private formBuilder: UntypedFormBuilder,
    private archivesService: ArchivesService,
    private loaderService: LoaderService,
    private toastMessagesService: ToastMessagesService
  ) {}

  ngOnInit(): void {
    this.initForm();
  }

  onLink() {
    const inventoryToLink = this.form.value.inventory;
    if (inventoryToLink === this.selectedItem.inventory) {
      this.toastMessagesService.show({
        title: this.translateService.instant(bitfToTranslate('COMMON.LABEL.INVENTORY_NOT_VALID')),
        type: EBitfUiMessageType.ERROR,
      });
      return;
    }

    this.loaderService.show();
    this.archivesService
      .getItem({
        variables: {
          filters: { inventory: { eq: this.form.value.inventory } },
        },
        disableHideLoader: true,
      })
      .pipe(
        // NOTE: Check if the inventory exists
        filter((response: IBitfApiResponse<Archive>) => {
          if (!response.content) {
            this.toastMessagesService.show({
              title: this.translateService.instant(bitfToTranslate('COMMON.LABEL.ITEMS_NOT_FOUND')),
              type: EBitfUiMessageType.ERROR,
            });
            return false;
          }
          return true;
        }),
        switchMap((response: IBitfApiResponse<Archive>) =>
          this.archivesService
            .updateItem({
              id: this.selectedItem.id,
              body: {
                lookbooks_to: [...this.selectedItem.serialised.lookbooks_to, response.content.id],
              },
              disableHideLoader: true,
            })
            .pipe(
              tap((selectedItemResponse: IBitfApiResponse<Archive>) => {
                // NOTE: Update selectedItem
                this.storeService.updateSelectedItem({
                  lookbooks_to: selectedItemResponse.content.lookbooks_to,
                });

                // NOTE: Update the related item
                this.storeService.updateGalleryItem({ id: response.content.id });
              })
            )
        ),
        finalize(() => {
          this.form.reset();
          this.loaderService.hide();
        })
      )
      .subscribe();
  }

  onRemoveLink(archive: Archive) {
    if (!window.confirm(this.translateService.instant('ITEM.DETAIL.REMOVE_LINKED_ITEM'))) {
      return;
    }
    this.loaderService.show();

    this.archivesService
      .updateItem({
        id: this.selectedItem.id,
        body: {
          lookbooks_to: this.selectedItem.serialised.lookbooks_to.filter(
            _archiveId => _archiveId !== archive.id
          ),
          lookbooks_from: this.selectedItem.serialised.lookbooks_from.filter(
            _archiveId => _archiveId !== archive.id
          ),
        },
      })
      .pipe(
        tap((response: IBitfApiResponse<Archive>) => {
          // NOTE: Update selectedItem
          this.storeService.updateSelectedItem({
            lookbooks_to: response.content.lookbooks_to,
            lookbooks_from: response.content.lookbooks_from,
          });

          // NOTE: Update the related item
          this.storeService.updateGalleryItem({ id: archive.id });
        }),
        finalize(() => {
          this.form.reset();
          this.loaderService.hide();
        })
      )
      .subscribe();
  }

  private initForm() {
    this.form = this.formBuilder.group({
      inventory: ['', Validators.required],
    });
  }
}
