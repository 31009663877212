<div class="images-container d-flex w-100"
  [ngClass]="{'flex-row w-100 overflow-auto py-4':isHorizontal, 'flex-column': !isHorizontal}">
  <mat-card class="item p-0"
    [ngClass]="{'mx-auto mr-3':isHorizontal, 'mx-auto mb-3': !isHorizontal, 'item-zoom': isZoom}"
    *ngFor="let item of selectedItemAndHisSuits; let index = index">

    <mpa-item-images [item]="item"
      [imageSize]="(isZoom) ? 'xlarge' : 'small'"></mpa-item-images>

    <div class="p-2 d-flex align-items-center justify-content-between border-top item-actions">
      <div>
        <div class="mat-caption">{{item.description}}</div>
        <div class="d-flex">
          <span>{{item.inventory}}</span>
          <div class="item-icons ml-2">
            <mat-icon class="mr-1"
              *ngIf="item.isInLoanOut"
              matTooltip="{{'COMMON.LABEL.LOAN' | translate}}">work</mat-icon>
            <mat-icon *ngIf="item.suit?.id"
              matTooltip="{{'COMMON.LABEL.SUIT' | translate}}">category</mat-icon>
          </div>
        </div>
      </div>

      <button mat-icon-button
        *ngIf="!isZoom && index > 0"
        color="primary"
        [matTooltip]="'COMMON.LABEL.DETAILS' | translate"
        class="ml-3"
        (click)="onLoadItem(item)">
        <mat-icon>visibility</mat-icon>
      </button>
    </div>

  </mat-card>
</div>