<form (ngSubmit)="onAddLink()"
  [formGroup]="form"
  class="mb-4"
  *ngIf="uiRoleManagerService.canI(eRoleActions.EDIT_ITEM)">
  <h4>{{'ITEM.DETAIL.LINK_NEW_ITEM_TO_SUIT' | translate}}</h4>

  <div class="d-flex align-items-center flex-wrap gap-2">
    <mat-form-field appearance="outline"
      class="form-field-condensed">
      <mat-label>{{'COMMON.LABEL.INVENTORY' | translate}}</mat-label>
      <input matInput
        formControlName="inventory">
    </mat-form-field>

    <!-- <mat-form-field appearance="outline"
      class="mx-3">
      <mat-label>{{'ITEM.DETAIL.SUIT_TYPOLOGY' | translate}}</mat-label>
      <mat-select formControlName="typologyId"
        (selectionChange)="onChangeSuitTypology($event)">
        <mat-option *ngFor="let typology of storeService.store.filters.suitTypologies"
          [value]="typology.id">
          {{typology.name}}
        </mat-option>
      </mat-select>
    </mat-form-field> -->

    <button mat-raised-button
      color="primary"
      [disabled]="!form.valid"
      type="submit">
      {{'ITEM.DETAIL.LINK_ITEM_TO_SUIT' | translate}}
    </button>
  </div>
</form>

<div class="items-list"
  *ngIf="selectedItem.suitItems?.length">
  <h4>{{'ITEM.DETAIL.LINKED_SUIT' | translate}}</h4>

  <mpa-linked-item-row *ngFor="let item of selectedItem.suitItems"
    [item]="item"
    (removeLink)="onRemoveLink($event)"></mpa-linked-item-row>
</div>

<ng-container *ngIf="!selectedItem?.suitItems?.length">
  <h4>{{'ITEM.DETAIL.EMPTY_ITEM_LINKED_SUIT' | translate}}</h4>
</ng-container>