import { Component, EventEmitter, Input, Output } from '@angular/core';

import { Archive } from '@common/core/models';
import { ArchivesService, StoreService, UiRoleManagerService } from '@common/core/services';
import { ERoleActions } from '@common/enums';
import { IBitfGraphQlResponse } from '@common/interfaces';

@Component({
  selector: 'mpa-linked-item-row',
  templateUrl: './linked-item-row.component.html',
  styleUrls: ['./linked-item-row.component.scss'],
})
export class LinkedItemRowComponent {
  @Input() item: Archive;
  @Input() isHighlighted = false;
  @Input() showDeleteButton = true;
  @Input() size: 'default' | 'big' = 'default';
  @Output() removeLink = new EventEmitter();

  eRoleActions = ERoleActions;
  constructor(
    public uiRoleManagerService: UiRoleManagerService,
    public storeService: StoreService,
    private archivesService: ArchivesService
  ) {}

  onLoadItem(archive: Archive) {
    this.archivesService
      .getItemById({ variables: { id: archive.id } })
      .subscribe((response: IBitfGraphQlResponse<Archive>) => {
        this.storeService.setSelectedItem(response.content);
      });
  }
}
