import { SuperModel } from './super-model.model';
import { User } from './user.model';

export abstract class SuperStrapiModel<T = any> extends SuperModel {
  created_by_user: User;
  updated_by_user: User;

  constructor(data, modelInstance?: Partial<T>) {
    super({ id: data?.id ? Number(data.id) : undefined, ...data?.attributes });

    if (modelInstance) {
      Object.assign(this, modelInstance);
      return;
    }

    if (data?.attributes?.created_by_user) {
      this.created_by_user = new User(data.attributes.created_by_user.data);
    }
    if (data?.attributes?.updated_by_user) {
      this.updated_by_user = new User(data.attributes.updated_by_user.data);
    }
  }

  protected deEnumize(string: string): string {
    if (string) {
      return string?.replace(/_/g, ' ') || '';
    }
    return string;
  }

  protected enumize(string: string): string {
    if (string?.replace) {
      return string?.replace(/ /g, '_') || '';
    }
    return undefined;
  }
}
