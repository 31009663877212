import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UiRoleManagerService } from '@common/core/services';
import { ERoleActions } from '@common/enums';
import { IPrintFoldersDialogData, IPrintFoldersData } from '@common/interfaces';
import { EArchiveType, EPrintType } from '@web/enums';
import { environment } from '@env/environment';
import { SuperPrint } from '@web/dynamic-modules/shared/print-strategies/super-print';

@Component({
  selector: 'mpa-dynamic-print-folders-dialog',
  templateUrl: './dynamic-print-folders-dialog.component.html',
  styleUrls: ['./dynamic-print-folders-dialog.component.scss'],
})
export class DynamicPrintFoldersDialogComponent implements OnInit {
  printForm: FormGroup;
  eRoleActions = ERoleActions;
  ePrintType = EPrintType;

  constructor(
    public uiRoleManagerService: UiRoleManagerService,
    public dialogRef: MatDialogRef<DynamicPrintFoldersDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: IPrintFoldersDialogData,
    private formBuilder: FormBuilder
  ) {}

  ngOnInit(): void {
    this.initForm();
  }

  async onPrint() {
    let component: SuperPrint = null;
    const { items, title } = this.dialogData;
    const { layout } = this.printForm.value;

    const printData: IPrintFoldersData = {
      layout,
      items,
      title,
    };

    switch (layout) {
      case EPrintType.LIST:
        switch (environment.archiveType) {
          case EArchiveType.FASHION:
            component = new (
              await import('@web/dynamic-modules/fashion/print-strategies/fashion-print-folders-list')
            ).FashionPrintFoldersList();
            break;
          case EArchiveType.MULTIMEDIA:
            component = new (
              await import(
                // eslint-disable-next-line max-len
                '@web/dynamic-modules/multimedia/print-strategies/multimedia-print-folders-list'
              )
            ).MultimediaPrintFoldersList();
            break;
          case EArchiveType.CELLAR:
            component = new (
              await import(
                // eslint-disable-next-line max-len
                '@web/dynamic-modules/cellar/print-strategies/cellar-print-folders-list'
              )
            ).CellarPrintFoldersList();
            break;
        }

        break;
    }

    component.print(printData);
    this.dialogRef.close();
  }

  private initForm() {
    this.printForm = this.formBuilder.group({
      layout: [this.dialogData.actions[0], Validators.required],
    });
  }
}
