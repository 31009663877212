import { Pipe, PipeTransform } from '@angular/core';
import { DecimalPipe } from '@angular/common';
import { BitfDynamicLocaleService } from '../core/services/locale/bitf-dynamic-locale.service';

@Pipe({ name: 'bitfNumber', pure: false })
export class BitfNumberPipe implements PipeTransform {
  private lastLocale: string;
  private lastResult: string;
  private lastValue: any;
  private decimalPipe: DecimalPipe;

  constructor(private bitfDynamiclocaleService: BitfDynamicLocaleService) {
    this.decimalPipe = new DecimalPipe('en');
  }

  transform(value: any, format?: string, locale?: string): string {
    try {
      locale = locale || this.bitfDynamiclocaleService.locale.code;
      if (value !== this.lastValue || locale !== this.lastLocale) {
        this.lastLocale = locale;
        this.lastResult = this.decimalPipe.transform(value, format, locale);
        this.lastValue = value;
      }

      return this.lastResult;
    } catch (error) {
      console.error(error);
      return value;
    }
  }
}
