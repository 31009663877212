<div class="container-fluid h-100">
  <div class="row flex-grow-1 h-100">
    <div #galleryContainer
      class="col-6 h-100 overflow-auto"
      [ngClass]="{'col-24': isZoom}">

      <mpa-item-images-gallery [item]="item"
        [showDetailAction]="!isZoom"
        [isZoom]="isZoom"></mpa-item-images-gallery>
    </div>

    <div #detailContainer
      class="col-18 h-100 overflow-auto"
      [hidden]="isZoom">
      <mpa-item-details-tabs *ngIf="item"
        [item]="item"
        [isReadOnly]="isReadOnly || !uiRoleManagerService.canI(eRoleActions.EDIT_ITEM)"></mpa-item-details-tabs>
    </div>
  </div>
</div>