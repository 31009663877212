import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';

import { gql } from 'apollo-angular';

import { BitfGraphQlService } from '@bitf/services/graph-ql/bitf-graph-ql.service';

import { IBitfGraphQlRequest, IBitfGraphQlResponse } from '@interfaces';
import { ArchiveSale } from '@models';
import { ARCHIVE_SALE_ENTITY } from './archive-sales.gql';
import { tap } from 'rxjs/operators';
import { PAGINATION_ENTITY } from './strapi.gql';

@Injectable({
  providedIn: 'root',
})
// NOTE: Add methods following CRUD order
export class ArchiveSalesService extends BitfGraphQlService {
  constructor(public injector: Injector) {
    super(injector);
  }

  createItem(requestParams: IBitfGraphQlRequest = {}) {
    requestParams.modelMapper = 'createArchiveSale';
    requestParams.mutation = gql`
      ${ARCHIVE_SALE_ENTITY}
      mutation Create($input: ArchiveSaleInput!) {
        createArchiveSale(data: $input) {
          data {
            ...ArchiveSaleEntity
          }
        }
      }
    `;

    return super.mutate<ArchiveSale>(requestParams);
  }

  getItemById(requestParams: IBitfGraphQlRequest): Observable<IBitfGraphQlResponse<ArchiveSale>> {
    requestParams.modelMapper = 'archiveSale';
    requestParams.query = gql`
      ${ARCHIVE_SALE_ENTITY}
      query Query($id: ID) {
        archiveSale(id: $id) {
          data {
            ...ArchiveSaleEntity
          }
        }
      }
    `;

    return super.query<ArchiveSale>(requestParams);
  }

  getItems(requestParams: IBitfGraphQlRequest = {}): Observable<IBitfGraphQlResponse<ArchiveSale[]>> {
    requestParams.modelMapper = 'archiveSales';
    requestParams.query = gql`
      ${ARCHIVE_SALE_ENTITY}
      ${PAGINATION_ENTITY}
      query Query($pagination: PaginationArg, $sort: [String], $filters: ArchiveSaleFiltersInput) {
        archiveSales(pagination: $pagination, sort: $sort, filters: $filters) {
          data {
            ...ArchiveSaleEntity
          }
          meta {
            pagination {
              ...PaginationEntity
            }
          }
        }
      }
    `;

    return super.query<ArchiveSale[]>(requestParams);
  }

  updateItem(
    requestParams: IBitfGraphQlRequest<Partial<ArchiveSale>> = {},
    saleArchive?: Partial<ArchiveSale>
  ): Observable<IBitfGraphQlResponse<ArchiveSale>> {
    requestParams.modelMapper = 'updateArchiveSale';
    requestParams.mutation = gql`
      ${ARCHIVE_SALE_ENTITY}
      mutation Update($id: ID!, $input: ArchiveSaleInput!) {
        updateArchiveSale(id: $id, data: $input) {
          data {
            ...ArchiveSaleEntity
          }
        }
      }
    `;
    return super.mutate<ArchiveSale>(requestParams, saleArchive).pipe(
      tap((response: IBitfGraphQlResponse<ArchiveSale>) => {
        if (saleArchive) {
          Object.assign(saleArchive, response.content);
        }
      })
    );
  }

  deleteItem(requestParams: IBitfGraphQlRequest = {}) {
    requestParams.modelMapper = 'deleteArchiveSale';
    requestParams.mutation = gql`
      mutation Mutate($id: ID!) {
        deleteArchiveSale(id: $id) {
          data {
            id
          }
        }
      }
    `;
    return super.mutate<ArchiveSale>(requestParams);
  }
}
