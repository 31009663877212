import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { gql } from 'apollo-angular';

import { BitfGraphQlService } from '@bitf/services/graph-ql/bitf-graph-ql.service';

import { IBitfGraphQlRequest, IBitfGraphQlResponse } from '@interfaces';
import { Image } from '@models';
import { IMAGE_ENTITY } from './images.gql';

@Injectable({
  providedIn: 'root',
})
// NOTE: Add methods following CRUD order
export class FilesService extends BitfGraphQlService {
  constructor(public injector: Injector) {
    super(injector);
  }

  createImage(requestParams: IBitfGraphQlRequest = {}): Observable<IBitfGraphQlResponse<Image>> {
    requestParams.variables.file.resetUploadState();
    requestParams.variables.file.isUploading = true;
    requestParams.modelMapper = 'upload';
    requestParams.mutation = gql`
      ${IMAGE_ENTITY}
      mutation Create($file: Upload!) {
        upload(file: $file) {
          data {
            ...ImageEntity
          }
        }
      }
    `;

    return super.mutate<Image>(requestParams).pipe(
      catchError(error => {
        requestParams.variables.file.isUploading = false;
        requestParams.variables.file.hasUploadErrors = true;
        return error;
      }),
      tap(_ => {
        if (requestParams.variables.file) {
          requestParams.variables.file.isUploading = false;
          requestParams.variables.file.isUploaded = true;
        }
      })
    );
  }
}
