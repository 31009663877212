import { formatDate } from '@angular/common';

import { IBitfParseResult, IBitfRange } from '@interfaces';

const defaultFormats = [
  'y-M-d',
  'd-M-y',
  'MMM d, y',
  'MMM d,y',
  'y-MMM-d',
  'd-MMM-y',
  'MMM d',
  'MMM-d',
  'd-MMM',
];

export function bitfParseDateFromString(value: string, format?: string): Date {
  if (!value) {
    return null;
  }

  // If no format is specified, try default formats
  if (!format || format === '') {
    for (const f of defaultFormats) {
      const d = bitfParseDateFromString(value, f);
      if (d != null) {
        return d;
      }
    }
    return null;
  }

  let valueCurrentIndex = 0;
  let formatCurrentIndex = 0;
  let year = 0;
  let month = 0;
  let day = 0;

  while (formatCurrentIndex < format.length) {
    let formatToken = '';

    // Get next token from format string
    const c = format.charAt(formatCurrentIndex);
    while (format.charAt(formatCurrentIndex) === c && formatCurrentIndex < format.length) {
      formatToken += format.charAt(formatCurrentIndex++);
    }

    // Extract contents of value based on format token
    formatToken = formatToken.toLowerCase();
    if (formatToken === 'yyyy' || formatToken === 'yy' || formatToken === 'y') {
      // Year part
      const { result, isSuccess, offset } = bitfParseYear(value, valueCurrentIndex, formatToken);
      if (!isSuccess) {
        console.error(`Error parsing year part of ${value}, format ${format}`);
        return null;
      }

      valueCurrentIndex += offset;
      year = result;
    } else if (formatToken === 'mm' || formatToken === 'm') {
      // Month part
      const { result, isSuccess, offset } = bitfParseMonth(value, valueCurrentIndex, formatToken);
      if (!isSuccess) {
        console.error(`Error parsing month part of ${value}, format ${format}`);
        return null;
      }

      valueCurrentIndex += offset;
      month = result;
    } else if (formatToken === 'dd' || formatToken === 'd') {
      // Day part
      const { result, isSuccess, offset } = bitfParseDay(value, valueCurrentIndex, formatToken);
      if (!isSuccess) {
        console.error(`Error parsing day part of ${value}, format ${format}`);
        return null;
      }

      valueCurrentIndex += offset;
      day = result;
    } else {
      if (value.substring(valueCurrentIndex, valueCurrentIndex + formatToken.length) !== formatToken) {
        return null;
      } else {
        valueCurrentIndex += formatToken.length;
      }
    }
  }

  // If there are any trailing characters left in the value, it doesn't match
  if (valueCurrentIndex !== value.length) {
    console.error(`Error parsing date ${value}, format ${format}, trailing data is present`);
    return null;
  }

  // Check if day is correct according to month
  if (month === 2) {
    const isLeapYear = (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
    if ((isLeapYear && day > 29) || (isLeapYear && day > 28)) {
      console.error(`Error, day ${day} for month ${month} is not possible`);
      return null;
    }
  }

  if (month === 4 || month === 6 || month === 9 || month === 11) {
    if (day > 30) {
      console.error(`Error, day ${day} for month ${month} is not possible`);
    }
  }

  return new Date(year, month - 1, day);
}

export function bitfIsDateValid(value: string, format?: string) {
  return bitfParseDateFromString(value, format) !== null;
}

function bitfIsInt(str: string): boolean {
  for (let i = 0; i < str.length; i++) {
    if ('1234567890'.indexOf(str.charAt(i)) === -1) {
      return false;
    }
  }

  return true;
}

function bitfGetIntSubstring(str: string, i: number, lengthRange: IBitfRange): string {
  for (let x = lengthRange.max; x >= lengthRange.min; x--) {
    const token = str.substring(i, i + x);

    if (token.length < lengthRange.min) {
      return null;
    }

    if (bitfIsInt(token)) {
      return token;
    }
  }

  return null;
}

function bitfParseYear(fullString: string, startIndex: number, format: string): IBitfParseResult<number> {
  let lengthRange;

  if (format === 'yyyy') {
    lengthRange = { min: 4, max: 4 };
  }
  if (format === 'yy') {
    lengthRange = { min: 2, max: 2 };
  }
  if (format === 'y') {
    lengthRange = { min: 2, max: 4 };
  }

  const yearStr = bitfGetIntSubstring(fullString, startIndex, lengthRange);
  let result = parseInt(yearStr, 10);

  if (!yearStr || isNaN(result)) {
    return {
      isSuccess: false,
    } as IBitfParseResult<number>;
  }

  if (yearStr.length === 2) {
    if (result > 70) {
      result = 1900 + result;
    } else {
      result = 2000 + result;
    }
  }

  return {
    result,
    isSuccess: true,
    offset: yearStr.length,
  };
}

function bitfParseMonth(fullString: string, startIndex: number, format: string): IBitfParseResult<number> {
  const monthStr = bitfGetIntSubstring(fullString, startIndex, { min: format.length, max: 2 });

  const result = parseInt(monthStr, 10);
  if (!monthStr == null || isNaN(result) || result < 1 || result > 12) {
    return {
      isSuccess: false,
    } as IBitfParseResult<number>;
  }

  return { result, isSuccess: true, offset: monthStr.length };
}

function bitfParseDay(fullString: string, startIndex: number, format: string): IBitfParseResult<number> {
  const dayStr = bitfGetIntSubstring(fullString, startIndex, { min: format.length, max: 2 });

  const result = parseInt(dayStr, 10);
  if (!dayStr == null || isNaN(result) || result < 1 || result > 31) {
    return { isSuccess: false } as IBitfParseResult<number>;
  }

  return { result, isSuccess: true, offset: dayStr.length };
}

export function bitfFormatDateISO(date: Date) {
  return formatDate(date, 'yyyy-MM-dd', 'en');
}

export function getDateWithoutTime(date: Date) {
  return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(
    date.getDate()
  ).padStart(2, '0')}`;
}

export const ONE_DAY_MS = 24 * 60 * 60 * 1000;
