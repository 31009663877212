<h1 mat-dialog-title>{{'COMMON.LABEL.PRINT' | translate}}</h1>

<ng-container [formGroup]="printForm">

  <div mat-dialog-content>
    <section *ngIf="printForm">

      <mat-radio-group formControlName="layout"
        class="row">

        <div class="col-auto d-flex flex-column">

          <mat-radio-button color="primary"
            [value]="ePrintType.LIST"
            *ngIf="dialogData.actions.includes(ePrintType.LIST)">{{'COMMON.LABEL.LIST' | translate}}
          </mat-radio-button>

        </div>

      </mat-radio-group>

    </section>
  </div>

  <div mat-dialog-actions
    class="d-flex justify-content-between">
    <div>
    </div>

    <div>
      <button mat-stroked-button
        mat-dialog-close
        color="primary">{{'BITF.LABEL.CANCEL' | translate}}</button>

      <button mat-raised-button
        color="primary"
        (click)="onPrint()">{{'COMMON.LABEL.PRINT' | translate}}</button>
    </div>
  </div>

</ng-container>