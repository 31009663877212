import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ArchiveLoan, Loan } from '@common/core/models';
import { ArchiveLoansService, LoaderService, LoansService, StoreService } from '@common/core/services';
import { EBitfApiSortDirection, EBitfCloseEventStatus, EBitfUiMessageType } from '@common/enums';
import { IBitfCloseEvent, IBitfGraphQlResponse, ICreateUpdateLoanDialogData } from '@common/interfaces';
import { marker as bitfToTranslate } from '@biesbjerg/ngx-translate-extract-marker';
import { TranslateService } from '@ngx-translate/core';
import { ToastMessagesService } from '@web/core/services';
import { tap, finalize } from 'rxjs/operators';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';

@Component({
  selector: 'mpa-bulk-add-to-loan-dialog',
  templateUrl: './bulk-add-to-loan-dialog.component.html',
  styleUrls: ['./bulk-add-to-loan-dialog.component.scss'],
})
export class BulkAddToLoanDialogComponent implements OnInit {
  public form: UntypedFormGroup;
  public loans: Loan[] = [];
  public filteredLoans: Loan[] = [];
  public selectedLoan: Loan;

  constructor(
    public dialogRef: MatDialogRef<BulkAddToLoanDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: ICreateUpdateLoanDialogData,
    private archiveLoansService: ArchiveLoansService,
    private loansService: LoansService,
    private toastMessagesService: ToastMessagesService,
    private translateService: TranslateService,
    private loaderService: LoaderService,
    private storeService: StoreService
  ) {}

  ngOnInit(): void {
    this.initForm();
    this.initLoansList();
  }

  onSelectLoan(event: MatAutocompleteSelectedEvent) {
    this.selectedLoan = event.option.value;
  }

  onSave() {
    const loan = this.form.value.loan as Loan;
    let validItems = [...this.dialogData.items];

    const loanItemIds = loan.archive_loans.map(archiveLoan => archiveLoan.id);
    validItems = validItems.filter(item => {
      const itemLoanIds = item.archive_loans.map(archiveLoan => archiveLoan.id);
      // NOTE: Filter out the items that are already in selectedLoan or out
      return !item.isInLoanOut && !itemLoanIds.some(id => loanItemIds.includes(id));
    });

    if (validItems.length < this.dialogData.items.length) {
      this.toastMessagesService.show({
        title: this.translateService.instant(bitfToTranslate('LOAN.SOME_ITEMS_ARE_ALREADY_IN_LOAN')),
        type: EBitfUiMessageType.INFO,
      });
    }
    if (!validItems.length) {
      this.dialogRef.close({
        status: EBitfCloseEventStatus.OK,
      } as IBitfCloseEvent<void>);
      return;
    }

    const observableList = validItems.map(item => {
      const archiveLoan = new ArchiveLoan({}, { archive: item, loan, date_out: loan.date_out });

      return this.archiveLoansService
        .createItem({
          disableHideLoader: true,
          body: archiveLoan,
        })
        .pipe(
          // NOTE: we've to update the gallery items with the new archive_loans
          // to eventually show the loan icon
          tap((r: IBitfGraphQlResponse<ArchiveLoan>) => {
            this.storeService.updateGalleryItem({ id: r.content.archive.id, disableHideLoader: true });
          })
        );
    });

    this.loaderService.show();
    this.archiveLoansService
      .getThrottledObservables(observableList)
      .pipe(
        finalize(() => {
          this.loaderService.hide();

          this.toastMessagesService.show({
            title: this.translateService.instant(bitfToTranslate('LOAN.ITEMS_ADDED'), {
              loan: this.form.value.loan.name,
            }),
            type: EBitfUiMessageType.SUCCESS,
          });

          this.dialogRef.close({
            status: EBitfCloseEventStatus.OK,
          } as IBitfCloseEvent<void>);
        })
      )
      .subscribe();
  }

  onClose() {
    this.dialogRef.close({
      status: EBitfCloseEventStatus.CANCEL,
    } as IBitfCloseEvent<void>);
  }

  displayFn(loan): string {
    return loan?.name ? `ID:${loan.id} - ${loan.name}` : '';
  }

  onClearLoanInput() {
    this.form.patchValue({ loan: '' }, { emitEvent: false });
    this.filteredLoans = this.loans.filter(option => option.name.toLowerCase().includes(''));
  }

  private initForm() {
    this.form = new UntypedFormGroup({
      loan: new UntypedFormControl(undefined, Validators.required),
    });

    this.form.controls.loan.valueChanges.subscribe(loanSearch => {
      if (typeof loanSearch === 'string') {
        this.filteredLoans = this.loans.filter(
          option =>
            option.name.toLowerCase().includes(loanSearch.toLowerCase()) ||
            option.id.toString().includes(loanSearch.toLowerCase())
        );
        if (this.filteredLoans.length === 1) {
          this.selectedLoan = this.filteredLoans[0];
          this.form.patchValue({ loan: this.selectedLoan }, { emitEvent: false });
        } else {
          this.selectedLoan = undefined;
        }
      } else if (loanSearch instanceof Loan) {
        // If the loan is selected clicking in the list
        this.selectedLoan = this.filteredLoans[0];
      }
    });
  }

  private initLoansList() {
    this.loansService
      .getList({
        page: 0,
        size: 100000,
        sorting: [
          {
            property: 'createdAt',
            direction: EBitfApiSortDirection.DESC,
          },
        ],
      })
      .subscribe(res => {
        this.loans = res.content;
        this.filteredLoans = [...res.content];
      });
  }
}
