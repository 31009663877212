import { Component, OnDestroy, OnInit } from '@angular/core';

import { Archive } from '@common/core/models';
import { ApiCallStateService, ArchivesService, StoreService } from '@common/core/services';
import { EApiCallStateNames, eStoreActions } from '@web/enums';
import { Subscription } from 'rxjs';

@Component({
  selector: 'mpa-view-neighbours',
  templateUrl: './view-neighbours.component.html',
  styleUrls: ['./view-neighbours.component.scss'],
})
export class ViewNeighboursComponent implements OnInit, OnDestroy {
  isLoading = false;
  neighbours: Archive[] = [];
  apiCallStateName: EApiCallStateNames;
  private subscription: Subscription = new Subscription();

  get selectedItem(): Archive {
    return this.storeService.store.selectedItem;
  }
  constructor(
    private apiCallStateService: ApiCallStateService,
    private storeService: StoreService,
    private archivesService: ArchivesService
  ) {}

  ngOnInit(): void {
    if (this.storeService.store.selectedFolder) {
      this.apiCallStateName = EApiCallStateNames.FOLDER_DETAILS;
    } else {
      this.apiCallStateName = EApiCallStateNames.ARCHIVE;
    }
    this.loadData();

    this.storeService.selectStore([eStoreActions.SET_SELECTED_ITEM]).subscribe(() => {
      this.loadData();
    });
  }

  private loadData() {
    this.neighbours = [];
    this.isLoading = true;
    const requestParams = JSON.parse(
      JSON.stringify(this.apiCallStateService.getApiRequest(this.apiCallStateName))
    );
    if (this.selectedItem) {
      delete requestParams.search;
      requestParams.filter.unshift({ id: { eq: this.selectedItem.id } });

      this.subscription.add(
        this.archivesService.getNeighbours(requestParams).subscribe(res => {
          this.isLoading = false;
          this.neighbours = res.content;
        })
      );
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
