import { bitfFormatDateISO } from '@common/libs/bitforce/utils/bitf-dates.utils';

import { ArchiveLoan } from './archive-loan.model';
import { SuperStrapiModel } from './super-strapi.model';

export class Loan extends SuperStrapiModel {
  name: string;
  date_out: Date;
  note: string;
  reference: string;
  archive_loans: ArchiveLoan[];
  is_visible_to_editor: boolean;
  is_visible_to_collaborator: boolean;
  is_visible_to_ufficio_stile: boolean;
  is_visible_to_viewer: boolean;

  constructor(data, modelInstance?: Partial<Loan>) {
    super(data, modelInstance);

    if (modelInstance) {
      if (this.date_out !== null && this.date_out instanceof Date === false) {
        this.date_out = new Date(this.date_out);
      }
      return;
    }

    if (data.attributes?.archive_loans?.data) {
      this.archive_loans = data.attributes.archive_loans.data.map(
        archiveLoan => new ArchiveLoan(archiveLoan)
      );
    }

    if (data.attributes?.date_out) {
      this.date_out = new Date(data.attributes?.date_out);
    }
  }

  get totalIn() {
    return this.archive_loans?.filter(archiveLoan => archiveLoan.isIn).length;
  }

  get totalOut() {
    return this.archive_loans?.filter(archiveLoan => archiveLoan.isOut).length;
  }

  get isClosed() {
    return this.totalIn === this.archive_loans.length;
  }

  get serialised() {
    return {
      id: this.id,
      name: this.name,
      date_out: this.date_out && bitfFormatDateISO(this.date_out),
      note: this.note,
      reference: this.reference,
      archive_loans: !!this.archive_loans
        ? this.archive_loans.map(archiveLoan => archiveLoan.serialised)
        : this.archive_loans,
      is_visible_to_editor: this.is_visible_to_editor,
      is_visible_to_collaborator: this.is_visible_to_collaborator,
      is_visible_to_ufficio_stile: this.is_visible_to_ufficio_stile,
      is_visible_to_viewer: this.is_visible_to_viewer,
    };
  }
}
