import { TYPOLOGY_NESTED_RELATIONS_ENTITY } from './multimedia-typologies.gql';

export const FILTER = `
  ${TYPOLOGY_NESTED_RELATIONS_ENTITY}
  query Filters {
    typologies: t1S(pagination: { limit: 300 }, sort: ["name:ASC"]) {
      data {
        ...NestedTypologies
      }
    }
    formats(pagination: { limit: 500 }, sort: ["name:ASC"]) {
      data {
        id
        attributes {
          name
        }
      }
    }
    directors(pagination: { limit: 500 }, sort: ["name:ASC"]) {
      data {
        id
        attributes {
          name
        }
      }
    }
    languages(pagination: { limit: 500 }, sort: ["name:ASC"]) {
      data {
        id
        attributes {
          name
        }
      }
    }
  }
`;
