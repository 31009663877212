import { Observable, Subject } from 'rxjs';
import { marker as bitfToTranslate } from '@biesbjerg/ngx-translate-extract-marker';

import { IBitfCloseEvent } from './bitf.interface';

export interface IBitfDialogService {
  dialog: IBitfDialog;
}
export interface IBitfDialog {
  // TODO options need a bitf type
  open: (component: any, options: any) => IBitfDialogRef;
  closeAll?: () => any;
  getDialogById?: (id: string) => any;
  afterAllClosed?: Observable<void>;
  afterOpen?: Subject<any>;
  openDialogs?: Array<any>;
}

export interface IBitfDialogRef {
  afterClosed: () => Observable<IBitfCloseEvent<unknown>>;
  close: (dialogResult: IBitfCloseEvent<unknown>) => void;
  afterOpen?: () => Observable<void>;
  backdropClick?: () => Observable<MouseEvent>;
  beforeClose?: () => Observable<any>;
  keydownEvents?: () => Observable<KeyboardEvent>;
  updatePosition?: () => any;
  updateSize?: () => any;
}

export interface IBitfOkCancelDialogData {
  title: string;
  message: string;
  cancelText: string;
  okText: string;
  okButtonType?: 'PRIMARY' | 'SECONDARY' | 'TERTIARY' | 'WARNING' | 'DANGER';
  prompt?: IBitfOkCancelDialogDataPrompt;
}

bitfToTranslate('BITF.DIALOGS.BUTTON_OK_TEXT');
bitfToTranslate('BITF.DIALOGS.BUTTON_OK_TEXT_TO_SAVE');
bitfToTranslate('BITF.DIALOGS.BUTTON_CANCEL_TEXT');

export interface IBitfOkCancelDialogDataPrompt {
  value?: string;
  placeholder?: string;
  isRequired?: boolean;
  label?: string;
  inputType?:
    | 'button'
    | 'checkbox'
    | 'color'
    | 'date'
    | 'datetime-local'
    | 'email'
    | 'file'
    | 'hidden'
    | 'image'
    | 'month'
    | 'number'
    | 'password'
    | 'radio'
    | 'range'
    | 'reset'
    | 'search'
    | 'submit'
    | 'tel'
    | 'text'
    | 'time'
    | 'url'
    | 'week';
}

export interface IBitfInputDialogData extends IBitfOkCancelDialogData {
  defaultValue: string;
  placeholder: string;
}
