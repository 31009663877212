export enum EPrintType {
  DETAILS = 'DETAILS',
  GRID_1 = 'GRID_1',
  GRID_2_LANDSCAPE = 'GRID_2_LANDSCAPE',
  GRID_2 = 'GRID_2',
  GRID_3 = 'GRID_3',
  LIST = 'LIST',
  LABEL = 'LABEL',
  LOAN_LIST = 'LOAN_LIST',
  LOAN_DELIVERY_NOTE = 'LOAN_DELIVERY_NOTE',
  SALE_LIST = 'SALE_LIST',
  SALE_DELIVERY_NOTE = 'SALE_DELIVERY_NOTE',
}
