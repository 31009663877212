import {
  ARCHIVE_BASE_PROPERTIES,
  ARCHIVE_LOAN,
  FOLDER_ENTITY,
  IMAGE_ENTITY,
  LOAN_ENTITY,
  PAGINATION_ENTITY,
} from '@common/core/services';
import { TYPOLOGIES } from './cellar-typologies.gql';

export const ARCHIVE_SHARED_PROPS_AND_RELATIONS = `
  ${IMAGE_ENTITY}
  ${TYPOLOGIES}
  fragment ArchiveSharedPropsAndRelations on Archive {
    production_date
    purchase_date
    seasoning_date
    weight
    purchase_price_kg
    createdAt
    updatedAt
    ...Typologies
    images {
      data {
        ...ImageEntity
      }
    }
    apartment {
      data {
        id
        attributes {
          name
        }
      }
    }
    place {
      data {
        id
        attributes {
          name
        }
      }
    }
    shelf {
      data {
        id
        attributes {
          name
        }
      }
    }
    sector {
      data {
        id
        attributes {
          name
        }
      }
    }
    box {
      data {
        id
        attributes {
          name
        }
      }
    }
    producer {
      data {
        id
        attributes {
          name
          address
          city
          province
          zip
          country
          email
          phone
          vat_number
        }
      }
    }
    distributor {
      data {
        id
        attributes {
          name
          address
          city
          province
          zip
          country
          email
          phone
          vat_number
        }
      }
    }
    race {
      data {
        id
        attributes {
          name
          seasoning_months
        }
      }
    }
    sex {
      data {
        id
        attributes {
          name
        }
      }
    }
  }
`;

export const ARCHIVE_GALLERY = `
${PAGINATION_ENTITY}
${ARCHIVE_BASE_PROPERTIES}
${IMAGE_ENTITY}
query Query($pagination: PaginationArg, $sort: [String], $filters: ArchiveFiltersInput) {
  archives(pagination: $pagination, sort: $sort, filters: $filters) {
    data {
      id
      attributes {
        ...ArchiveBaseProperty
        seasoning_date
        images {
          data {
            ...ImageEntity
          }
        }
        archive_loans {
          data {
            id
            attributes {
              date_out
              loan {
                data {
                  id
                }
              }
            }
          }
        }
      }
    }
    meta {
      pagination {
        ...PaginationEntity
      }
    }
  }
}
`;

export const ARCHIVE_PRINT_DATA = `
  ${PAGINATION_ENTITY}
  ${ARCHIVE_BASE_PROPERTIES}
  ${ARCHIVE_SHARED_PROPS_AND_RELATIONS}
  query Query($pagination: PaginationArg, $sort: [String], $filters: ArchiveFiltersInput) {
    archives(pagination: $pagination, sort: $sort, filters: $filters) {
      data {
        id
        attributes {
          ...ArchiveBaseProperty
          ...ArchiveSharedPropsAndRelations
        }
      }
      meta {
        pagination {
          ...PaginationEntity
        }
      }
    }
  }
`;

export const LOAN_PRINT_DATA = `
  ${ARCHIVE_BASE_PROPERTIES}
  ${ARCHIVE_SHARED_PROPS_AND_RELATIONS}
  ${IMAGE_ENTITY}
  ${TYPOLOGIES}
  query Query($id: ID) {
    loan(id: $id) {
      data {
        attributes {
          note
          reference
          date_out
          archive_loans(pagination: { limit: 1000 }) {
            data {
              id
              attributes {
                archive {
                  data {
                    id
                    attributes {
                      ...ArchiveBaseProperty
                      ...ArchiveSharedPropsAndRelations
                      ...Typologies
                      images {
                        data {
                          ...ImageEntity
                        }
                      }
                    }
                  }
                }
                note_out
                date_out
                office_note
              }
            }
          }
        }
      }
    }
  }
`;

export const SALE_PRINT_DATA = `
  ${ARCHIVE_BASE_PROPERTIES}
  ${ARCHIVE_SHARED_PROPS_AND_RELATIONS}
  ${IMAGE_ENTITY}
  ${TYPOLOGIES}
  query Query($id: ID) {
    sale(id: $id) {
      data {
        attributes {
          note
          reference
          date
          archive_sales(pagination: { limit: 1000 }) {
            data {
              id
              attributes {
                archive {
                  data {
                    id
                    attributes {
                      ...ArchiveBaseProperty
                      ...ArchiveSharedPropsAndRelations
                      ...Typologies
                      images {
                        data {
                          ...ImageEntity
                        }
                      }
                    }
                  }
                }
                note_out
              }
            }
          }
        }
      }
    }
  }
`;

export const ARCHIVE_WITH_ALL_RELATIONS_ENTITY = `
  ${ARCHIVE_BASE_PROPERTIES}
  ${ARCHIVE_SHARED_PROPS_AND_RELATIONS}
  ${FOLDER_ENTITY}
  ${ARCHIVE_LOAN}
  ${LOAN_ENTITY}
  fragment ArchiveWithAllRelationsEntity on ArchiveEntity {
    id
    attributes {
      ...ArchiveBaseProperty
      ...ArchiveSharedPropsAndRelations
      folders {
        data {
          ...FolderEntity
        }
      }
      archive_loans {
        data {
          id
          attributes {
            ...ArchiveLoan
            loan {
              data {
                ...LoanEntity
              }
            }
          }
        }
      }
    }
  }
`;
