import {
  BitfStrapiGraphQlRequestMapper,
  BitfStrapiGraphQlEnvelopeMapper,
  BitfStrapiGraphQlResponseMapper,
} from '@common/libs/bitforce/core/parsers/strapi-graph-ql-parser';
import { AppEnvelopeMapper, AppPathBuilder, AppRequestMapper, AppResponseMapper } from '@web/core/parsers';

import { IBitfParserStrategy, IBitfGraphQlParserStrategy } from '@interfaces';

export const BITF_CONFIGS: any = {
  toastMessage: { durationMs: 5000 },
  apiErrorsInterceptorService: { interceptHttpResponsesWithCode: ['0', '401', '4XX', '5XX'] },
  parsers: {
    defaultParser: 'appParser',
    defaultGraphQlParser: 'strapiParser',
    parserStrategies: {
      appParser: {
        pathBuilder: new AppPathBuilder(),
        requestMapper: new AppRequestMapper(),
        responseMapper: new AppResponseMapper(),
        envelopeMapper: new AppEnvelopeMapper(),
      } as IBitfParserStrategy,
      strapiParser: {
        requestMapper: new BitfStrapiGraphQlRequestMapper(),
        envelopeMapper: new BitfStrapiGraphQlEnvelopeMapper(),
        responseMapper: new BitfStrapiGraphQlResponseMapper(),
      } as IBitfGraphQlParserStrategy,
    },
  },
};
