import { SuperStrapiModel } from './super-strapi.model';

export class Typology extends SuperStrapiModel {
  name: string;
  children: Typology[];

  constructor(data) {
    super(data);

    if (data?.attributes?.children) {
      this.children = data.attributes.children.data.map(child => new Typology(child));
    }
  }

  get serialised() {
    return {
      id: this.id,
      name: this.name,
    };
  }
}
