import { ARCHIVE_SALE, ARCHIVE_SALE_ENTITY } from './archive-sales.gql';

export const SALE = `
  fragment Sale on Sale {
    id
    attributes {
      name
      date
      note
      reference
    }
  }
`;

export const SALE_ENTITY = `
  fragment SaleEntity on SaleEntity {
    id
    attributes {
      name
      date
      note
      reference
    }
  }
`;

export const SALE_WITH_ALL_RELATIONS_ENTITY = `
  ${ARCHIVE_SALE_ENTITY}
  fragment SaleWithAllRelationsEntity on SaleEntity {
    id
    attributes {
      name
      date
      note
      reference
      archive_sales(pagination: { limit: 200 }) {
        data {
          ...ArchiveSaleEntity
        }
      }
    }
  }
`;

export const SALE_LIST_ENTITY = `
  ${ARCHIVE_SALE}
  fragment SaleListEntity on SaleEntity {
    id
    attributes {
      name
      date
      note
      reference
      createdAt
      archive_sales(pagination: { limit: 1000 }) {
        data {
          id
          attributes {
            ...ArchiveSale
            archive {
              data {
                id
                attributes {
                  quantity
                  value

                  is_visible_to_editor
                  is_visible_to_collaborator
                  is_visible_to_ufficio_stile
                  is_visible_to_viewer
                }
              }
            }
          }
        }
      }
    }
  }
`;
