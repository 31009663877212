const typologyProperties = `
  name
`;

export const TYPOLOGY_1_ENTITY = `
  fragment Typology1Entity on T1Entity {
    id
    attributes {
      ${typologyProperties}
    }
  }
`;
export const TYPOLOGY_2_ENTITY = `
  fragment Typology2Entity on T2Entity {
    id
    attributes {
      ${typologyProperties}
    }
  }
`;
export const TYPOLOGY_3_ENTITY = `
  fragment Typology3Entity on T3Entity {
    id
    attributes {
      ${typologyProperties}
    }
  }
`;
export const TYPOLOGY_4_ENTITY = `
  fragment Typology4Entity on T4Entity {
    id
    attributes {
      ${typologyProperties}
    }
  }
`;
