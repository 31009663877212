import { SuperStrapiModel } from './super-strapi.model';

export class Configuration extends SuperStrapiModel {
  archive_tooltip_properties: string[];
  folder_identifier: number;
  loan_identifier: number;

  constructor(data?: any, modelInstance?: Partial<Configuration>) {
    super(data, modelInstance);

    if (modelInstance) {
      return;
    }

    if (!data?.attributes?.archive_tooltip_properties) {
      this.archive_tooltip_properties = [];
    }

    if (!data?.attributes?.folder_identifier) {
      this.folder_identifier = 1;
    }

    if (!data?.attributes?.loan_identifier) {
      this.loan_identifier = 1;
    }
  }

  get serialised() {
    return {
      archive_tooltip_properties: this.archive_tooltip_properties,
      folder_identifier: this.folder_identifier,
      loan_identifier: this.loan_identifier,
    };
  }
}
