<div class="dialog-stretch">
  <!-- HEADER -->
  <div class="d-flex justify-content-between pt-2"
    mat-dialog-title>
    <h2 class="mb-0 mat-headline-6  w-100 d-flex align-items-center">{{'FOLDER.COPY_ITEMS' | translate}}</h2>
    <button mat-icon-button
      (click)="onClose()">
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <mat-dialog-content class="py-2">
    <form [formGroup]="form"
      (submit)="onSave()">
      <mat-form-field appearance="outline"
        class="w-100">
        <mat-label>{{'COMMON.LABEL.FOLDER' | translate}}</mat-label>
        <input type="text"
          matInput
          formControlName="folder"
          [matAutocomplete]="auto">
        <button *ngIf="form?.value.folder"
          matSuffix
          mat-icon-button
          aria-label="Clear"
          (click)="onClearFolderInput()">
          <mat-icon>close</mat-icon>
        </button>
        <mat-autocomplete #auto="matAutocomplete"
          [displayWith]="displayFn"
          (optionSelected)="onSelectFolder($event)">
          <mat-option *ngFor="let folder of filteredFolders"
            [value]="folder">
            ID:{{folder.id}} - {{folder.name | translate}}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </form>
  </mat-dialog-content>

  <mat-dialog-actions align="end"
    class="d-flex align-items-center">
    <!-- ACTIONS -->
    <button mat-flat-button
      (click)="onClose()">
      {{ 'BITF.DIALOGS.BUTTON_CANCEL_TEXT' | translate }}
    </button>
    <button mat-flat-button
      color="primary"
      [disabled]="!form.valid || !this.selectedFolder"
      (click)="onSave()">
      {{ 'BITF.DIALOGS.BUTTON_OK_TEXT_TO_SAVE' | translate }}
    </button>
  </mat-dialog-actions>
</div>