import { SuperStrapiModel } from './super-strapi.model';

export class Race extends SuperStrapiModel {
  name: string;
  seasoning_months: number;

  constructor(data = {}) {
    super(data);
  }

  get serialised() {
    return {
      id: this.id,
      name: this.name,
      seasoning_months: this.seasoning_months,
    };
  }
}
