import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { BITF_CONFIGS } from '@web/configs';
import { Observable } from 'rxjs';
import { StoreService } from '../store.service';
import { UiRoleManagerService } from '../ui-role-manager.service';

@Injectable({
  providedIn: 'root',
})
export class UiRoleGuard {
  constructor(
    private router: Router,
    private storeService: StoreService,
    private uiRoleManagerService: UiRoleManagerService
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (
      next.data.roleAction &&
      !this.uiRoleManagerService.canI(next.data.roleAction) &&
      this.storeService.store.user
    ) {
      this.router.navigate([BITF_CONFIGS.urls.homePageUrl]);
      return false;
    }

    return true;
  }
}
