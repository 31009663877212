import { marker as bitfToTranslate } from '@biesbjerg/ngx-translate-extract-marker';

export const CONSTANTS = {
  META_TAGS: {
    TITLE: {
      DEFAULT_TITLE: bitfToTranslate('COMMON.META_TAGS.DEFAULT_TITLE'),
      POSTFIX: bitfToTranslate('COMMON.META_TAGS.TITLE_POSTFIX'),
    },
    DESCRIPTION: bitfToTranslate('COMMON.META_TAGS.DESCRIPTION'),
  },
  SUPPORTED_LANGUAGES: [
    { code: 'en', label: 'English' },
    { code: 'it', label: 'Italiano' },
  ],
  DEFAULT_LANGUAGE: { code: 'en', label: 'English' },
  LOCAL_STORAGE_VERSION: 1,
  MAX_PRINTABLE_ITEMS: 300,
  MAX_PRINTABLE_FOLDERS: 1000,
  MAX_PRINTABLE_LOANS: 1000,
  MAX_PRINTABLE_SALES: 1000,

  // Dialog sizes
  FULL_SCREEN_DIALOG_SIZE: { height: '95vh', maxHeight: '95vh', width: '95vw', maxWidth: '95vw' },
  MID_DIALOG_SIZE: { width: '500px', maxWidth: '90%', height: 'auto', maxHeight: '70%' },
  SMALL_DIALOG_SIZE: { width: '80vw', maxWidth: '600px' },
};
