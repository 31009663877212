import {
  OnInit,
  Input,
  Output,
  EventEmitter,
  Injector,
  ViewChild,
  ElementRef,
  Component,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { IBitfFileValidationRule, IBitfFileUploaderConfig } from '@interfaces';
import { bitfGenerateFilesList, bitfValidateFiles } from '@bitf/utils/bitf-files.utils';
import { BitfFile } from '@bitf/core/models/bitf-file.model';
import { marker as bitfToTranslate } from '@biesbjerg/ngx-translate-extract-marker';
import { EBitfUiMessageType } from '@bitf/enums';

import { CONSTANTS } from '@constants';
import { ToastMessagesService, DialogsService } from '@services';

@Component({
  selector: 'bitf-file-uploader',
  template: '',
})
export abstract class BitfFileUploaderComponent<T> implements OnInit, IBitfFileUploaderConfig {
  /** Number of selectable files */
  @Input() maxNumberOfFiles = 1000;

  /** Extensions valid for upload, used for validation */
  @Input() extensions: string[];

  /** Maximum size for each file, used for validation */
  @Input() maxFileSize: number;

  /** Concatenate selected files to files array */
  @Input() concatenateMultipleFiles = true;

  /** Enable the display of the list of files */
  @Input() showFileList = true;

  /** View the toast or dialog with errors */
  @Input() showValidationErrorMessage = false;

  /** Displays the drag & drop area */
  @Input() showDndArea = true;

  /** Emit the "startUpload" event as soon as the files are selected */
  @Input() uploadOnFilesDropped = false;

  /** Hide the upload button */
  @Input() hideUploadButton = true;

  /** Enable full folder upload */
  @Input() uploadFolder = false;

  /** Upload the configuration of the file uploader via object */
  @Input() fileUploaderConfig: IBitfFileUploaderConfig = {};

  /** Event for uploaded files */
  @Output() filesUploaded = new EventEmitter<T | T[]>();

  /** Event for deleted all files */
  @Output() cancel = new EventEmitter<any>();

  /** Event for start uploading */
  @Output() startUpload = new EventEmitter<any>();

  @ViewChild('selectFile', { static: false }) selectFile: ElementRef<any>;
  @ViewChild('selectDirectory', { static: false }) selectDirectory: ElementRef<any>;

  protected valiationRule: IBitfFileValidationRule;
  public files: BitfFile[] = [];
  get uploadableFiles(): BitfFile[] {
    return this.files.filter(f => f.isValid && !f.isUploading && !f.isUploaded);
  }
  get uploadedFiles(): BitfFile[] {
    return this.files.filter(f => f.isUploaded);
  }

  protected dialogsService: DialogsService;
  protected translateService: TranslateService;
  private toastMessagesService: ToastMessagesService;

  constructor(public injector: Injector) {
    this.toastMessagesService = this.injector.get<ToastMessagesService>(ToastMessagesService);
    this.translateService = this.injector.get<TranslateService>(TranslateService);
    this.dialogsService = this.injector.get<DialogsService>(DialogsService);
  }

  ngOnInit() {
    bitfToTranslate('BITF.FILE_UPLOADER.DIALOG_UPLOAD_ERROR_TITLE');
    bitfToTranslate('BITF.LABEL.CLOSE');
    bitfToTranslate('BITF.LABEL.OK');

    Object.assign(this, this.fileUploaderConfig);

    this.valiationRule = {
      extensions: this.extensions,
      maxFileSize: this.maxFileSize,
    } as IBitfFileValidationRule;

    /*
     * Requested to false, otherwise the previous files remain in arrays and it is possible to delete
     * them from the list
     */
    if (!this.showFileList) {
      this.concatenateMultipleFiles = false;
    }
  }

  /**
   * This method is called when files are selected or dragged into the drag & drop area
   *
   * @param event selected files list from Drag&Drop area or from input
   */
  onFileDropped(event: FileList | any) {
    const fileList = event instanceof FileList ? event : event.srcElement.files;

    const bitFiles = bitfGenerateFilesList(fileList).filter(
      // Use this match to enable uploading of files like 'emf' that don't have the content/type
      // Use the "find" to not load a file with the same name
      file =>
        file.fileObject.name.match(/\.[a-zA-Z0-9]{3,4}$/) &&
        !this.files.find(_file => _file.fileObject.name === file.fileObject.name)
    );

    // NOTE: this solves the problem of uploading the same file after it is deleted from the list
    // because that file already exists in the FileList object.
    if (document.getElementById('selectFile')) {
      document.getElementById('selectFile')['value'] = '';
    }
    if (this.selectDirectory?.nativeElement?.value) {
      this.selectDirectory.nativeElement.value = '';
    }
    if (this.uploadFolder && this.selectDirectory) {
      this.selectDirectory.nativeElement.value = '';
    }

    if (this.concatenateMultipleFiles) {
      this.files = this.files.concat(bitFiles);
    } else {
      this.files = bitFiles;
    }

    if (this.files.length > this.maxNumberOfFiles) {
      this.files.length = this.maxNumberOfFiles;
      this.toastMessagesService.show({
        type: EBitfUiMessageType.WARNING,
        title: this.translateService.instant(bitfToTranslate('COMMON.LABEL.INFORMATION')),
        message: this.translateService.instant(
          bitfToTranslate('BITF.FILE_UPLOADER.TOO_MANY_FILES_SELECTED'),
          { limit: this.maxNumberOfFiles }
        ),
      });
    }

    bitfValidateFiles(this.files, this.valiationRule, this.translateService);
    if (this.showValidationErrorMessage && this.files.some(file => !file.isValid)) {
      this.onValidationError();
    }

    if (this.uploadOnFilesDropped && this.uploadableFiles.length > 0) {
      this.upload();
    }
  }

  /**
   * Emit only the upload event
   */
  upload() {
    this.startUpload.next();
  }

  /**
   * Removes file by index from files array.
   */
  removeFile(index: number) {
    this.files.splice(index, 1);
  }

  /**
   * Removes all files from files array, and emit "cancel" event
   */
  removeAllFiles() {
    this.files = [];
    this.cancel.emit();
  }

  /**
   * Removes all files in isUploaded = TRUE state, from files array
   */
  removeUploadedFiles() {
    this.files = this.files.filter(f => !f.isUploaded);
  }

  /**
   * This method is called in case showValidationErrorMessage is TRUE and there is at least 1 file
   * that has validation errors
   */
  onValidationError() {
    const title = this.translateService.instant('BITF.FILE_UPLOADER.DIALOG_UPLOAD_ERROR_TITLE');
    if (this.files.length > 1) {
      const message = this.files
        .map(file => `<b>${file.fileObject.name}</b><br/>${file.validationErrors.join('<br/>')}`)
        .join('<br/><br/>');
      this.showValidationErrorDialog(title, message);
    } else {
      const message = this.files.map(file => `${file.validationErrors.join('. ')}`);
      this.showValidationErrorToast('', message);
    }
  }

  /**
   * Show errors in a toast if the list of uploaded files is not visible, recommended for a few error.
   *
   * @param title Title of toast message
   * @param message content of the toast message
   */
  showValidationErrorToast(title, message) {
    this.toastMessagesService.show({
      title,
      message,
      type: EBitfUiMessageType.ERROR,
      duration: 5000,
    });
  }

  /**
   * Show errors in a dialog if the list of uploaded files is not visible
   *
   * @param title Title of toast message
   * @param message content of the toast message
   */
  showValidationErrorDialog(title, message) {
    this.dialogsService.dialog.open(CONSTANTS.okCancelDialogComponent, {
      width: '500px',
      maxWidth: '90%',
      height: 'auto',
      maxHeight: '70%',
      data: {
        title,
        message,
        cancelText: this.translateService.instant('BITF.LABEL.CLOSE'),
        okText: this.translateService.instant('BITF.LABEL.OK'),
      },
    });
  }
}
