export const USER_RELATION_ENTITY = `
  fragment UserRelationEntity on Folder {
    created_by_user {
      data {
        attributes {
          email
        }
      }
    }
  }
`;
