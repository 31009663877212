<div class="dialog-stretch">
  <!-- HEADER -->
  <div class="d-flex justify-content-between pt-2"
    mat-dialog-title>
    <h2 class="mb-0 mat-headline-6  w-100 d-flex align-items-center">
      <span *ngIf="!isInEdit">{{'FOLDER.CREATE' | translate}}</span>
      <span *ngIf="isInEdit">{{'FOLDER.EDIT' | translate}}</span>
    </h2>
    <button mat-icon-button
      (click)="onClose()">
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <mat-dialog-content class="py-2">
    <form [formGroup]="form"
      (submit)="onSave()">
      <mat-form-field appearance="outline"
        class="w-100">
        <mat-label>{{'FOLDER.NAME' | translate}}</mat-label>
        <input matInput
          formControlName="name"
          type="text">
      </mat-form-field>

      <mat-checkbox formControlName="is_visible_to_editor"
        *bitfUiRoleManager="{action : eRoleActions.MANAGE_EDITOR_FOLDERS}">
        <span>{{ 'FOLDER.IS_VISIBLE_TO_EDITOR' | translate}}</span>
      </mat-checkbox>
      <mat-checkbox formControlName="is_visible_to_collaborator"
        *bitfUiRoleManager="{action : eRoleActions.MANAGE_COLLABORATOR_FOLDERS}">
        <span>{{ 'FOLDER.IS_VISIBLE_TO_COLLABORATOR' | translate}}</span>
      </mat-checkbox>
      <mat-checkbox formControlName="is_visible_to_ufficio_stile"
        *bitfUiRoleManager="{action : eRoleActions.MANAGE_UFFICIO_STILE_FOLDERS}">
        <span>{{ 'FOLDER.IS_VISIBLE_TO_UFFICIO_STILE' | translate}}</span>
      </mat-checkbox>
      <mat-checkbox formControlName="is_visible_to_viewer"
        *bitfUiRoleManager="{action : eRoleActions.MANAGE_VIEWER_FOLDERS}">
        <span>{{ 'FOLDER.IS_VISIBLE_TO_VIEWER' | translate}}</span>
      </mat-checkbox>
    </form>
  </mat-dialog-content>

  <mat-dialog-actions align="end"
    class="d-flex align-items-center">
    <!-- ACTIONS -->
    <button mat-flat-button
      (click)="onClose()">
      {{ 'BITF.DIALOGS.BUTTON_CANCEL_TEXT' | translate }}
    </button>
    <button mat-flat-button
      color="primary"
      [disabled]="!form.valid"
      (click)="onSave()">
      {{ 'BITF.DIALOGS.BUTTON_OK_TEXT_TO_SAVE' | translate }}
    </button>
  </mat-dialog-actions>
</div>