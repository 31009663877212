<form (ngSubmit)="onCopyDataOnLink()"
  [formGroup]="form"
  class="mb-4"
  *ngIf="uiRoleManagerService.canI(eRoleActions.EDIT_ITEM)">
  <h4>{{'ITEM.DETAIL.COPY_DATA' | translate}}</h4>

  <div class="d-flex align-items-center gap-2">
    <mat-form-field appearance="outline"
      class="form-field-condensed">
      <mat-label>{{'COMMON.LABEL.INVENTORY' | translate}}</mat-label>
      <input matInput
        formControlName="inventory">
    </mat-form-field>

    <button mat-raised-button
      color="primary"
      [disabled]="!form.valid"
      type="submit">
      {{'COMMON.LABEL.COPY' | translate}}
    </button>
  </div>
</form>