import { Component, Input, OnInit, ViewContainerRef } from '@angular/core';
import { EApiCallStateNames, EArchiveType } from '@web/enums';
import { environment } from '@env/environment';
import { CreateDynamicComponentService } from '@web/core/services/create-dynamic-component.service';

@Component({
  selector: 'mpa-dynamic-gallery-sorts',
  templateUrl: './dynamic-gallery-sorts.component.html',
  styleUrls: ['./dynamic-gallery-sorts.component.scss'],
})
export class DynamicGallerySortsComponent implements OnInit {
  @Input() apiCallStateName: EApiCallStateNames;

  constructor(
    private createDynamicComponentService: CreateDynamicComponentService,
    private viewContainerRef: ViewContainerRef
  ) {}

  async ngOnInit() {
    let component = null;

    switch (environment.archiveType) {
      case EArchiveType.FASHION:
        component = (
          await import('@web/dynamic-modules/fashion/fashion-gallery-sorts/fashion-gallery-sorts.component')
        ).FashionGallerySortsComponent;
        break;
      case EArchiveType.MULTIMEDIA:
        component = (
          await import(
            '@web/dynamic-modules/multimedia/multimedia-gallery-sorts/multimedia-gallery-sorts.component'
          )
        ).MultimediaGallerySortsComponent;
        break;
      case EArchiveType.CELLAR:
        component = (
          await import('@web/dynamic-modules/cellar/cellar-gallery-sorts/cellar-gallery-sorts.component')
        ).CellarGallerySortsComponent;
        break;
    }

    await this.createDynamicComponentService.loadComponent(component, this.viewContainerRef, {
      apiCallStateName: this.apiCallStateName,
    });
  }
}
