import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Loan } from '@common/core/models';
import { StoreService } from '@common/core/services';
import { ERoleActions, ERoles } from '@common/enums';

@Component({
  selector: 'mpa-create-update-loan',
  templateUrl: './create-update-loan.component.html',
  styleUrls: ['./create-update-loan.component.scss'],
})
export class CreateUpdateLoanComponent implements OnInit {
  @Input() loan: Loan;
  @Input() isReadOnly: boolean;
  public form: UntypedFormGroup;
  eRoleActions = ERoleActions;

  constructor(private storeService: StoreService) {}

  ngOnInit(): void {
    this.initForm();
  }

  private initForm() {
    this.form = new UntypedFormGroup({
      name: new UntypedFormControl({ value: undefined, disabled: this.isReadOnly }, Validators.required),
      reference: new UntypedFormControl({ value: undefined, disabled: this.isReadOnly }),
      date_out: new UntypedFormControl({ value: undefined, disabled: this.isReadOnly }, [
        Validators.required,
      ]),
      note: new UntypedFormControl({ value: undefined, disabled: this.isReadOnly }),
      is_visible_to_editor: new UntypedFormControl(
        this.loan?.is_visible_to_editor ||
          (!this.loan && this.storeService.store.user.role.name === ERoles.EDITOR)
      ),
      is_visible_to_collaborator: new UntypedFormControl(
        this.loan?.is_visible_to_collaborator ||
          (!this.loan && this.storeService.store.user.role.name === ERoles.COLLABORATOR)
      ),
      is_visible_to_ufficio_stile: new UntypedFormControl(this.loan?.is_visible_to_ufficio_stile || false),
      is_visible_to_viewer: new UntypedFormControl(this.loan?.is_visible_to_viewer || false),
    });

    if (this.loan) {
      this.form.patchValue(this.loan);
    } else {
      this.form.patchValue({
        date_out: new Date(),
      });
    }
  }
}
