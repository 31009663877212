<h1 mat-dialog-title>{{'COMMON.LABEL.PRINT' | translate}}</h1>

<ng-container [formGroup]="printForm">
  <mat-dialog-content>
    <p>
      {{'PRINT.MAX_PRINTABLE_ITEMS_INFO_MESSAGE' | translate: { limit: maxPrintableItems } }}
    </p>
    <mat-radio-group formControlName="page"
      class="row">
      <div class="col-8 my-2"
        *ngFor="let page of pages">
        <mat-radio-button color="primary"
          [value]="page">
          [{{(page - 1) * (maxPrintableItems ) + 1}} -
          {{getMin(maxPrintableItems * page, dialogData )}}]
        </mat-radio-button>
      </div>
    </mat-radio-group>
  </mat-dialog-content>

  <mat-dialog-actions class="d-flex justify-content-end">
    <button mat-stroked-button
      mat-dialog-close
      color="primary">{{'BITF.LABEL.CANCEL' | translate}}</button>

    <button mat-raised-button
      color="primary"
      [disabled]="printForm.invalid"
      (click)="onPrint()">{{'COMMON.LABEL.PRINT' | translate}}</button>
  </mat-dialog-actions>
</ng-container>