import { Injectable, Injector, Type, ViewContainerRef, createNgModule } from '@angular/core';
import { environment } from '@env/environment';
import { EArchiveType } from '@web/enums';

@Injectable({
  providedIn: 'root',
})
export class CreateDynamicComponentService {
  constructor(private injector: Injector) {}

  async loadComponent<T>(
    component: Type<T>,
    containerRef: ViewContainerRef,
    inputs?: { [key: string]: unknown }
  ): Promise<T> {
    let module = null;

    switch (environment.archiveType) {
      case EArchiveType.FASHION:
        module = (await import('@web/dynamic-modules/fashion/fashion.module')).FashionModule;
        break;
      case EArchiveType.MULTIMEDIA:
        module = (await import('@web/dynamic-modules/multimedia/multimedia.module')).MultimediaModule;
        break;
      case EArchiveType.CELLAR:
        module = (await import('@web/dynamic-modules/cellar/cellar.module')).CellarModule;
        break;
    }

    const moduleRef = createNgModule(module, this.injector);
    containerRef.clear();

    const componentRef = containerRef.createComponent(component, { ngModuleRef: moduleRef });
    if (inputs) {
      Object.keys(inputs).forEach(key => {
        componentRef.setInput(key, inputs[key]);
      });
    }

    return componentRef.instance;
  }
}
