export function bitfRange(start: number, end: number, interval?: number) {
  if (typeof start !== 'number') {
    throw new Error('start must be a number');
  }
  if (typeof end !== 'number') {
    throw new Error('end must be a number');
  }
  const v = [];
  interval = interval || 1;
  for (let i = start; i <= end; i = i + interval) {
    v.push(i);
  }
  return v;
}

export function bitfFindDuplicatesInArray(arr: string[]) {
  const counter: { [key: string]: number } = {};
  const duplicates: string[] = [];
  arr.forEach(item => {
    counter[item] = counter[item] ? counter[item] + 1 : 1;
    if (counter[item] === 2) {
      duplicates.push(item);
    }
  });
  return duplicates;
}

export function chunkArray(items, size): Array<Array<any>> {
  return Array.from({ length: Math.ceil(items.length / size) }, (v, i) =>
    items.slice(i * size, i * size + size)
  );
}
