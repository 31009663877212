import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { BitfJwtAuthService } from '@bitf/services/auth/jwt/bitf-jwt-auth.service';

import { EBitfAuthState } from '@enums';
import { IBitfApiResponse, IBitfGraphQlRequest } from '@interfaces';
import { BitfStrapiJwtService } from './bitf-strapi.service';
import { User } from '@models';

@Injectable({
  providedIn: 'root',
})
export abstract class BitfStrapiAuthService extends BitfJwtAuthService {
  bitfStrapiService: BitfStrapiJwtService;
  tokenAlertTresholdMinutes = 5;

  constructor(protected injector: Injector) {
    super(injector);
    this.bitfStrapiService = this.injector.get(BitfStrapiJwtService);
  }

  listenForAuthStateChange(): Observable<EBitfAuthState> {
    return this.authState$.pipe(
      tap((authState: EBitfAuthState) => {
        if (authState === EBitfAuthState.TOKEN_RETRIEVED) {
          this.usersService.getMe().subscribe(
            (response: IBitfApiResponse<User>) => {
              this.setUser(response.content);
              this.onLoginSuccess();
              this.handleRedirect();
            },
            () => {
              this.onLoginError();
            }
          );
        }
      })
    );
  }

  handleAuthentication(requestParams: IBitfGraphQlRequest) {
    this.authState$.next(EBitfAuthState.LOGIN_IN_PROGRESS);
    this.bitfStrapiService.getToken(requestParams).subscribe(
      response => {
        this.signIn({ encodedToken: response.content.jwt });
      },
      () => {
        this.onLoginError();
      }
    );
  }
}
