<h1 mat-dialog-title>{{'COMMON.LABEL.PRINT' | translate}}</h1>

<ng-container [formGroup]="printForm">

  <div mat-dialog-content>
    <section *ngIf="printForm">

      <mat-radio-group formControlName="layout"
        class="row">

        <div class="col-auto d-flex flex-column">
          <mat-radio-button color="primary"
            [value]="ePrintType.DETAILS"
            *ngIf="dialogData.actions.includes(ePrintType.DETAILS)">{{'COMMON.LABEL.DETAILS' | translate}}
          </mat-radio-button>

          <mat-radio-button color="primary"
            [value]="ePrintType.LIST"
            *ngIf="dialogData.actions.includes(ePrintType.LIST)">{{'COMMON.LABEL.LIST' | translate}}
          </mat-radio-button>

          <mat-radio-button color="primary"
            [value]="ePrintType.LABEL"
            *ngIf="dialogData.actions.includes(ePrintType.LABEL)">{{'COMMON.LABEL.LABEL' | translate}}
          </mat-radio-button>
        </div>

        <div class="col-auto d-flex flex-column">
          <mat-radio-button color="primary"
            [value]="ePrintType.GRID_1"
            *ngIf="dialogData.actions.includes(ePrintType.GRID_1)">
            1x1 (1 {{'COMMON.LABEL.ITEMS' | translate}})
          </mat-radio-button>

          <mat-radio-button color="primary"
            [value]="ePrintType.GRID_2_LANDSCAPE"
            *ngIf="dialogData.actions.includes(ePrintType.GRID_2_LANDSCAPE)">
            2x1 (2 {{'COMMON.LABEL.ITEMS' | translate}})
          </mat-radio-button>

          <mat-radio-button color="primary"
            [value]="ePrintType.GRID_2"
            *ngIf="dialogData.actions.includes(ePrintType.GRID_2)">
            2x3 (6 {{'COMMON.LABEL.ITEMS' | translate}})
          </mat-radio-button>

          <mat-radio-button color="primary"
            [value]="ePrintType.GRID_3"
            *ngIf="dialogData.actions.includes(ePrintType.GRID_3)">
            3x3 (9 {{'COMMON.LABEL.ITEMS' | translate}})
          </mat-radio-button>
        </div>

        <div class="col-auto d-flex flex-column">
          <mat-radio-button color="primary"
            [value]="ePrintType.LOAN_LIST"
            *ngIf="dialogData.actions.includes(ePrintType.LOAN_LIST)">{{'COMMON.LABEL.LOAN_LIST' | translate}}
          </mat-radio-button>

          <mat-radio-button color="primary"
            [value]="ePrintType.LOAN_DELIVERY_NOTE"
            *ngIf="dialogData.actions.includes(ePrintType.LOAN_DELIVERY_NOTE)">
            {{'COMMON.LABEL.LOAN_DELIVERY_NOTE' | translate}}
          </mat-radio-button>
        </div>

        <div class="col-auto d-flex flex-column">
          <mat-radio-button color="primary"
            [value]="ePrintType.SALE_LIST"
            *ngIf="dialogData.actions.includes(ePrintType.SALE_LIST)">{{'COMMON.LABEL.SALE_LIST' | translate}}
          </mat-radio-button>

          <mat-radio-button color="primary"
            [value]="ePrintType.SALE_DELIVERY_NOTE"
            *ngIf="dialogData.actions.includes(ePrintType.SALE_DELIVERY_NOTE)">
            {{'COMMON.LABEL.SALE_DELIVERY_NOTE' | translate}}
          </mat-radio-button>
        </div>

      </mat-radio-group>

      <mat-form-field appearance="outline"
        class="mt-2"
        *ngIf="printForm.get('layout').value === ePrintType.LABEL">
        <mat-label>{{'PRINT.SKIP_N_OF_LABELS' | translate}}</mat-label>
        <input matInput
          formControlName="skipNumberOfLabels"
          type="number">
      </mat-form-field>
    </section>
  </div>

  <div mat-dialog-actions
    class="d-flex justify-content-between align-items-end">
    <div class="flex-1">
      <mat-checkbox *ngIf="dialogData.title && printForm.get('layout').value !== ePrintType.LABEL"
        class="mr-2"
        color="primary"
        formControlName="showTitle">{{'PRINT.PRINT_TITLE' | translate}}</mat-checkbox>

      <mat-checkbox
        *ngIf="uiRoleManagerService.canI(eRoleActions.VIEW_VALUE) && printForm.get('layout').value !== ePrintType.LOAN_LIST && printForm.get('layout').value !== ePrintType.LOAN_DELIVERY_NOTE && printForm.get('layout').value !== ePrintType.SALE_LIST && printForm.get('layout').value !== ePrintType.SALE_DELIVERY_NOTE && printForm.get('layout').value !== ePrintType.LABEL"
        class="mr-2"
        color="primary"
        formControlName="showValue">{{'PRINT.PRINT_VALUE' | translate}}</mat-checkbox>

      <ng-container
        *ngIf="printForm.get('layout').value === ePrintType.GRID_1 || printForm.get('layout').value === ePrintType.GRID_2_LANDSCAPE || printForm.get('layout').value === ePrintType.GRID_2 || printForm.get('layout').value === ePrintType.GRID_3">
        <mat-checkbox color="primary"
          formControlName="showNote">{{'PRINT.PRINT_NOTE' | translate}}</mat-checkbox>

        <mat-checkbox color="primary"
          formControlName="showTypologies">{{printTypologyLabel | translate}}</mat-checkbox>
      </ng-container>

      <mat-checkbox *ngIf="printForm.get('layout').value === ePrintType.LABEL"
        class="mr-2"
        color="primary"
        formControlName="showImg">{{'PRINT.PRINT_IMG' | translate}}</mat-checkbox>

      <mat-checkbox *ngIf="printForm.get('layout').value === ePrintType.GRID_1"
        class="mr-2"
        color="primary"
        formControlName="showAllImages">{{'PRINT.PRINT_ALL_IMG' | translate}}</mat-checkbox>

    </div>

    <div>
      <button mat-stroked-button
        mat-dialog-close
        color="primary">{{'BITF.LABEL.CANCEL' | translate}}</button>

      <button mat-raised-button
        color="primary"
        (click)="onPrint()">{{'COMMON.LABEL.PRINT' | translate}}</button>
    </div>
  </div>

</ng-container>