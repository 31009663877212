import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { of, Subscription } from 'rxjs';
import { filter, map, switchMap, tap, finalize } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

import { marker as bitfToTranslate } from '@biesbjerg/ngx-translate-extract-marker';

import { Archive, Suit } from '@common/core/models';
import {
  ArchivesService,
  LoaderService,
  StoreService,
  SuitsService,
  UiRoleManagerService,
} from '@common/core/services';
import { IBitfApiResponse } from '@common/interfaces';
import { EBitfUiMessageType, ERoleActions, eStoreActions } from '@web/enums';
import { ToastMessagesService } from '@web/core/services';

@Component({
  selector: 'mpa-link-suit',
  templateUrl: './link-suit.component.html',
  styleUrls: ['./link-suit.component.scss'],
})
export class LinkSuitComponent implements OnInit, OnDestroy {
  public form: UntypedFormGroup;
  public suitItems: Archive[] = [];
  public eRoleActions = ERoleActions;

  private subscription = new Subscription();

  get selectedItem(): Archive {
    return this.storeService.store.selectedItem;
  }

  constructor(
    public storeService: StoreService,
    private archivesService: ArchivesService,
    private translateService: TranslateService,
    private formBuilder: UntypedFormBuilder,
    private loaderService: LoaderService,
    private suitsService: SuitsService,
    public uiRoleManagerService: UiRoleManagerService,
    private toastMessagesService: ToastMessagesService
  ) {}

  ngOnInit(): void {
    this.initForm();
    this.subscription.add(
      this.storeService
        .selectStore([eStoreActions.SET_SELECTED_ITEM, eStoreActions.UPDATE_SELECTED_ITEM])
        .subscribe(() => {
          this.initForm();
        })
    );
  }

  onAddLink() {
    const inventoryToLink = this.form.value.inventory;
    if (inventoryToLink === this.selectedItem.inventory) {
      this.toastMessagesService.show({
        title: this.translateService.instant(bitfToTranslate('COMMON.LABEL.INVENTORY_NOT_VALID')),
        type: EBitfUiMessageType.ERROR,
      });
      return;
    }

    this.loaderService.show();
    this.archivesService
      .getItem({
        variables: {
          filters: { inventory: { eq: inventoryToLink } },
        },
      })
      .pipe(
        // NOTE: Check if the inventory exists
        filter((response: IBitfApiResponse<Archive>) => {
          if (!response.content) {
            this.toastMessagesService.show({
              title: this.translateService.instant(bitfToTranslate('COMMON.LABEL.ITEMS_NOT_FOUND')),
              type: EBitfUiMessageType.ERROR,
            });
            return false;
          }
          return true;
        }),

        // NOTE: If the new item is already inside a suit we've to ask the user to move it
        // in the new suit
        filter((response: IBitfApiResponse<Archive>) => {
          if (response.content.suit?.id) {
            return window.confirm(this.translateService.instant('ITEM.DETAIL.ITEM_HAS_ALREADY_SUIT_HINT'));
          }
          // NOTE: The new item is not inside any suit, we can proceed to link it
          return true;
        }),

        // NOTE: Create new suit or return selectedItem.suit
        switchMap((response: IBitfApiResponse<Archive>) => {
          if (this.selectedItem.suit?.id) {
            // NOTE: The suit exists, we can return it
            return of({ itemToAdd: response.content, suit: this.selectedItem.suit });
          } else {
            // NOTE: The suit must be created
            this.loaderService.show();
            return this.suitsService
              .create({
                body: new Suit(),
                // body: new Suit({}, { s_t_1: new SuitTypology({ id: this.form.value.typologyId }) }),
              })
              .pipe(
                map((responseNewSuit: IBitfApiResponse<Suit>) => ({
                  itemToAdd: response.content,
                  suit: responseNewSuit.content,
                }))
              );
          }
        }),

        // NOTE: Update the suit with the new item(s)
        switchMap(({ itemToAdd, suit }: { itemToAdd: Archive; suit: Suit }) => {
          suit.archives.push(itemToAdd);
          if (suit.archives.length === 1) {
            // NOTE: This is a brand new suit, we've to add also the selectedItem
            suit.archives.push(this.selectedItem);
          }

          this.loaderService.show();
          return this.suitsService
            .update({
              body: suit,
            })
            .pipe(
              tap(() => {
                // NOTE: If we are here the suits it's already updated and the itemToAdd
                // have been added to the new suit. We can safely check if it's the case
                // to delete his previous suit if that contains only one item.
                // We use tap, and not blocking switchMap since this it's a cleaning
                // behaviour it's not related to this action (add item to a suit)
                if (itemToAdd.suit?.archives && itemToAdd.suit.archives?.length <= 2) {
                  itemToAdd.suit.archives.forEach(archive => {
                    // NOTE: update all the items contained in the suit in the galleryItem with undefined
                    // since those are not anymore inside a suit
                    this.storeService.updateGalleryItem({ id: archive.id });
                  });
                  this.suitsService.delete({ id: itemToAdd.suit.id }).subscribe();
                }
              }),
              tap((response: IBitfApiResponse<Suit>) => {
                response.content.archives.forEach(archive => {
                  // NOTE: update all the items contained in the suit in the galleryItem with the new suit
                  this.storeService.updateGalleryItem({ id: archive.id });
                });
              })
            );
        }),
        finalize(() => {
          this.loaderService.hide();
        })
      )
      .subscribe((response: IBitfApiResponse<Suit>) => {
        this.storeService.updateSelectedItem({ suit: response.content });
      });
  }

  onRemoveLink(archive: Archive) {
    if (window.confirm(this.translateService.instant('ITEM.DETAIL.REMOVE_LINKED_ITEM'))) {
      this.loaderService.show();

      const suit = this.selectedItem.suit;

      // NOTE: We are removing the last item and we can delete the suit
      if (suit.archives.length === 2) {
        this.suitsService
          .delete({ id: suit.id })
          .pipe(
            tap(() => {
              this.storeService.updateSelectedItem({ suit: undefined });
              // NOTE: Item removed from the suit
              this.storeService.updateGalleryItem({ id: archive.id });
            })
          )
          .subscribe();
      } else {
        // NOTE: We can update the suit
        suit.archives = suit.archives.filter(a => a.id !== archive.id);
        this.suitsService
          .update({ body: suit })
          .pipe(
            tap((response: IBitfApiResponse<Suit>) => {
              this.storeService.updateSelectedItem({ suit: response.content });

              // NOTE: Items still in the suit
              suit.archives.forEach(a => {
                // NOTE: update all the items contained in the suit in the galleryItem with the new suit
                this.storeService.updateGalleryItem({ id: a.id });
              });
              // NOTE: Item removed from the suit
              this.storeService.updateGalleryItem({ id: archive.id });
            })
          )
          .subscribe();
      }
    }
  }

  // onChangeSuitTypology({ value }: MatSelectChange) {
  //   if (this.selectedItem?.suit?.id) {
  //     this.suitsService
  //       .update({ id: this.selectedItem.suit.id, body: { s_t_1: value } }, this.selectedItem.suit)
  //       .subscribe((response: IBitfGraphQlResponse<Suit>) => {
  //         this.storeService.updateSelectedItem({
  //           suit: response.content,
  //         });
  //       });
  //   }
  // }

  private initForm() {
    this.form = this.formBuilder.group({
      inventory: [null, Validators.required],
      // typologyId: [this.selectedItem.suit?.s_t_1?.id, Validators.required],
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
