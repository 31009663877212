<div [formGroup]="form"
  class="row">

  <mat-form-field appearance="outline"
    class="col-12"
    [ngStyle]="{'font-size': fontSize}">
    <mat-label>{{typology1Label | translate}}</mat-label>
    <mat-select multiple
      [disabled]="isReadOnly"
      formControlName="t_1s"
      (valueChange)="onT1Change($event)"
      [compareWith]="compareById">
      <mat-option [value]="item"
        *ngFor="let item of filters.typologies;">{{item.name}}</mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field appearance="outline"
    class="col-12"
    *ngIf="numberOfTypologies > 1"
    [ngStyle]="{'font-size': fontSize}">
    <mat-label>{{typology2Label | translate}}</mat-label>
    <mat-select multiple
      [disabled]="isReadOnly"
      formControlName="t_2s"
      (valueChange)="onT2Change($event)"
      [compareWith]="compareById">

      <mat-optgroup *ngFor="let optionsGroup of t2"
        [label]="optionsGroup.parentTypology.name">
        <mat-option *ngFor="let option of optionsGroup.options"
          [value]="option">
          {{option.name}}
        </mat-option>
      </mat-optgroup>

    </mat-select>
  </mat-form-field>

  <mat-form-field appearance="outline"
    class="col-12"
    *ngIf="numberOfTypologies > 2"
    [ngStyle]="{'font-size': fontSize}">
    <mat-label>{{typology3Label | translate}}</mat-label>
    <mat-select multiple
      [disabled]="isReadOnly"
      formControlName="t_3s"
      (valueChange)="onT3Change($event)"
      [compareWith]="compareById">

      <mat-optgroup *ngFor="let optionsGroup of t3"
        [label]="optionsGroup.parentTypology.name">
        <mat-option *ngFor="let option of optionsGroup.options"
          [value]="option">
          {{option.name}}
        </mat-option>
      </mat-optgroup>

    </mat-select>
  </mat-form-field>

  <mat-form-field appearance="outline"
    class="col-12"
    *ngIf="numberOfTypologies > 3"
    [ngStyle]="{'font-size': fontSize}">
    <mat-label>{{typology4Label | translate}}</mat-label>
    <mat-select multiple
      [disabled]="isReadOnly"
      formControlName="t_4s"
      (valueChange)="onT4Change()"
      [compareWith]="compareById">

      <mat-optgroup *ngFor="let optionsGroup of t4"
        [label]="optionsGroup.parentTypology.name">
        <mat-option *ngFor="let option of optionsGroup.options"
          [value]="option">
          {{option.name}}
        </mat-option>
      </mat-optgroup>
    </mat-select>
  </mat-form-field>

</div>