import { BitfGraphQlRequestMapper } from '../graph-ql-parser/bitf-graph-ql-request.mapper';
import { EBitfGraphQlParsers } from '@enums';

export class BitfStrapiGraphQlRequestMapper extends BitfGraphQlRequestMapper {
  name = EBitfGraphQlParsers.BITF_STRAPI_GRAPHQL_PARSER;

  constructor() {
    super();
  }

  protected mapFiltersVariables({ mappedVariables, filterAccumulator }) {
    mappedVariables.filters = { and: [...filterAccumulator] };
  }

  protected mapSortingVariables({ mappedVariables, sorting }) {
    Object.assign(mappedVariables, {
      sort: sorting.map(sort => `${sort.property}:${sort.direction}`),
    });
    mappedVariables.sort.push('id:desc');
  }

  protected mapPaginationVariables({ mappedVariables, size, page }) {
    Object.assign(mappedVariables, {
      pagination: { pageSize: size, page },
    });
  }

  protected mapPaginationVariablesByOffset({ mappedVariables, start, limit }) {
    Object.assign(mappedVariables, {
      pagination: { start, limit },
    });
  }
}
