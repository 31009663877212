import { TYPOLOGY_NESTED_RELATIONS_ENTITY } from './fashion-typologies.gql';

export const FILTER = `
  ${TYPOLOGY_NESTED_RELATIONS_ENTITY}
  query Filters {
    typologies: t1S(pagination: { limit: 300 }, sort: ["name:ASC"]) {
      data {
        ...NestedTypologies
      }
    }

    conservation_statuses: conservationStatuses(
      pagination: { limit: 300 }
      sort: ["isPriorityItem:DESC", "name:ASC"]
    ) {
      data {
        id
        attributes {
          name
        }
      }
    }

    apartments(pagination: { limit: 300 }, sort: ["isPriorityItem:DESC", "name:ASC"]) {
      data {
        id
        attributes {
          name
        }
      }
    }

    places(pagination: { limit: 300 }, sort: ["isPriorityItem:DESC", "name:ASC"]) {
      data {
        id
        attributes {
          name
        }
      }
    }

    shelves(pagination: { limit: 300 }, sort: ["isPriorityItem:DESC", "name:ASC"]) {
      data {
        id
        attributes {
          name
        }
      }
    }

    sectors(pagination: { limit: 300 }, sort: ["isPriorityItem:DESC", "name:ASC"]) {
      data {
        id
        attributes {
          name
        }
      }
    }

    boxes(pagination: { limit: 300 }, sort: ["isPriorityItem:DESC", "name:ASC"]) {
      data {
        id
        attributes {
          name
        }
      }
    }

    details(pagination: { limit: 300 }, sort: ["isPriorityItem:DESC", "name:ASC"]) {
      data {
        id
        attributes {
          name
        }
      }
    }

    colors(pagination: { limit: 300 }, sort: ["isPriorityItem:DESC", "name:ASC"]) {
      data {
        id
        attributes {
          name
        }
      }
    }

    materials(pagination: { limit: 200 }, sort: ["isPriorityItem:DESC", "name:ASC"]) {
      data {
        id
        attributes {
          name
        }
      }
    }

    techniques(pagination: { limit: 300 }, sort: ["isPriorityItem:DESC", "name:ASC"]) {
      data {
        id
        attributes {
          name
        }
      }
    }

    seasons(pagination: { limit: 300 }, sort: ["isPriorityItem:DESC", "name:ASC"]) {
      data {
        id
        attributes {
          name
        }
      }
    }

    brands(pagination: { limit: 800 }, sort: ["isPriorityItem:DESC", "name:ASC"]) {
      data {
        id
        attributes {
          name
        }
      }
    }

    fantasies(pagination: { limit: 300 }, sort: ["isPriorityItem:DESC", "name:ASC"]) {
      data {
        id
        attributes {
          name
        }
      }
    }
  }
  `;
