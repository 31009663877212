import { Injectable, Injector } from '@angular/core';

import { BitfStoreService } from '@bitf/services/store/bitf-store.service';

import { Archive, Folder, Loan, Sale, Store } from '@models';
import { StoreStorageService, FiltersService, ArchivesService } from '@services';
import { forkJoin } from 'rxjs';
import { tap } from 'rxjs/operators';
import { eStoreActions } from '@web/enums';
import { IBitfGraphQlResponse } from '@common/interfaces';

@Injectable({
  providedIn: 'root',
})

// NOTE: if you use storeStorage, the initalData must defined in the storeStorage, otherwise here
// NOTE: inital data must be an object literal to be passed in the storeClass constructor not a storeClass
// instance this is to avoid to create something like new storeClass(justClone(StoreClassInstance));
// which will lead to problems
export class StoreService extends BitfStoreService<Store> {
  constructor(storeStorageService: StoreStorageService, private injector: Injector) {
    // NOTE: Verstion with storage
    super({ storeClass: Store });
    // super({ storeClass: Store, storage: storeStorageService });
    // NOTE: version without storage and inital value here
    // super({ storeClass: Store, initialData: { prop1: 'Initial store value' } });
  }

  init() {
    const filtersService = this.injector.get(FiltersService);

    const getAllFiltersValues$ = filtersService.getAllFiltersValues().pipe(
      tap(response => {
        this.store.filters = response.content;
      })
    );

    return forkJoin([getAllFiltersValues$]);
  }

  setSelectedItem(item: Archive) {
    this.setStore(store => {
      store.selectedItem = item;
    }, eStoreActions.SET_SELECTED_ITEM);
  }

  updateSelectedItem(newItem: Partial<Archive>) {
    this.setStore(store => {
      // store.selectedItem.images = response.content.images;
      Object.assign(store.selectedItem, newItem);
    }, eStoreActions.UPDATE_SELECTED_ITEM);

    this.updateGalleryItem({ id: this.store.selectedItem.id });
  }

  setSelectedFolder(folder: Folder) {
    this.setStore(store => {
      store.selectedFolder = folder;
    }, eStoreActions.SET_SELECTED_FOLDER);
  }

  setSelectedLoan(loan: Loan) {
    this.setStore(store => {
      store.selectedLoan = loan;
    }, eStoreActions.SET_SELECTED_LOAN);
  }

  setSelectedSale(sale: Sale) {
    this.setStore(store => {
      store.selectedSale = sale;
    }, eStoreActions.SET_SELECTED_SALE);
  }

  updateGalleryItem(options: { id: number; disableHideLoader?: boolean; newItem?: Archive }) {
    if (!this.store.galleryItems) {
      return;
    }
    // NOTE to keep in sync the galleryItems we've to update that object too
    const galleryItem = this.store.galleryItems.find(item => item.id === options.id);
    if (galleryItem) {
      if (options.newItem) {
        this.setStore(() => {
          Object.assign(galleryItem, options.newItem);
        }, eStoreActions.UPDATE_GALLERY_ITEMS);
      } else {
        // NOTE: This is to avoid circular dependency error
        const archivesService = this.injector.get(ArchivesService);
        // NOTE: We need to do a full refetch because this object could be a
        // link lookbook or suit attached to the selectedItem and we haven't
        // all his props like item.links[0].links_from, links_from is not fetched
        // so we can manipulate that props to do FE update
        archivesService
          .getItemById({ id: options.id, disableHideLoader: !!options.disableHideLoader })
          .pipe(
            tap((response: IBitfGraphQlResponse<Archive>) => {
              this.setStore(() => {
                Object.assign(galleryItem, response.content);
              }, eStoreActions.UPDATE_GALLERY_ITEMS);
            })
          )
          .subscribe();
      }
    }
  }
}
