<mat-toolbar>
  <div class="logo border-right mr-3 px-3 h-100 d-flex align-items-center">
    {{appName}}
  </div>

  <div class="d-flex align-items-center truncate-text">
    <button *ngIf="backUrl"
      mat-icon-button
      color="primary"
      class="mr-2"
      [routerLink]="backUrl">
      <mat-icon>chevron_left</mat-icon>
    </button>

    <div class="d-flex flex-column pr-1"
      [ngClass]="backUrl ? 'w-100-with-btn' : 'w-100'">
      <h1 *ngIf="title"
        class="m-0 p-0 truncate-text">{{title}}</h1>

      <span *ngIf="subTitle"
        class="mat-caption">{{subTitle}}</span>
    </div>
  </div>

  <div class="flex-1">
    <ng-content>
    </ng-content>
  </div>

</mat-toolbar>