import { Injectable, Injector } from '@angular/core';

import { BitfAppSessionService } from '@bitf/services/app-session/bitf-app-session.service';
import { BitfLogDnaSenderService } from '@bitf/services/logger/bitf-log-dna-sender.service';
import { BitfPwaService } from '@bitf/services/pwa/bitf-pwa.service';
import { initDynamicLayoutUrl } from '@common/libs/bitforce/utils/bitf-responsive.utils';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root',
})
export class AppSessionService extends BitfAppSessionService {
  constructor(
    private bitfLogDnaSenderService: BitfLogDnaSenderService,
    protected bitfPwaService: BitfPwaService,
    protected injector: Injector
  ) {
    super(injector);
  }

  async init() {
    await super.init();
    // NOTE: At this point translations are already loaded

    // NOTE: needed for mobile/desktop routing support
    initDynamicLayoutUrl(this.router, this.storeService);

    this.initBreakpointListener();
    // this.responsiveService.init();

    // this.bitfTagManagerService.init();

    // this.bitfMetadataService.setDefaultDescription();

    // PWA
    this.bitfPwaService.initOnlineChecker();
    // this.bitfPwaService.showDefaultOnlineStateChangeToast();
    if (environment.registerServiceWorker) {
      this.bitfPwaService.init();

      this.bitfPwaService.initManifest();

      this.bitfPwaService.initSwUpdate();
      this.bitfPwaService.handleSwUpdate();

      this.bitfPwaService.initBeforeInstallPrompt();
      this.bitfPwaService.checkForBeforeInstallPromptEvent();
    }
  }

  initLogSender() {
    this.bitfLogDnaSenderService.init();
  }

  setLayoutBreakpoints() {
    const store = this.storeService.store;
    const wasInWebLayout = store.activeBreakpoints.isWebLayout;

    if (store.activeBreakpoints.isHandset) {
      store.activeBreakpoints.isHandsetLayout = true;
      store.activeBreakpoints.isTabletLayout = false;
      store.activeBreakpoints.isWebLayout = false;
    } else if (store.activeBreakpoints.isTablet || store.activeBreakpoints.isWeb) {
      store.activeBreakpoints.isHandsetLayout = false;
      store.activeBreakpoints.isTabletLayout = false;
      store.activeBreakpoints.isWebLayout = true;
    }

    // Switch from web to mobile layout
    if (wasInWebLayout && store.activeBreakpoints.isHandsetLayout) {
      setTimeout(() => {
        this.router.navigate(['/']);
      });
    }
  }
}
