import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Archive, ArchiveSale, Sale } from '@common/core/models';
import {
  ArchiveSalesService,
  ArchivesService,
  LoaderService,
  SalesService,
  StoreService,
} from '@common/core/services';
import { EBitfCloseEventStatus, EBitfUiMessageType } from '@common/enums';
import { IBitfCloseEvent, IBitfGraphQlResponse, ICreateUpdateSaleDialogData } from '@common/interfaces';
import { marker as bitfToTranslate } from '@biesbjerg/ngx-translate-extract-marker';
import { TranslateService } from '@ngx-translate/core';
import { ToastMessagesService } from '@web/core/services';
import { finalize, switchMap, tap } from 'rxjs/operators';
import { CreateUpdateSaleComponent } from '@web/shared/create-update-sale/create-update-sale.component';
@Component({
  selector: 'mpa-create-sale-dialog',
  templateUrl: './create-sale-dialog.component.html',
  styleUrls: ['./create-sale-dialog.component.scss'],
})
export class CreateSaleDialogComponent implements OnInit {
  @ViewChild(CreateUpdateSaleComponent) createUpdateSaleComponent: CreateUpdateSaleComponent;
  sale: Sale;

  constructor(
    public dialogRef: MatDialogRef<CreateSaleDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: ICreateUpdateSaleDialogData,
    private salesService: SalesService,
    private archiveSalesService: ArchiveSalesService,
    private archivesService: ArchivesService,
    private loaderService: LoaderService,
    private storeService: StoreService,
    private toastMessagesService: ToastMessagesService,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.sale = this.dialogData.sale;
  }

  onSave() {
    const body = new Sale({}, { ...this.createUpdateSaleComponent.form.value });

    this.loaderService.show();

    this.salesService
      .create({ body, disableHideLoader: true })
      .pipe(
        switchMap((response: IBitfGraphQlResponse<Sale>) => {
          this.sale = response.content;

          const validItems = [...this.dialogData.items].filter(item => !!item.quantity);
          if (!validItems.length) {
            this.dialogRef.close({
              status: EBitfCloseEventStatus.OK,
            } as IBitfCloseEvent<void>);
            return;
          }

          const observableList = validItems.map(item => {
            const archive = new Archive(undefined, {
              ...item,
              value: item.value || 0,
              quantity: item.selectedQuantity,
              is_sold: true,
              sale: response.content,
            });

            return this.archivesService.duplicateArchive(archive, true).pipe(
              switchMap((updatedArchiveResponse: IBitfGraphQlResponse<Archive>) => {
                const archiveSale = new ArchiveSale(undefined, {
                  archive: updatedArchiveResponse.content,
                  sale: response.content,
                });
                return this.archiveSalesService.createItem({
                  body: archiveSale,
                  disableHideLoader: true,
                });
              }),
              switchMap(_ => {
                let quantity = !item.quantity || item.quantity === null ? null : item.quantity;
                if (quantity != null) {
                  quantity = quantity < item.selectedQuantity ? 0 : quantity - item.selectedQuantity;
                }

                return this.archivesService.updateItem({
                  id: item.id,
                  body: new Archive(undefined, {
                    id: item.id,
                    quantity,
                  }),
                  disableHideLoader: true,
                });
              }),
              // NOTE: we've to update the gallery items with the new archive_sales
              // to eventually update the quantity
              tap((r: IBitfGraphQlResponse<Archive>) => {
                this.storeService.updateGalleryItem({ id: r.content.id, disableHideLoader: true });
              })
            );
          });

          return this.archiveSalesService.getThrottledObservables(observableList);
        }),
        finalize(() => {
          this.loaderService.hide();
        })
      )
      .subscribe(() => {
        this.toastMessagesService.show({
          type: EBitfUiMessageType.SUCCESS,
          title: this.translateService.instant(bitfToTranslate('SALE.TOAST.SALE_CREATED'), {
            sale: body.name,
          }),
        });

        this.dialogRef.close({
          status: EBitfCloseEventStatus.OK,
          data: this.sale,
        } as IBitfCloseEvent<Sale>);
      });
  }

  onClose() {
    this.dialogRef.close({
      status: EBitfCloseEventStatus.CANCEL,
    } as IBitfCloseEvent<void>);
  }
}
