import { Enum } from './enum.model';
import { Typology } from './typology.model';
import { Color } from './color.model';
import { Folder } from './folder.model';
import { Loan } from './loan.model';
// import { SuitTypology } from './suit-typology.model';
import { Material } from './material.model';
import { Technique } from './technique.model';
import { Season } from './season.model';
import { Brand } from './brand.model';
import { ConservationStatus } from './conservation-status.model';
import { Fantasy } from './fantasy.model';
import { Apartment } from './apartment.model';
import { Place } from './place.model';
import { Shelf } from './shelf.model';
import { Sector } from './sector.model';
import { Box } from './box.model';
import { Detail } from './detail.model';
import { Form } from '@angular/forms';
import { Format } from './format.model';
import { Director } from './director.model';
import { Language } from './language.model';
import { Distributor } from './distributor.model';
import { Producer } from './producer.model';
import { Race } from './race.model';
import { Sex } from './sex.model';

export class Filter {
  typologies: Typology[] = [];
  // suitTypologies: SuitTypology[];
  folders: Folder[] = [];
  loans: Loan[] = [];

  colors: Color[] = [];
  materials: Material[] = [];
  techniques: Technique[] = [];

  seasons: Season[] = [];
  brands: Brand[] = [];
  conservation_statuses: ConservationStatus[] = [];
  fantasies: Fantasy[] = [];

  apartments: Apartment[] = [];
  places: Place[] = [];
  shelves: Shelf[] = [];
  sectors: Sector[] = [];
  boxes: Box[] = [];
  details: Detail[] = [];
  formats: Format[] = [];
  directors: Director[] = [];
  languages: Language[] = [];
  distributors: Distributor[] = [];
  producers: Producer[] = [];
  races: Race[] = [];
  sexs: Sex[] = [];

  constructor(data) {
    if (data.typologies) {
      this.typologies = data.typologies.data.map(type => new Typology(type));
    }

    // if (data.suitTypologies) {
    //   this.suitTypologies = data.suitTypologies.data.map(suitType => new SuitTypology(suitType));
    // }

    if (data.colors) {
      this.colors = data.colors.data.map(color => new Color(color));
    }

    if (data.materials) {
      this.materials = data.materials.data.map(material => new Material(material));
    }

    if (data.techniques) {
      this.techniques = data.techniques.data.map(technique => new Technique(technique));
    }

    if (data.seasons) {
      this.seasons = data.seasons.data.map(season => new Season(season));
    }

    if (data.brands) {
      this.brands = data.brands.data.map(brand => new Brand(brand));
    }

    if (data.conservation_statuses) {
      this.conservation_statuses = data.conservation_statuses.data.map(
        conservation_status => new ConservationStatus(conservation_status)
      );
    }

    if (data.fantasies) {
      this.fantasies = data.fantasies.data.map(fantasy => new Fantasy(fantasy));
    }

    if (data.apartments) {
      this.apartments = data.apartments.data.map(apartment => new Apartment(apartment));
    }
    if (data.places) {
      this.places = data.places.data.map(place => new Place(place));
    }

    if (data.shelves) {
      this.shelves = data.shelves.data.map(shelf => new Shelf(shelf));
    }

    if (data.sectors) {
      this.sectors = data.sectors.data.map(sector => new Sector(sector));
    }

    if (data.boxes) {
      this.boxes = data.boxes.data.map(box => new Box(box));
    }

    if (data.folders) {
      this.folders = data.folders.data.map(folder => new Folder(folder));
    }

    if (data.loans) {
      this.loans = data.loans.data.map(loan => new Loan(loan));
    }

    if (data.details) {
      this.details = data.details.data.map(detail => new Detail(detail));
    }

    if (data.formats) {
      this.formats = data.formats.data.map(format => new Format(format));
    }

    if (data.directors) {
      this.directors = data.directors.data.map(director => new Director(director));
    }

    if (data.languages) {
      this.languages = data.languages.data.map(language => new Language(language));
    }

    if (data.distributors) {
      this.distributors = data.distributors.data.map(distributor => new Distributor(distributor));
    }

    if (data.producers) {
      this.producers = data.producers.data.map(producer => new Producer(producer));
    }

    if (data.races) {
      this.races = data.races.data.map(race => new Race(race));
    }

    if (data.sexs) {
      this.sexs = data.sexs.data.map(sex => new Sex(sex));
    }
  }
}
