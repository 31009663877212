import { ERoles } from '@common/enums';
import { SuperStrapiModel } from './super-strapi.model';

export class Role extends SuperStrapiModel {
  name: ERoles;
  description: string;
  type: string;

  constructor(data: Partial<Role> = {}) {
    super(data);
  }

  get serialised() {
    return {};
  }
}
