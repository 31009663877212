import { Component, Inject, OnInit, ViewContainerRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UiRoleManagerService } from '@common/core/services';
import { ERoleActions } from '@common/enums';
import { IPrintLoansDialogData, IPrintLoansData } from '@common/interfaces';
import { EArchiveType, EPrintType } from '@web/enums';
import { environment } from '@env/environment';
import { SuperPrint } from '@web/dynamic-modules/shared/print-strategies/super-print';

@Component({
  selector: 'mpa-dynamic-print-loans-dialog',
  templateUrl: './dynamic-print-loans-dialog.component.html',
  styleUrls: ['./dynamic-print-loans-dialog.component.scss'],
})
export class DynamicPrintLoansDialogComponent implements OnInit {
  printForm: FormGroup;
  eRoleActions = ERoleActions;
  ePrintType = EPrintType;
  constructor(
    public uiRoleManagerService: UiRoleManagerService,
    public dialogRef: MatDialogRef<DynamicPrintLoansDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: IPrintLoansDialogData,
    private formBuilder: FormBuilder
  ) {}
  ngOnInit(): void {
    this.initForm();
  }
  async onPrint() {
    let component: SuperPrint = null;
    const { items, title } = this.dialogData;
    const { layout } = this.printForm.value;
    const printData: IPrintLoansData = {
      layout,
      items,
      title,
    };
    switch (layout) {
      case EPrintType.LIST:
        switch (environment.archiveType) {
          case EArchiveType.FASHION:
            component = new (
              await import('@web/dynamic-modules/fashion/print-strategies/fashion-print-loans-list')
            ).FashionPrintLoansList();
            break;
          case EArchiveType.MULTIMEDIA:
            component = new (
              await import('@web/dynamic-modules/multimedia/print-strategies/multimedia-print-loans-list')
            ).MultimediaPrintLoansList();
            break;
          case EArchiveType.CELLAR:
            component = new (
              await import('@web/dynamic-modules/cellar/print-strategies/cellar-print-loans-list')
            ).CellarPrintLoansList();
            break;
        }
        break;
    }
    component.print(printData);

    this.dialogRef.close();
  }
  private initForm() {
    this.printForm = this.formBuilder.group({
      layout: [this.dialogData.actions[0], Validators.required],
    });
  }
}
