import { Component, HostListener, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { IItemDetailDialogData, IBitfCloseEvent, IBitfGraphQlResponse } from '@interfaces';
import {
  EApiCallStateNames,
  EApiRequestPartKeys,
  EBitfCloseEventStatus,
  EPrintType,
  ERoleActions,
  eStoreActions,
} from '@enums';
import { Archive } from '@common/core/models';
import {
  ApiCallStateService,
  ArchivesService,
  StoreService,
  UiRoleManagerService,
} from '@common/core/services';
import { Subscription } from 'rxjs';
import { BitfApiRequestPart } from '@common/libs/bitforce/core/api-call-state/bitf-api-request-part';
import { ItemDetailsComponent } from '../item-details/item-details.component';

@Component({
  selector: 'mpa-view-update-item-dialog',
  templateUrl: './view-update-item-dialog.component.html',
  styleUrls: ['./view-update-item-dialog.component.scss'],
})
export class ViewUpdateItemDialogComponent implements OnInit, OnDestroy {
  @ViewChild(ItemDetailsComponent) itemDetailsComponent: ItemDetailsComponent;

  eRoleActions = ERoleActions;
  ePrintType = EPrintType;
  selectedItemIndex: number;
  totalItems: number;
  apiCallStateName: EApiCallStateNames;
  buttonsDisabled = false;
  isReadOnly = false;

  private paginationRequestPart: BitfApiRequestPart;
  private subscription: Subscription = new Subscription();

  get items(): Archive[] {
    return this.storeService.store.galleryItems;
  }
  get item(): Archive {
    return this.storeService.store.selectedItem;
  }

  constructor(
    public dialogRef: MatDialogRef<ViewUpdateItemDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: IItemDetailDialogData,
    private archivesService: ArchivesService,
    private apiCallStateService: ApiCallStateService,
    public storeService: StoreService,
    public uiRoleManagerService: UiRoleManagerService
  ) {}

  ngOnInit() {
    this.selectedItemIndex = this.dialogData.selectedItemIndex;
    this.apiCallStateName = this.dialogData.apiCallStateName;
    this.totalItems = this.dialogData.totalItems;
    this.isReadOnly = this.dialogData.isReadOnly;
    this.initApiCallState();
    this.loadData();
  }

  @HostListener('window:keydown', ['$event'])
  keyDown(event: KeyboardEvent) {
    if (!event.shiftKey) {
      return;
    }
    if (event.key === 'ArrowRight') {
      this.onLoadNextItem();
    }
    if (event.key === 'ArrowLeft') {
      this.onLoadPreviousItem();
    }
  }

  onSave() {
    this.itemDetailsComponent.save();
  }

  onPrint(printType: EPrintType) {
    this.itemDetailsComponent.print(printType);
  }

  onClose() {
    this.dialogRef.close({
      status: EBitfCloseEventStatus.CANCEL,
    } as IBitfCloseEvent<any>);
  }

  onLoadPreviousItem = () => {
    if (this.selectedItemIndex === 0) {
      return;
    }
    if (this.items[this.selectedItemIndex - 1]?.id === undefined) {
      return;
    }
    this.selectedItemIndex--;
    this.loadData();
  };

  onLoadNextItem = () => {
    if (this.selectedItemIndex === this.totalItems - 1) {
      return;
    }
    if (this.items[this.selectedItemIndex + 1]?.id === undefined) {
      return;
    }
    if (this.selectedItemIndex === this.items.length - 1) {
      this.selectedItemIndex++;

      this.buttonsDisabled = true;
      this.apiCallStateService.setStore(() => {
        this.paginationRequestPart.data = {
          ...this.paginationRequestPart.data,
          page: this.paginationRequestPart.data.page + 1,
        };
      }, this.apiCallStateName);
    } else {
      this.selectedItemIndex++;
      this.loadData();
    }
  };

  private loadData() {
    this.buttonsDisabled = true;
    this.archivesService
      .getItemById({ variables: { id: this.items[this.selectedItemIndex]?.id } })
      .subscribe((response: IBitfGraphQlResponse<Archive>) => {
        this.storeService.setSelectedItem(response.content);
        this.buttonsDisabled = false;
      });
  }

  private initApiCallState() {
    this.paginationRequestPart = this.apiCallStateService.getRequestPart(
      this.apiCallStateName,
      EApiRequestPartKeys.PAGINATION
    );

    // NOTE: this is needed to react after a next page load event
    this.subscription.add(
      this.storeService.selectStore(eStoreActions.CONCAT_GALLERY_ITEMS).subscribe(() => {
        this.loadData();
      })
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.storeService.setSelectedItem(undefined);
  }
}
