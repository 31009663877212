import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { Archive, Folder, Loan, Sale } from '@common/core/models';
import { EBitfCloseEventStatus, ERoleActions } from '@common/enums';
import {
  IBitfApiPagination,
  IBitfCloseEvent,
  IBitfGraphQlRequest,
  IBitfGraphQlResponse,
  IPrintDialogData,
} from '@common/interfaces';
import {
  ApiCallStateService,
  ArchivesService,
  DialogsService,
  LoaderService,
  StoreService,
} from '@web/core/services';
import { CreateUpdateFolderDialogComponent } from '../create-update-folder-dialog/create-update-folder-dialog.component';
import { CreateLoanDialogComponent } from '../create-loan-dialog/create-loan-dialog.component';
import { BulkCopyIntoFolderDialogComponent } from '../bulk-copy-into-folder-dialog/bulk-copy-into-folder-dialog.component';
import { BulkMoveIntoFolderDialogComponent } from '../bulk-move-into-folder-dialog/bulk-move-into-folder-dialog.component';
import { BulkUpdateItemsDialogComponent } from '../bulk-update-items-dialog/bulk-update-items-dialog.component';
import { EApiCallStateNames, EApiRequestPartKeys, EAppSections, EPrintType, eStoreActions } from '@web/enums';
import { ViewUpdateItemDialogComponent } from '../view-update-item-dialog/view-update-item-dialog.component';
import { filter, map, tap } from 'rxjs/operators';
import { BitfApiRequestPart } from '@common/libs/bitforce/core/api-call-state/bitf-api-request-part';
import { BulkRemoveFromFolderDialogComponent } from '../bulk-remove-from-folder-dialog/bulk-remove-from-folder-dialog.component';
import { DynamicPrintDialogComponent } from '../dynamic-print-dialog/dynamic-print-dialog.component';
import { SelectPrintPageDialogComponent } from '../select-print-page-dialog/select-print-page-dialog.component';
import { CONSTANTS } from '@web/constants';
import { BulkAddToLoanDialogComponent } from '../bulk-add-to-loan-dialog/bulk-add-to-loan-dialog.component';
import { CreateSaleDialogComponent } from '../create-sale-dialog/create-sale-dialog.component';
import { BulkAddToSaleDialogComponent } from '../bulk-add-to-sale-dialog/bulk-add-to-sale-dialog.component';
import { QuantitySaleDialogComponent } from '../quantity-sale-dialog/quantity-sale-dialog.component';
import { environment } from '@env/environment';

@Component({
  selector: 'mpa-bulk-actions-toolbar',
  templateUrl: './bulk-actions-toolbar.component.html',
  styleUrls: ['./bulk-actions-toolbar.component.scss'],
})
export class BulkActionsToolbarComponent implements OnInit {
  @Input() items: Archive[] = [];
  @Input() selectedItems = new Map<number, Archive>();
  @Input() unselectedItems = new Map<number, Archive>();
  @Input() archivesValue: number;
  @Input() archivesQuantity: number;
  @Input() apiCallStateName: EApiCallStateNames;
  @Input() pagination: IBitfApiPagination;
  @Input() folder: Folder;
  @Input() isReadOnly: boolean = false;

  @Output() folderChange: EventEmitter<Folder> = new EventEmitter();
  @Output() archivesRemoved: EventEmitter<Archive[]> = new EventEmitter();
  @Output() invertSelection = new EventEmitter();

  @Input() allItemsSelected = false;
  @Output() allItemsSelectedChange = new EventEmitter<boolean>();

  paginationRequestPart: BitfApiRequestPart;
  eRoleActions = ERoleActions;

  environment = environment;
  EAppSections = EAppSections;

  constructor(
    private dialogsService: DialogsService,
    private archiveService: ArchivesService,
    private apiCallStateService: ApiCallStateService,
    private loaderService: LoaderService,
    private storeService: StoreService
  ) {}

  ngOnInit(): void {
    this.paginationRequestPart = this.apiCallStateService.getRequestPart(
      this.apiCallStateName,
      EApiRequestPartKeys.PAGINATION
    );
  }

  onAllItemsSelected(event): void {
    // NOTE: this to unselect all items if the checkbox is clicked in the indeterminate state
    if (event && this.selectedItems.size) {
      setTimeout(() => {
        this.allItemsSelected = false;
        this.allItemsSelectedChange.emit(false);
      }, 0);
    } else {
      this.allItemsSelected = event;
      this.allItemsSelectedChange.emit(event);
    }

    this.selectedItems.clear();
    this.unselectedItems.clear();
  }

  onInvertSelection(): void {
    this.invertSelection.emit();
  }

  async onBulkEdit() {
    const archives = await this.getAllSelectedItems();
    if (archives.length > 1) {
      this.dialogsService.dialog.open(BulkUpdateItemsDialogComponent, {
        ...CONSTANTS.FULL_SCREEN_DIALOG_SIZE,
        data: {
          bulkItemsIds: archives.map(archive => archive.id),
          apiCallStateName: this.apiCallStateName,
          totalItems: this.pagination.totalItems,
        },
      });
    } else if (archives.length === 1) {
      const selectedItemIndex = this.allItemsSelected
        ? 0
        : this.items.findIndex(item => item.id === [...this.selectedItems.keys()][0]);
      if (selectedItemIndex !== -1) {
        this.dialogsService.dialog.open(ViewUpdateItemDialogComponent, {
          ...CONSTANTS.FULL_SCREEN_DIALOG_SIZE,
          data: {
            selectedItemIndex,
            apiCallStateName: this.apiCallStateName,
            totalItems: this.pagination.totalItems,
          },
        });
      }
    }
  }

  // LOAN -----------------------------------
  async onCreateLoan() {
    const dialog = this.dialogsService.dialog.open(CreateLoanDialogComponent, {
      ...CONSTANTS.SMALL_DIALOG_SIZE,
      data: {
        items: await this.getAllSelectedItems(),
      },
    });
    dialog.afterClosed().subscribe((result: IBitfCloseEvent<Loan>) => {
      if (result?.status === EBitfCloseEventStatus.OK) {
        // this.router.navigate(['prestiti', result.data.id]);
      }
    });
  }

  async onAddToLoan() {
    const dialog = this.dialogsService.dialog.open(BulkAddToLoanDialogComponent, {
      ...CONSTANTS.SMALL_DIALOG_SIZE,
      data: {
        items: await this.getAllSelectedItems(),
      },
    });
    dialog.afterClosed().subscribe((result: IBitfCloseEvent<Loan>) => {
      if (result?.status === EBitfCloseEventStatus.OK) {
        // this.router.navigate(['prestiti', result.data.id]);
      }
    });
  }

  // SALE -----------------------------------
  async onCreateSale() {
    const items = await this.getAllSelectedItemsWithData();

    const qtyDialog = this.dialogsService.dialog.open(QuantitySaleDialogComponent, {
      ...CONSTANTS.SMALL_DIALOG_SIZE,
      data: {
        items,
      },
    });

    qtyDialog.afterClosed().subscribe((result: IBitfCloseEvent<Sale>) => {
      if (result?.status === EBitfCloseEventStatus.OK) {
        const dialog = this.dialogsService.dialog.open(CreateSaleDialogComponent, {
          ...CONSTANTS.SMALL_DIALOG_SIZE,
          data: {
            items,
          },
        });
      }
    });
  }

  async onAddToSale() {
    const items = await this.getAllSelectedItemsWithData();

    const qtyDialog = this.dialogsService.dialog.open(QuantitySaleDialogComponent, {
      ...CONSTANTS.SMALL_DIALOG_SIZE,
      data: {
        items,
      },
    });

    qtyDialog.afterClosed().subscribe((result: IBitfCloseEvent<Sale>) => {
      if (result?.status === EBitfCloseEventStatus.OK) {
        const dialog = this.dialogsService.dialog.open(BulkAddToSaleDialogComponent, {
          ...CONSTANTS.SMALL_DIALOG_SIZE,
          data: {
            items,
          },
        });
      }
    });
  }

  // FOLDER -----------------------------------
  async onCreateFolder() {
    const dialog = this.dialogsService.dialog.open(CreateUpdateFolderDialogComponent, {
      ...CONSTANTS.SMALL_DIALOG_SIZE,
      data: {
        items: await this.getAllSelectedItems(),
      },
    });
    dialog.afterClosed().subscribe((result: IBitfCloseEvent<Folder>) => {
      if (result?.status === EBitfCloseEventStatus.OK) {
        // this.router.navigate(['cartelle', result.data.id]);
      }
    });
  }

  async onCopyIntoFolder() {
    const dialog = this.dialogsService.dialog.open(BulkCopyIntoFolderDialogComponent, {
      ...CONSTANTS.SMALL_DIALOG_SIZE,
      data: {
        folder: this.folder,
        items: await this.getAllSelectedItems(),
      },
    });
    dialog.afterClosed().subscribe((result: IBitfCloseEvent<void>) => {
      if (result?.status === EBitfCloseEventStatus.OK) {
        /* TODO apertura dettaglio della cartella o no?*/
      }
    });
  }

  async onMoveToFolder() {
    const items = await this.getAllSelectedItems();
    this.dialogsService.dialog
      .open(BulkMoveIntoFolderDialogComponent, {
        ...CONSTANTS.SMALL_DIALOG_SIZE,
        data: {
          folder: this.folder,
          items,
        },
      })
      .afterClosed()
      .pipe(
        filter((result: IBitfCloseEvent<Folder>) => result?.status === EBitfCloseEventStatus.OK),
        tap(() => this.storeService.setStore(() => {}, eStoreActions.EVENT_UPDATE_ARCHIVES_TOTAL_VALUE)),
        tap((event: IBitfCloseEvent<Folder>) => {
          this.folderChange.emit(event.data);
          this.archivesRemoved.emit(items);
        })
      )
      .subscribe();
  }

  async onRemoveFromFolder() {
    const items = await this.getAllSelectedItems();
    this.dialogsService.dialog
      .open(BulkRemoveFromFolderDialogComponent, {
        ...CONSTANTS.SMALL_DIALOG_SIZE,
        data: {
          folder: this.folder,
          items,
        },
      })
      .afterClosed()
      .pipe(
        filter((event: IBitfCloseEvent<Folder>) => event?.status === EBitfCloseEventStatus.OK),
        tap(() => this.storeService.setStore(() => {}, eStoreActions.EVENT_UPDATE_ARCHIVES_TOTAL_VALUE)),
        tap((event: IBitfCloseEvent<Folder>) => {
          this.folderChange.emit(event.data);
          this.archivesRemoved.emit(items);
        })
      )
      .subscribe();
  }

  onPrint() {
    const requestParams: IBitfGraphQlRequest = this.apiCallStateService.getApiRequest(this.apiCallStateName);

    const request: IBitfGraphQlRequest = {
      ...requestParams,
      size: CONSTANTS.MAX_PRINTABLE_ITEMS,
    };
    if (!this.allItemsSelected) {
      request.filter = [...request.filter, { and: { id: { in: [...this.selectedItems.keys()] } } }];
    } else {
      request.filter = [...request.filter, { and: { id: { notIn: [...this.unselectedItems.keys()] } } }];
    }
    let totalItems: number;
    if (this.allItemsSelected && !this.unselectedItems.size) {
      totalItems = this.pagination.totalItems;
    } else if (this.selectedItems.size) {
      totalItems = this.selectedItems.size;
    } else {
      totalItems = this.pagination.totalItems - this.unselectedItems.size;
    }

    if (totalItems > CONSTANTS.MAX_PRINTABLE_ITEMS) {
      this.dialogsService.dialog
        .open(SelectPrintPageDialogComponent, {
          width: '500px',
          data: totalItems,
        })
        .afterClosed()
        .pipe(filter((result: IBitfCloseEvent<number>) => result?.status === EBitfCloseEventStatus.OK))
        .subscribe(result => {
          request.page = result.data;
          this.doPrint(request);
        });
    } else {
      request.page = 1;
      this.doPrint(request);
    }
  }

  private doPrint(request: IBitfGraphQlRequest) {
    this.loaderService.show();

    this.archiveService
      .getPrintData({
        ...request,
        disableHideLoader: true,
      })
      .pipe(
        tap((response: IBitfGraphQlResponse<Archive[]>) => {
          this.loaderService.hide();
          this.dialogsService.dialog.open(DynamicPrintDialogComponent, {
            width: '800px',
            data: {
              items: response.content,
              title: this.folder?.name || '',
              actions: [
                EPrintType.DETAILS,
                EPrintType.GRID_1,
                EPrintType.GRID_2_LANDSCAPE,
                EPrintType.GRID_2,
                EPrintType.GRID_3,
                EPrintType.LIST,
                EPrintType.LABEL,
              ],
            } as IPrintDialogData,
          });
        })
      )
      .subscribe();
  }

  private getAllSelectedItems(): Promise<Archive[]> {
    if (!this.allItemsSelected) {
      return new Promise(res => {
        res(Array.from(this.selectedItems.values()));
      });
    }

    const requestParams: IBitfGraphQlRequest = this.apiCallStateService.getApiRequest(this.apiCallStateName);
    const unselectedItemsIds = Array.from(this.unselectedItems.keys()).map(id => ({ id: { ne: id } }));
    this.loaderService.show();
    return this.archiveService
      .getAllIds({
        ...requestParams,
        page: 0,
        size: 100000,
        filter: [...requestParams.filter, { and: unselectedItemsIds }],
      })
      .pipe(map(response => response.content))
      .toPromise();
  }

  private getAllSelectedItemsWithData(): Promise<Archive[]> {
    const selectedItemsFilter = !this.allItemsSelected
      ? { and: { id: { in: [...this.selectedItems.keys()] } } }
      : { and: { id: { notIn: [...this.unselectedItems.keys()] } } };

    const requestParams: IBitfGraphQlRequest = this.apiCallStateService.getApiRequest(this.apiCallStateName);

    this.loaderService.show();
    return this.archiveService
      .getItems(
        {
          ...requestParams,
          page: 0,
          size: 100000,
          filter: [...requestParams.filter, selectedItemsFilter],
        },
        'fullItems'
      )
      .pipe(map(response => response.content))
      .toPromise();
  }
}
