<form [formGroup]="form">
  <div class="row">
    <div class="col">
      <mat-form-field appearance="outline"
        class="w-100">
        <mat-label>{{'SALE.NAME' | translate}}</mat-label>
        <input matInput
          formControlName="name"
          type="text">
      </mat-form-field>
    </div>
    <div class="col-4"
      *ngIf="sale">
      <mat-form-field appearance="outline"
        class="w-100">
        <mat-label>Id</mat-label>
        <input matInput
          readonly
          [value]="sale.id"
          type="text">
      </mat-form-field>
    </div>
  </div>

  <mat-form-field appearance="outline"
    class="w-100">
    <mat-label>{{'SALE.REFERENCE' | translate}}</mat-label>
    <input matInput
      formControlName="reference"
      type="text">
  </mat-form-field>

  <div class="d-flex">
    <mat-form-field appearance="outline"
      class="w-100">
      <mat-label>{{'SALE.DATE' | translate}}</mat-label>
      <input matInput
        [matDatepicker]="dateOutPicker"
        formControlName="date">
      <mat-datepicker-toggle matSuffix
        [for]="dateOutPicker"></mat-datepicker-toggle>
      <mat-datepicker #dateOutPicker></mat-datepicker>
    </mat-form-field>
  </div>

  <mat-form-field appearance="outline"
    class="w-100">
    <mat-label>{{'SALE.NOTE' | translate}}</mat-label>
    <textarea matInput
      formControlName="note"
      type="text"></textarea>
  </mat-form-field>
</form>