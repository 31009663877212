import { Component, Input } from '@angular/core';

import { Archive } from '@common/core/models';
import { ArchivesService, StoreService } from '@common/core/services';
import { IBitfGraphQlResponse } from '@common/interfaces';

@Component({
  selector: 'mpa-item-images-gallery',
  templateUrl: './item-images-gallery.component.html',
  styleUrls: ['./item-images-gallery.component.scss'],
})
export class ItemImagesGalleryComponent {
  @Input() showDetailAction: boolean;
  @Input() isZoom: boolean;
  @Input() isHorizontal: boolean;

  get selectedItemAndHisSuits(): Archive[] {
    if (this.storeService.store.selectedItem) {
      return [this.storeService.store.selectedItem, ...this.storeService.store?.selectedItem?.suitItems];
    }
    return [];
  }

  constructor(public storeService: StoreService, private archivesService: ArchivesService) {}

  onLoadItem(archive: Archive) {
    this.archivesService
      .getItemById({ variables: { id: archive.id } })
      .subscribe((response: IBitfGraphQlResponse<Archive>) => {
        this.storeService.setSelectedItem(response.content);
      });
  }
}
