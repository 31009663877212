import { Component, ElementRef, Input, OnDestroy, ViewChild } from '@angular/core';

import { Archive } from '@common/core/models';
import { StoreService, UiRoleManagerService } from '@common/core/services';
import { ERoleActions } from '@common/enums';
import { EPrintType, eStoreActions } from '@web/enums';
import { ItemDetailsTabsComponent } from '../item-details-tabs/item-details-tabs.component';
import { Subscription } from 'rxjs';

@Component({
  selector: 'mpa-item-details',
  templateUrl: './item-details.component.html',
  styleUrls: ['./item-details.component.scss'],
})
export class ItemDetailsComponent implements OnDestroy {
  @Input() isZoom: boolean;
  @Input() isReadOnly: boolean;

  @ViewChild(ItemDetailsTabsComponent) itemDetailsTabsComponent: ItemDetailsTabsComponent;
  @ViewChild('galleryContainer') galleryContainer: ElementRef<HTMLDivElement>;
  @ViewChild('detailContainer') detailContainer: ElementRef<HTMLDivElement>;

  eRoleActions = ERoleActions;
  subscriptions = new Subscription();

  get item(): Archive {
    return this.storeService.store.selectedItem;
  }

  get isItemFormsTab() {
    return this.itemDetailsTabsComponent.selectedTabIndex === 0;
  }

  constructor(public uiRoleManagerService: UiRoleManagerService, public storeService: StoreService) {
    this.subscriptions.add(
      this.storeService
        .selectStore([eStoreActions.SET_SELECTED_ITEM, eStoreActions.UPDATE_SELECTED_ITEM])
        .subscribe(() => {
          this.galleryContainer?.nativeElement.scrollTo(0, 0);
          this.detailContainer?.nativeElement.scrollTo(0, 0);
        })
    );
  }

  save() {
    this.itemDetailsTabsComponent.save();
  }

  print(printType: EPrintType) {
    this.itemDetailsTabsComponent.print(printType);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
