<div class="border-bottom d-flex justify-content-between py-1"
  [ngClass]="{'highlighted': isHighlighted}">
  <div class="d-flex align-items-center">
    <div class="item-image {{size}} rounded d-flex align-items-center justify-content-center mr-3">
      <img *ngIf="item.images?.length"
        [src]="item.images[0]?.getImageByFormat('xsmall')?.url"
        [alt]="item.description">
      <mat-icon *ngIf="!item.images?.length">no_photography</mat-icon>
    </div>
    <div class="d-flex justify-content-between">
      <div class="d-flex flex-column">
        <span>{{item.description}}</span>
        <span class="mat-caption"> {{item.inventory}}</span>
      </div>
    </div>
  </div>

  <div class="d-flex align-items-center justify-content-end">
    <button mat-icon-button
      *ngIf="uiRoleManagerService.canI(eRoleActions.EDIT_ITEM) && showDeleteButton"
      color="primary"
      class="mr-3"
      (click)="removeLink.emit(item)">
      <mat-icon>delete</mat-icon>
    </button>

    <button mat-icon-button
      *ngIf="!isHighlighted"
      color="primary"
      [matTooltip]="'COMMON.LABEL.DETAILS' | translate"
      (click)="onLoadItem(item)">
      <mat-icon>visibility</mat-icon>
    </button>
  </div>
</div>