import { Component, Input, OnInit, ViewContainerRef } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { environment } from '@env/environment';
import { CreateDynamicComponentService } from '@web/core/services/create-dynamic-component.service';
import { SuperItemDataFormComponent } from '@web/dynamic-modules/shared/components/super-item-data-form.component';
import { EArchiveType } from '@web/enums';

@Component({
  selector: 'mpa-dynamic-item-data-form',
  templateUrl: './dynamic-item-data-form.component.html',
  styleUrls: ['./dynamic-item-data-form.component.scss'],
})
export class DynamicItemDataFormComponent implements OnInit {
  @Input() isReadOnly: boolean;
  @Input() isBulkUpdate: boolean;

  private component: SuperItemDataFormComponent;
  constructor(
    private createDynamicComponentService: CreateDynamicComponentService,
    private viewContainerRef: ViewContainerRef
  ) {}

  get form() {
    return this.component?.form;
  }

  async ngOnInit() {
    let component = null;

    switch (environment.archiveType) {
      case EArchiveType.FASHION:
        component = (
          await import('@web/dynamic-modules/fashion/fashion-item-data-form/fashion-item-data-form.component')
        ).FashionItemDataFormComponent;
        break;
      case EArchiveType.MULTIMEDIA:
        component = (
          await import(
            '@web/dynamic-modules/multimedia/multimedia-item-data-form/multimedia-item-data-form.component'
          )
        ).MultimediaItemDataFormComponent;
        break;
      case EArchiveType.CELLAR:
        component = (
          await import('@web/dynamic-modules/cellar/cellar-item-data-form/cellar-item-data-form.component')
        ).CellarItemDataFormComponent;
        break;
    }

    this.component = await this.createDynamicComponentService.loadComponent(
      component,
      this.viewContainerRef,
      {
        isReadOnly: this.isReadOnly,
      }
    );

    setTimeout(() => {
      if (this.form && this.isBulkUpdate) {
        this.form.patchValue({
          is_visible_to_editor: undefined,
          is_visible_to_collaborator: undefined,
          is_visible_to_ufficio_stile: undefined,
          is_visible_to_viewer: undefined,
        });
      }
    }, 0);
  }
}
