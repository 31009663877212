import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';

import { gql } from 'apollo-angular';

import { BitfGraphQlService } from '@bitf/services/graph-ql/bitf-graph-ql.service';

import { IBitfGraphQlRequest, IBitfGraphQlResponse } from '@interfaces';
import { Loan } from '@models';
import { LOAN_ENTITY, LOAN_LIST_ENTITY, LOAN_WITH_ALL_RELATIONS_ENTITY } from './loans.gql';
import { tap } from 'rxjs/operators';
import { PAGINATION_ENTITY } from './strapi.gql';
import { getLoanPrintDataGql } from './archives.gql';

@Injectable({
  providedIn: 'root',
})
// NOTE: Add methods following CRUD order
export class LoansService extends BitfGraphQlService {
  constructor(public injector: Injector) {
    super(injector);
  }

  get(requestParams: IBitfGraphQlRequest): Observable<IBitfGraphQlResponse<Loan[]>> {
    requestParams.modelMapper = 'loans';
    requestParams.query = gql`
      ${LOAN_WITH_ALL_RELATIONS_ENTITY}
      ${PAGINATION_ENTITY}
      query Query($pagination: PaginationArg, $sort: [String], $filters: LoanFiltersInput) {
        loans(pagination: $pagination, sort: $sort, filters: $filters) {
          data {
            ...LoanWithAllRelationsEntity
          }
          meta {
            pagination {
              ...PaginationEntity
            }
          }
        }
      }
    `;

    return super.query<Loan[]>(requestParams);
  }

  getList(requestParams: IBitfGraphQlRequest): Observable<IBitfGraphQlResponse<Loan[]>> {
    requestParams.modelMapper = 'loans';
    requestParams.query = gql`
      ${LOAN_LIST_ENTITY}
      ${PAGINATION_ENTITY}
      query Query($pagination: PaginationArg, $sort: [String], $filters: LoanFiltersInput) {
        loans(pagination: $pagination, sort: $sort, filters: $filters) {
          data {
            ...LoanListEntity
          }
          meta {
            pagination {
              ...PaginationEntity
            }
          }
        }
      }
    `;

    return super.query<Loan[]>(requestParams);
  }

  // getLatestIdentifier(requestParams: IBitfGraphQlRequest): Observable<IBitfGraphQlResponse<Loan[]>> {
  //   requestParams.modelMapper = 'loans';
  //   requestParams.query = gql`
  //     query Query {
  //       loans(pagination: { limit: 1 }, sort: ["id:DESC"]) {
  //         data {
  //           attributes {
  //             identifier
  //           }
  //         }
  //       }
  //     }
  //   `;

  //   return super.query<Loan[]>(requestParams);
  // }

  create(requestParams: IBitfGraphQlRequest = {}) {
    requestParams.modelMapper = 'createLoan';
    requestParams.mutation = gql`
      ${LOAN_ENTITY}
      mutation Create($input: LoanInput!) {
        createLoan(data: $input) {
          data {
            ...LoanEntity
          }
        }
      }
    `;

    return super.mutate<Loan>(requestParams);
  }

  getItemById(
    requestParams: IBitfGraphQlRequest,
    queryType: 'loan' | 'loanDetails' = 'loan'
  ): Observable<IBitfGraphQlResponse<Loan>> {
    requestParams.modelMapper = 'loan';
    switch (queryType) {
      case 'loan':
        requestParams.query = gql`
          ${LOAN_ENTITY}
          query Query($id: ID) {
            loan(id: $id) {
              data {
                ...LoanEntity
              }
            }
          }
        `;
        break;
      case 'loanDetails':
        requestParams.query = gql`
          ${LOAN_WITH_ALL_RELATIONS_ENTITY}
          query Query($id: ID) {
            loan(id: $id) {
              data {
                ...LoanWithAllRelationsEntity
              }
            }
          }
        `;
        break;
    }

    return super.query<Loan>(requestParams);
  }

  updateItem(
    requestParams: IBitfGraphQlRequest<Partial<Loan>> = {},
    loan?: Partial<Loan>
  ): Observable<IBitfGraphQlResponse<Loan>> {
    requestParams.modelMapper = 'updateLoan';
    requestParams.mutation = gql`
      ${LOAN_ENTITY}
      mutation Update($id: ID!, $input: LoanInput!) {
        updateLoan(id: $id, data: $input) {
          data {
            ...LoanEntity
          }
        }
      }
    `;
    return super.mutate<Loan>(requestParams, loan).pipe(
      tap((response: IBitfGraphQlResponse<Loan>) => {
        if (loan) {
          Object.assign(loan, response.content);
        }
      })
    );
  }

  deleteItem(requestParams: IBitfGraphQlRequest = {}) {
    requestParams.modelMapper = 'deleteLoan';
    requestParams.mutation = gql`
      mutation Mutate($id: ID!) {
        deleteLoan(id: $id) {
          data {
            id
          }
        }
      }
    `;
    return super.mutate<Loan>(requestParams);
  }

  getPrintData(requestParams: IBitfGraphQlRequest = {}): Observable<IBitfGraphQlResponse<Loan>> {
    requestParams.modelMapper = 'loan';
    requestParams.query = gql`
      ${getLoanPrintDataGql()}
    `;

    return super.query<Loan>(requestParams);
  }
}
