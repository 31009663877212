import { Injectable, Injector } from '@angular/core';
import { BitfStrapiAuthService } from '@common/libs/bitforce/core/services/auth/strapi-auth/bitf-strapi-auth.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService extends BitfStrapiAuthService {
  constructor(protected injector: Injector) {
    super(injector);
  }
}
