<div class="dialog-stretch">
  <!-- HEADER -->
  <div class="d-flex justify-content-between pt-2"
    mat-dialog-title>
    <h2 class="mb-0 mat-headline-6  w-100 d-flex align-items-center">
      <span>{{ "ITEM.EDIT_BULK_TITLE" | translate: {count: dialogData.bulkItemsIds.length} }}</span>
    </h2>
    <button mat-icon-button
      (click)="onClose()">
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <mat-dialog-content class="py-2">
    <form [formGroup]="form"
      (submit)="onSave()">
      <mat-form-field appearance="outline"
        class="w-100">
        <mat-label>{{'COMMON.LABEL.DATE_OUT' | translate}}</mat-label>
        <input matInput
          [matDatepicker]="dateOutPicker"
          [placeholder]="'COMMON.LABEL.DATE_OUT' | translate"
          formControlName="date_out">
        <mat-datepicker-toggle matSuffix
          [for]="dateOutPicker"></mat-datepicker-toggle>
        <mat-datepicker #dateOutPicker></mat-datepicker>
      </mat-form-field>
      <mat-form-field appearance="outline"
        class="w-100">
        <mat-label>{{'COMMON.LABEL.NOTE_OUT' | translate}}</mat-label>
        <textarea matInput
          rows="3"
          [placeholder]="'COMMON.LABEL.NOTE_OUT' | translate"
          formControlName="note_out"
          type="text"></textarea>
      </mat-form-field>

      <mat-form-field appearance="outline"
        class="w-100">
        <mat-label>{{'COMMON.LABEL.DATE_IN' | translate}}</mat-label>
        <input matInput
          [matDatepicker]="dateInPicker"
          [placeholder]="'COMMON.LABEL.DATE_IN' | translate"
          formControlName="date_in">
        <mat-datepicker-toggle matSuffix
          [for]="dateInPicker"></mat-datepicker-toggle>
        <mat-datepicker #dateInPicker></mat-datepicker>
      </mat-form-field>
      <mat-form-field appearance="outline"
        class="w-100">
        <mat-label>{{'COMMON.LABEL.NOTE_IN' | translate}}</mat-label>
        <textarea matInput
          rows="3"
          [placeholder]="'COMMON.LABEL.NOTE_IN' | translate"
          formControlName="note_in"
          type="text"></textarea>
      </mat-form-field>
    </form>
  </mat-dialog-content>

  <mat-dialog-actions align="end"
    class="d-flex align-items-center">
    <!-- ACTIONS -->
    <button mat-flat-button
      (click)="onClose()">
      {{ 'BITF.DIALOGS.BUTTON_CANCEL_TEXT' | translate }}
    </button>
    <button mat-flat-button
      color="primary"
      [disabled]="!form.valid"
      (click)="onSave()">
      {{ 'BITF.DIALOGS.BUTTON_OK_TEXT_TO_SAVE' | translate }}
    </button>
  </mat-dialog-actions>
</div>