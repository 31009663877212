<div class="position-relative d-flex align-items-center image">
  <ng-container *ngIf="item">
    <span class="mat-caption image-counter"
      *ngIf="item.gallery?.length > 1 && showImagesCounter">
      {{item.gallery?.length || 0}} {{'COMMON.LABEL.IMAGES' | translate}}
    </span>

    <img *ngIf="item.gallery?.length"
      [src]="item.gallery[item.galleryImageIndex]?.image?.getImageByFormat(imageSize)?.url"
      [alt]="item.description"
      [title]="item.description"
      loading="lazy">

    <img *ngIf="!item.gallery?.length"
      src="/assets/img/placeholder-image.png">

    <span class="position-absolute image-type d-flex flex-column"
      *ngIf="item.gallery?.length">
      <mat-icon *ngIf="item.lookbooks_to?.length"
        [color]="item.gallery[item.galleryImageIndex]?.type==='lookbooks_to' ? 'accent':'primary'"
        [matTooltip]="item.gallery[item.galleryImageIndex]?.type==='lookbooks_to' ? ('COMMON.LABEL.LOOKBOOK' | translate) +': '+ item.gallery[item.galleryImageIndex]?.inventory : ''"
        class="mb-2 material-icons-outlined">
        portrait
      </mat-icon>

      <mat-icon *ngIf="item.lookbooks_from?.length"
        [color]="item.gallery[item.galleryImageIndex]?.type==='lookbooks_from' ? 'accent':'primary'"
        [matTooltip]="item.gallery[item.galleryImageIndex]?.type==='lookbooks_from' ? ('COMMON.LABEL.ITEM' | translate) +': '+ item.gallery[item.galleryImageIndex]?.inventory : ''"
        class="mb-2">
        checkroom
      </mat-icon>

      <mat-icon *ngIf="item.links?.length"
        [color]="item.gallery[item.galleryImageIndex]?.type==='link' ? 'accent':'primary'"
        [matTooltip]="item.gallery[item.galleryImageIndex]?.type==='link' ? ('COMMON.LABEL.LINK' | translate) +': '+ item.gallery[item.galleryImageIndex]?.inventory : ''">
        link
      </mat-icon>
    </span>

    <div class="slideshow-buttons">
      <button mat-icon-button
        class="left arrow"
        *ngIf="item.gallery?.length > 1"
        (click)="showPreviousImage($event)">
        <mat-icon>chevron_left</mat-icon>
      </button>

      <button mat-icon-button
        class="right arrow"
        *ngIf="item.gallery?.length > 1"
        (click)="showNextImage($event)">
        <mat-icon>chevron_right</mat-icon>
      </button>
    </div>
  </ng-container>
</div>