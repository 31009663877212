import { Component, Input, OnInit, ViewContainerRef } from '@angular/core';
import { EApiCallStateNames, EArchiveType } from '@web/enums';
import { environment } from '@env/environment';
import { CreateDynamicComponentService } from '@web/core/services/create-dynamic-component.service';

@Component({
  selector: 'mpa-filter-panel',
  template: '',
})
export class DynamicFilterPanelComponent implements OnInit {
  @Input() showFolderSelect = false;
  @Input() showLoanSelect = false;
  @Input() showSuitSelect = false;
  @Input() apiCallStateName: EApiCallStateNames;

  constructor(
    private createDynamicComponentService: CreateDynamicComponentService,
    private viewContainerRef: ViewContainerRef
  ) {}

  async ngOnInit() {
    let component = null;
    const inputs = {
      apiCallStateName: this.apiCallStateName,
    };
    switch (environment.archiveType) {
      case EArchiveType.FASHION:
        component = (
          await import('@web/dynamic-modules/fashion/fashion-filter-panel/fashion-filter-panel.component')
        ).FashionFilterPanelComponent;
        break;
      case EArchiveType.MULTIMEDIA:
        component = (
          await import(
            '@web/dynamic-modules/multimedia/multimedia-filter-panel/multimedia-filter-panel.component'
          )
        ).MultimediaFilterPanelComponent;
        break;
      case EArchiveType.CELLAR:
        component = (
          await import('@web/dynamic-modules/cellar/cellar-filter-panel/cellar-filter-panel.component')
        ).CellarFilterPanelComponent;
        break;
    }

    await this.createDynamicComponentService.loadComponent(component, this.viewContainerRef, inputs);
  }
}
