import { Component, OnInit } from '@angular/core';
import { marker as bitfToTranslate } from '@biesbjerg/ngx-translate-extract-marker';

import { Archive, Image } from '@common/core/models';
import {
  ApiCallStateService,
  ArchivesService,
  FilesService,
  LoaderService,
  StoreService,
  UiRoleManagerService,
} from '@common/core/services';
import { IBitfGraphQlResponse } from '@common/interfaces';
import { BitfFile } from '@common/libs/bitforce/core/models';
import { TranslateService } from '@ngx-translate/core';
import { ToastMessagesService } from '@web/core/services';
import { EApiCallStateNames, EBitfUiMessageType, ERoleActions } from '@web/enums';
import { from, Observable } from 'rxjs';
import { catchError, finalize, switchMap, tap } from 'rxjs/operators';

@Component({
  selector: 'mpa-duplicate-archive',
  templateUrl: './duplicate-archive.component.html',
  styleUrls: ['./duplicate-archive.component.scss'],
})
export class DuplicateArchiveComponent implements OnInit {
  eRoleActions = ERoleActions;

  constructor(
    public uiRoleManagerService: UiRoleManagerService,
    private translateService: TranslateService,
    private storeService: StoreService,
    private apiCallStateService: ApiCallStateService,
    private archivesService: ArchivesService,
    private filesService: FilesService,
    private loaderService: LoaderService,
    private toastMessagesService: ToastMessagesService
  ) {}

  ngOnInit(): void {}

  async onDuplicateArchive() {
    if (!window.confirm(this.translateService.instant('ITEM.DETAIL.CONFIRM_DUPLICATING_IMAGE'))) {
      return;
    }

    this.loaderService.show();

    this.archivesService
      .duplicateArchive(this.storeService.store.selectedItem)
      .pipe(
        tap((updatedArchiveResponse: IBitfGraphQlResponse<Archive>) => {
          this.toastMessagesService.show({
            duration: 15000,
            title: this.translateService.instant(bitfToTranslate('ITEM.DETAIL.ITEM_DUPLICATED'), {
              inventory: updatedArchiveResponse.content.inventory,
            }),
            type: EBitfUiMessageType.SUCCESS,
          });
          this.storeService.updateSelectedItem(updatedArchiveResponse.content);
          // NOTE: this is not optimal, we should pass the apiCallstateName (archive or folder details)
          // but this component it's very deep in the gallery tree and since this action
          // it's not very common we can live with this
          this.apiCallStateService.setStore(() => {}, EApiCallStateNames.ARCHIVE);
          this.apiCallStateService.setStore(() => {}, EApiCallStateNames.FOLDER_DETAILS);
        }),
        finalize(() => {
          this.loaderService.hide();
        })
      )
      .subscribe();
  }
}
