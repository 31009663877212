import { MAT_DATE_LOCALE } from '@angular/material/core';
import { Optional, Inject, Injectable, Injector } from '@angular/core';
import { Platform } from '@angular/cdk/platform';

import { BitfDateAdapterService } from '@bitf/services/locale/bitf-date-adapter.service';
import { BitfDynamicLocaleService } from '@bitf/services/locale/bitf-dynamic-locale.service';

@Injectable({
  providedIn: 'root',
})
export class DateAdapterService extends BitfDateAdapterService {
  firstDayMap = {
    it: 1,
    en: 1,
  };
  localeFormatMap = {
    it: 'dd/MM/yyyy',
    en: 'MM/dd/yyyy',
  };
  constructor(
    @Optional() @Inject(MAT_DATE_LOCALE) matDateLocale: string,
    platform: Platform,
    protected bitfDynamicLocaleService: BitfDynamicLocaleService
  ) {
    super(matDateLocale, platform, bitfDynamicLocaleService);
  }
}
