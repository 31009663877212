<bitf-dnd-file (dropFiles)="onFileDropped($event)"
  *ngIf="showDndArea"
  class="d-none d-md-block">
  <div class="d-flex flex-column align-items-center justify-content-center h-100 py-5">
    <div class="text-center">
      {{ 'BITF.FILE_UPLOADER.DROP_FILES_HERE_OR_SELECT' | translate }}
    </div>

    <ng-container *ngTemplateOutlet="selectFileTemplate"></ng-container>

  </div>
</bitf-dnd-file>

<div class="d-block d-md-none w-100 p-1">
  <h4>{{ 'BITF.FILE_UPLOADER.UPLOAD' | translate }}</h4>
  <ng-container *ngTemplateOutlet="selectFileTemplate"></ng-container>
</div>

<div *ngIf="files.length && showFileList"
  class="mt-3">

  <h3 class="d-flex align-items-center justify-content-between">
    {{'BITF.FILE_UPLOADER.SELECTED_FILES' | translate}} ({{ files.length }})

    <div class="d-flex align-items-center">
      <button mat-stroked-button
        color="primary"
        *ngIf="!uploadOnFilesDropped"
        (click)="removeAllFiles()">
        {{ 'BITF.FILE_UPLOADER.REMOVE_ALL_FILES' | translate }}
      </button>
      <button mat-raised-button
        *ngIf="!fileUploaderConfig.hideUploadButton && !uploadOnFilesDropped"
        color="primary"
        (click)="upload()"
        class="ml-2">
        {{ 'BITF.FILE_UPLOADER.UPLOAD' | translate }}
      </button>
    </div>
  </h3>

  <mat-list role="list">
    <mat-list-item *ngFor="let file of files; let index = index;"
      class="border-bottom">
      <div class="row m-0 w-100">
        <div class="col-19 pl-0 pr-2 mat-caption d-flex flex-column justify-content-center">
          <span class="file-name">{{ file.fileObject.name }}</span>
          <span>({{file.fileObject.size | bitfBytes}})</span>
        </div>

        <div class="col-5 pl-2 pr-0">
          <div class="w-100 h-100 d-flex align-items-center justify-content-end">
            <mat-spinner color="primary"
              class="mx-2"
              [diameter]="22"
              *ngIf="file.isUploading"></mat-spinner>

            <button mat-icon-button
              color="primary"
              class="ml-2 cursor-pointer"
              *ngIf="!file.isUploading"
              (click)="removeFile(index)">
              <mat-icon>delete</mat-icon>
            </button>

            <button mat-icon-button
              color="primary"
              *ngIf="file.validationErrors.length"
              color="warn"
              class="ml-2"
              [matTooltip]="file.validationErrorsTooltip">
              <mat-icon>warning</mat-icon>
            </button>

            <mat-icon *ngIf="file.hasUploadErrors"
              color="warn"
              class="ml-2">error</mat-icon>
          </div>
        </div>
      </div>
    </mat-list-item>
  </mat-list>
</div>

<ng-template #selectFileTemplate>
  <div class="my-3">
    <button mat-stroked-button
      color="primary"
      (click)="selectFile.click()"> {{ 'BITF.FILE_UPLOADER.SELECT_FILE' | translate | uppercase }}
    </button>
    <input class="d-none"
      type="file"
      #selectFile
      id="selectFile"
      [attr.multiple]="(maxNumberOfFiles > 1) ? '' : null"
      (change)="onFileDropped($event)" />
  </div>

  <div *ngIf="uploadFolder">
    <button mat-stroked-button
      color="primary"
      (click)="selectDirectory.click()"> {{ 'BITF.FILE_UPLOADER.SELECT_FOLDER' | translate | uppercase }}
    </button>
    <input class="d-none"
      type="file"
      #selectDirectory
      webkitdirectory
      mozdirectory
      (change)="onFileDropped($event)" />
  </div>
</ng-template>