import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';

import { gql } from 'apollo-angular';

import { BitfGraphQlService } from '@bitf/services/graph-ql/bitf-graph-ql.service';

import { IBitfGraphQlRequest, IBitfGraphQlResponse } from '@interfaces';
import { Sale } from '@models';
import { SALE_ENTITY, SALE_LIST_ENTITY, SALE_WITH_ALL_RELATIONS_ENTITY } from './sales.gql';
import { tap } from 'rxjs/operators';
import { PAGINATION_ENTITY } from './strapi.gql';
import { getSalePrintDataGql } from './archives.gql';

@Injectable({
  providedIn: 'root',
})
// NOTE: Add methods following CRUD order
export class SalesService extends BitfGraphQlService {
  constructor(public injector: Injector) {
    super(injector);
  }

  get(requestParams: IBitfGraphQlRequest): Observable<IBitfGraphQlResponse<Sale[]>> {
    requestParams.modelMapper = 'sales';
    requestParams.query = gql`
      ${SALE_WITH_ALL_RELATIONS_ENTITY}
      ${PAGINATION_ENTITY}
      query Query($pagination: PaginationArg, $sort: [String], $filters: SaleFiltersInput) {
        sales(pagination: $pagination, sort: $sort, filters: $filters) {
          data {
            ...SaleWithAllRelationsEntity
          }
          meta {
            pagination {
              ...PaginationEntity
            }
          }
        }
      }
    `;

    return super.query<Sale[]>(requestParams);
  }

  getList(requestParams: IBitfGraphQlRequest): Observable<IBitfGraphQlResponse<Sale[]>> {
    requestParams.modelMapper = 'sales';
    requestParams.query = gql`
      ${SALE_LIST_ENTITY}
      ${PAGINATION_ENTITY}
      query Query($pagination: PaginationArg, $sort: [String], $filters: SaleFiltersInput) {
        sales(pagination: $pagination, sort: $sort, filters: $filters) {
          data {
            ...SaleListEntity
          }
          meta {
            pagination {
              ...PaginationEntity
            }
          }
        }
      }
    `;

    return super.query<Sale[]>(requestParams);
  }

  create(requestParams: IBitfGraphQlRequest = {}) {
    requestParams.modelMapper = 'createSale';
    requestParams.mutation = gql`
      ${SALE_ENTITY}
      mutation Create($input: SaleInput!) {
        createSale(data: $input) {
          data {
            ...SaleEntity
          }
        }
      }
    `;

    return super.mutate<Sale>(requestParams);
  }

  getItemById(
    requestParams: IBitfGraphQlRequest,
    queryType: 'sale' | 'saleDetails' = 'sale'
  ): Observable<IBitfGraphQlResponse<Sale>> {
    requestParams.modelMapper = 'sale';
    switch (queryType) {
      case 'sale':
        requestParams.query = gql`
          ${SALE_ENTITY}
          query Query($id: ID) {
            sale(id: $id) {
              data {
                ...SaleEntity
              }
            }
          }
        `;
        break;
      case 'saleDetails':
        requestParams.query = gql`
          ${SALE_WITH_ALL_RELATIONS_ENTITY}
          query Query($id: ID) {
            sale(id: $id) {
              data {
                ...SaleWithAllRelationsEntity
              }
            }
          }
        `;
        break;
    }

    return super.query<Sale>(requestParams);
  }

  updateItem(
    requestParams: IBitfGraphQlRequest<Partial<Sale>> = {},
    sale?: Partial<Sale>
  ): Observable<IBitfGraphQlResponse<Sale>> {
    requestParams.modelMapper = 'updateSale';
    requestParams.mutation = gql`
      ${SALE_ENTITY}
      mutation Update($id: ID!, $input: SaleInput!) {
        updateSale(id: $id, data: $input) {
          data {
            ...SaleEntity
          }
        }
      }
    `;
    return super.mutate<Sale>(requestParams, sale).pipe(
      tap((response: IBitfGraphQlResponse<Sale>) => {
        if (sale) {
          Object.assign(sale, response.content);
        }
      })
    );
  }

  deleteItem(requestParams: IBitfGraphQlRequest = {}) {
    requestParams.modelMapper = 'deleteSale';
    requestParams.mutation = gql`
      mutation Mutate($id: ID!) {
        deleteSale(id: $id) {
          data {
            id
          }
        }
      }
    `;
    return super.mutate<Sale>(requestParams);
  }

  getPrintData(requestParams: IBitfGraphQlRequest = {}): Observable<IBitfGraphQlResponse<Sale>> {
    requestParams.modelMapper = 'sale';
    requestParams.query = gql`
      ${getSalePrintDataGql()}
    `;

    return super.query<Sale>(requestParams);
  }
}
