import { Injectable, Injector } from '@angular/core';

import { BitfWebUiMessagesListenerService } from '@bitf/services/ui-messages-listener/web/bitf-web-ui-messages-listener.service';

@Injectable({
  providedIn: 'root',
})
export class UiMessagesListenerService extends BitfWebUiMessagesListenerService {
  constructor(protected injector: Injector) {
    super(injector);
  }
}
