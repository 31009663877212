import { EBitfStoreActions } from '@common/enums';
export enum EWebStoreActions {
  SET_SELECTED_ITEM = 'SET_SELECTED_ITEM',
  UPDATE_SELECTED_ITEM = 'UPDATE_SELECTED_ITEM',
  SET_SELECTED_FOLDER = 'SET_SELECTED_FOLDER',
  SET_SELECTED_LOAN = 'SET_SELECTED_LOAN',
  SET_SELECTED_SALE = 'SET_SELECTED_SALE',
  SET_GALLERY_ITEMS = 'SET_GALLERY_ITEMS',
  UPDATE_GALLERY_ITEMS = 'UPDATE_GALLERY_ITEMS',
  CONCAT_GALLERY_ITEMS = 'CONCAT_GALLERY_ITEMS',
  SET_CONFIGURATIONS = 'SET_CONFIGURATIONS',
  EVENT_UPDATE_ARCHIVES_TOTAL_VALUE = 'EVENT_UPDATE_ARCHIVES_TOTAL_VALUE',
}

export const eStoreActions = { ...EBitfStoreActions, ...EWebStoreActions };
