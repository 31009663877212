import { Injectable } from '@angular/core';
import { Injector } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Router } from '@angular/router';
import { Observable, from, of } from 'rxjs';
import { switchMap, catchError, map } from 'rxjs/operators';

import { BITF_CONFIGS } from '@configs';
import { StoreService, AuthService, UsersService, StorageService } from '@services';

@Injectable({
  providedIn: 'root',
})
export class BitfAuthGuard {
  protected authService: AuthService;
  protected usersService: UsersService;
  protected router: Router;
  protected storeService: StoreService;
  protected storageService: StorageService;

  constructor(protected injector: Injector) {
    this.authService = injector.get<AuthService>(AuthService);
    this.usersService = injector.get<UsersService>(UsersService);
    this.router = injector.get(Router);
    this.storeService = injector.get(StoreService);
    this.storageService = injector.get(StorageService);
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    // NOTE: if we persist the user in the session or local storage could be that we still have the user
    // loaded but the token is expired, so we have to check both
    if (this.storeService.store.user && this.authService.isTokenValid()) {
      return true;
    } else if (this.authService.isTokenValid()) {
      return this.authService.loginUserWithToken(next).pipe(
        catchError(() => {
          const queryParams = new URLSearchParams(next.queryParams).toString();
          //this.router.navigate was not working, so we use window.location.href
          window.location.href = `${BITF_CONFIGS.urls.signInUrl}?${queryParams}`;
          return of(false);
        })
      );
    } else {
      // NOTE store the not allowed url in the sessionStorage, this will be readed from the auth
      // class on the sign in callback
      this.storageService.data = { redirectUrl: location.href };
      // Try to renew the token
      return from(this.authService.renewToken()).pipe(
        switchMap(() => this.authService.loginUserWithToken(next).pipe(map(() => true))),
        catchError(() => {
          const queryParams = new URLSearchParams(next.queryParams).toString();
          //this.router.navigate was not working, so we use window.location.href
          window.location.href = `${BITF_CONFIGS.urls.signInUrl}?${queryParams}`;
          return of(false);
        })
      );
    }
  }

  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.canActivate(childRoute, state);
  }
}
