import { BitfStrapiAuth } from '@common/libs/bitforce/core/models';
import * as Models from '@models';

export const modelsMap = new Map<string, any>([
  ['login', BitfStrapiAuth],
  ['me', Models.User],
  ['usersPermissionsUsers', Models.User],
  ['archives', Models.Archive],
  ['archive', Models.Archive],
  ['archiveByInventory', Models.Archive],
  ['deleteArchive', Models.Void],
  ['createArchive', Models.Archive],
  ['updateArchive', Models.Archive],
  ['filters', Models.Filter],
  ['configuration', Models.Configuration],
  ['updateConfiguration', Models.Configuration],
  ['folders', Models.Folder],
  ['folder', Models.Folder],
  ['deleteFolder', Models.Void],
  ['linkSuit', Models.Suit],
  ['linkItem', Models.Suit],
  ['updateSuit', Models.Suit],
  ['createSuit', Models.Suit],
  ['deleteSuit', Models.Suit],
  ['loans', Models.Loan],
  ['loan', Models.Loan],
  ['deleteLoan', Models.Void],
  ['createLoan', Models.Loan],
  ['updateLoan', Models.Loan],
  ['archiveLoan', Models.ArchiveLoan],
  ['archiveLoans', Models.ArchiveLoan],
  ['deleteArchiveLoan', Models.Void],
  ['createArchiveLoan', Models.ArchiveLoan],
  ['updateArchiveLoan', Models.ArchiveLoan],
  ['sales', Models.Sale],
  ['sale', Models.Sale],
  ['deleteSale', Models.Void],
  ['createSale', Models.Sale],
  ['updateSale', Models.Sale],
  ['archiveSale', Models.Archive],
  ['archiveSales', Models.ArchiveSale],
  ['deleteArchiveSale', Models.Void],
  ['createArchiveSale', Models.ArchiveSale],
  ['updateArchiveSale', Models.ArchiveSale],
  ['createFolder', Models.Folder],
  ['updateFolder', Models.Folder],
  ['upload', Models.Image],
  ['removeFile', Models.Image],
]);
