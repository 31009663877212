import { Injectable, Injector } from '@angular/core';

import { gql } from 'apollo-angular';

import { BitfGraphQlService } from '@bitf/services/graph-ql/bitf-graph-ql.service';

import { IBitfGraphQlRequest } from '@interfaces';
import { Image } from '@models';

@Injectable({
  providedIn: 'root',
})
// NOTE: Add methods following CRUD order
export class ImagesService extends BitfGraphQlService {
  constructor(public injector: Injector) {
    super(injector);
  }

  delete(requestParams: IBitfGraphQlRequest = {}) {
    requestParams.modelMapper = 'removeFile';
    requestParams.mutation = gql`
      mutation Mutate($id: ID!) {
        removeFile(id: $id) {
          data {
            id
          }
        }
      }
    `;
    return super.mutate<Image>(requestParams);
  }
}
