import { IMAGE_ENTITY } from './images.gql';

export const ARCHIVE_SALE = `
  fragment ArchiveSale on ArchiveSale {
    note_out
  }
`;

export const ARCHIVE_SALE_ENTITY = `
  ${ARCHIVE_SALE}
  ${IMAGE_ENTITY}
  fragment ArchiveSaleEntity on ArchiveSaleEntity {
    id
    attributes {
      ...ArchiveSale
      archive {
        data {
          id
          attributes {
            inventory
            description
            quantity
            value
            images {
              data {
                ...ImageEntity
              }
            }
            sale {
              data {
                id
                attributes {
                  name
                  date
                  note
                  reference
                }
              }
            }
            archive_sales {
              data {
                id
                attributes {
                  ...ArchiveSale
                  sale {
                    data {
                      id
                      attributes {
                        name
                        date
                        note
                        reference
                      }
                    }
                  }
                }
              }
            }

            is_visible_to_editor
            is_visible_to_collaborator
            is_visible_to_ufficio_stile
            is_visible_to_viewer
          }
        }
      }
      sale {
        data {
          id
          attributes {
            name
            date
            note
            reference
          }
        }
      }
    }
  }
`;
