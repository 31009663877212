import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { marker as bitfToTranslate } from '@biesbjerg/ngx-translate-extract-marker';

import { EBitfCloseEventStatus, ERoleActions } from '@common/enums';
import { IBitfCloseEvent } from '@common/interfaces';
import { Archive, Folder } from '@models';
import { DialogsService, UiRoleManagerService } from '@services';
import { CONSTANTS } from '@web/constants';
import { environment } from '@env/environment';
import { EAppSections, EArchiveType } from '@web/enums';

@Component({
  selector: 'mpa-item-card',
  templateUrl: './item-card.component.html',
  styleUrls: ['./item-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class ItemCardComponent implements OnInit {
  @Input() item: Archive;
  @Input() folder: Folder;
  @Input() isSelected = false;
  @Input() isReadOnly = false;
  @Output() detail = new EventEmitter();
  @Output() select = new EventEmitter();
  @Output() delete = new EventEmitter();
  eRoleActions = ERoleActions;

  showQuantity = false;

  constructor(
    private dialogsService: DialogsService,
    private translateService: TranslateService,
    public uiRoleManagerService: UiRoleManagerService
  ) {}

  ngOnInit(): void {
    if (environment.appSections.includes(EAppSections.SALE)) {
      this.showQuantity = true;
    }
  }

  onSelect() {
    if (this.isReadOnly) return;
    this.select.emit();
  }

  onItemDetail(isZoom = false) {
    this.detail.emit(isZoom);
  }

  onDelete(deleteType: 'deleteItem' | 'deleteFromFolder' | 'deleteFromLoan' = 'deleteItem') {
    let title: string = bitfToTranslate('ITEM.DIALOG.DELETE_CARD.TITLE');
    let message: string = this.translateService.instant(
      bitfToTranslate('ITEM.DIALOG.DELETE_CARD.DESCRIPTION'),
      {
        item: this.item.description,
      }
    );

    switch (deleteType) {
      case 'deleteFromFolder':
        title = bitfToTranslate('ITEM.DIALOG.DELETE_FROM_FOLDER.TITLE');
        message = this.translateService.instant(
          bitfToTranslate('ITEM.DIALOG.DELETE_FROM_FOLDER.DESCRIPTION'),
          {
            item: this.item.description,
          }
        );
        break;
      case 'deleteFromLoan':
        title = bitfToTranslate('ITEM.DIALOG.DELETE_FROM_LOAN.TITLE');
        message = this.translateService.instant(bitfToTranslate('ITEM.DIALOG.DELETE_FROM_LOAN.DESCRIPTION'), {
          item: this.item.description,
        });
        break;
    }

    const dialog = this.dialogsService.dialog.open(CONSTANTS.okCancelDialogComponent, {
      ...CONSTANTS.SMALL_DIALOG_SIZE,
      data: {
        title,
        message,
        cancelText: bitfToTranslate('BITF.LABEL.CANCEL'),
        okText: bitfToTranslate('BITF.LABEL.OK'),
        okButtonType: 'PRIMARY',
      },
    });
    dialog.afterClosed().subscribe((result: IBitfCloseEvent<void>) => {
      if (result?.status === EBitfCloseEventStatus.OK) {
        this.delete.emit(deleteType);
      }
    });
  }

  getTooltipText() {
    switch (environment.archiveType) {
      case EArchiveType.FASHION:
        const materials = this.item.mainMaterial?.name || '';
        const techniques = this.item.techniques?.map(technique => technique.name).join(', ');
        return `${materials}${materials && techniques ? ' - ' : ''}${techniques}`;
      case EArchiveType.MULTIMEDIA:
        const director = this.item.director?.name || '';
        return `${director}`;
      case EArchiveType.CELLAR:
        const seasoning_date = this.item.seasoning_date
          ? `${this.translateService.instant(
              'CELLAR.FIELDS.SEASONING_DATE'
            )}: ${this.item.seasoning_date.toLocaleDateString('it')}`
          : '';
        const totalPrice =
          this.item.quantity && this.item.value
            ? `${this.translateService.instant('CELLAR.FIELDS.TOTAL_PRICE')}: ${
                this.item.quantity * this.item.value
              } €`
            : '';
        return `${seasoning_date}\n${totalPrice}`;
    }
  }

  getSaleTooltipText() {
    if (this.item.sale) {
      const sold_date = this.translateService.instant('COMMON.LABEL.SOLD_ON_DATE', {
        date: this.item.sale.date.toLocaleDateString('it'),
      });
      const sale = `ID: ${this.item.sale.id} - ${this.item.sale.name}`;
      return `${sale}\n${sold_date}`;
    }
    return '';
  }
}
