<mat-toolbar [ngClass]="{'toolbar-selected': selectedItems.size || allItemsSelected}">
  <!-- <mat-toolbar-row *bitfUiRoleManager="{action : eRoleActions.VIEW_BULK_ACTIONS, inverted: true}"
    class="overflow-auto">
    <span class="ml-3 toolbar-label font-weight-bold">
      <span class="font-weight-bold"> {{ 'ITEM.SEARCH_RESULTS_COUNT' | translate}} </span>
      {{pagination?.totalItems}}
    </span>
  </mat-toolbar-row> -->

  <mat-toolbar-row class="overflow-auto"
    [ngClass]="{'d-flex': !selectedItems.size && !unselectedItems.size && !allItemsSelected}">
    <div class="d-flex align-items-center">
      <mat-checkbox color="primary"
        *ngIf="!isReadOnly"
        [(ngModel)]="allItemsSelected"
        (ngModelChange)="onAllItemsSelected($event)"
        [indeterminate]="!!selectedItems.size || !!unselectedItems.size">
        <span class="toolbar-label text-uppercase">{{ 'ITEM.SELECT_ALL' | translate}}</span>
      </mat-checkbox>

      <div class="ml-3 toolbar-label">
        <div *ngIf="!selectedItems.size && !unselectedItems.size && !allItemsSelected"
          class="d-flex">
          <div class="d-flex align-items-center mr-2">
            <span class="font-weight-bold mr-1">{{'ITEM.SEARCH_RESULTS_COUNT' | translate}}</span>
            <mat-chip-listbox>
              <mat-chip class="returned-chip">{{pagination?.totalItems}}</mat-chip>
            </mat-chip-listbox>
          </div>

          <div class="d-flex align-items-center mr-2">
            <span class="font-weight-bold mr-1">{{'ITEM.SEARCH_RESULTS_TOTAL_QUANTITY_VALUE' |
              translate}}</span>
            <mat-chip-listbox>
              <mat-chip class="returned-chip">{{archivesQuantity}}</mat-chip>
            </mat-chip-listbox>
          </div>

          <ng-container *bitfUiRoleManager="{action: eRoleActions.VIEW_VALUE}">
            <div class="d-flex align-items-center">
              <span class="font-weight-bold mr-1">{{'ITEM.SEARCH_RESULTS_TOTAL_ARCHIVES_VALUE' |
                translate}}</span>
              <mat-chip-listbox *ngIf="archivesValue >= 0">
                <mat-chip class="returned-chip">{{ archivesValue | bitfNumber: '.0' }}&euro;</mat-chip>
              </mat-chip-listbox>
            </div>
          </ng-container>
        </div>

        <span *ngIf="allItemsSelected && !unselectedItems.size">
          {{pagination?.totalItems}} / {{pagination?.totalItems}}
        </span>

        <span *ngIf="selectedItems.size">
          {{selectedItems.size}} / {{pagination?.totalItems}}
        </span>

        <span *ngIf="unselectedItems.size">
          {{pagination?.totalItems - unselectedItems.size}} / {{pagination?.totalItems}}
        </span>
      </div>
    </div>

    <div class="d-flex align-items-center"
      *ngIf="selectedItems.size || allItemsSelected">
      <button mat-icon-button
        matTooltip="{{'COMMON.LABEL.INVERT_SELECTION' | translate}}"
        (click)="onInvertSelection()">
        <mat-icon class="mr-2">published_with_changes</mat-icon>
      </button>
      <button class="text-uppercase"
        *bitfUiRoleManager="{action : eRoleActions.EDIT_ITEM}"
        (click)="onBulkEdit()"
        mat-stroked-button>{{ 'ITEM.BUTTONS.EDIT' | translate}}</button>

      <!-- Loan actions -->
      <ng-container *ngIf="environment.appSections.includes(EAppSections.LOAN)">
        <button *bitfUiRoleManager="{action : eRoleActions.MANAGE_LOANS}"
          class="ml-3 text-uppercase"
          mat-stroked-button
          [matMenuTriggerFor]="loanMenu">{{ 'ITEM.BUTTONS.LOAN' | translate}}
          <mat-icon class="mb-1">arrow_drop_down</mat-icon>
        </button>
        <mat-menu #loanMenu="matMenu">
          <button mat-menu-item
            (click)="onCreateLoan()">{{ 'ITEM.BUTTONS.CREATE_LOAN' | translate}}</button>
          <button mat-menu-item
            (click)="onAddToLoan()">{{ 'ITEM.BUTTONS.ADD_TO_LOAN' | translate}}</button>
        </mat-menu>
      </ng-container>

      <!-- Sale actions -->
      <ng-container *ngIf="environment.appSections.includes(EAppSections.SALE)">
        <button *bitfUiRoleManager="{action : eRoleActions.MANAGE_SALES}"
          class="ml-3 text-uppercase"
          mat-stroked-button
          [matMenuTriggerFor]="saleMenu">{{ 'ITEM.BUTTONS.SALE' | translate}}
          <mat-icon class="mb-1">arrow_drop_down</mat-icon>
        </button>
        <mat-menu #saleMenu="matMenu">
          <button mat-menu-item
            (click)="onCreateSale()">{{ 'ITEM.BUTTONS.CREATE_SALE' | translate}}</button>
          <button mat-menu-item
            (click)="onAddToSale()">{{ 'ITEM.BUTTONS.ADD_TO_SALE' | translate}}</button>
        </mat-menu>
      </ng-container>

      <!-- Folder actions -->
      <ng-container *ngIf="environment.appSections.includes(EAppSections.FOLDER)">
        <button *bitfUiRoleManager="{action : eRoleActions.VIEW_FOLDER_ACTIONS}"
          class="ml-3 text-uppercase"
          mat-stroked-button
          [matMenuTriggerFor]="folderMenu">{{ 'ITEM.BUTTONS.FOLDER' | translate}}
          <mat-icon class="mb-1">arrow_drop_down</mat-icon>
        </button>
        <mat-menu #folderMenu="matMenu">
          <button (click)="onCreateFolder()"
            *bitfUiRoleManager="{action : eRoleActions.CREATE_FOLDER}"
            mat-menu-item>{{ 'ITEM.BUTTONS.CREATE_FOLDER' | translate}}</button>

          <div *bitfUiRoleManager="{action : eRoleActions.MANAGE_FOLDERS}">
            <button (click)="onCopyIntoFolder()"
              mat-menu-item>{{ 'ITEM.BUTTONS.COPY_INTO_FOLDER' | translate}}</button>

            <button *ngIf="folder && !folder.is_image_folder"
              (click)="onMoveToFolder()"
              mat-menu-item>{{ 'ITEM.BUTTONS.MOVE_TO_FOLDER' | translate}}</button>

            <button *ngIf="folder"
              (click)="onRemoveFromFolder()"
              mat-menu-item>{{ 'FOLDER.REMOVE_FROM_FOLDER' | translate}}</button>
          </div>
        </mat-menu>
      </ng-container>

      <button mat-stroked-button
        class="ml-3 text-uppercase"
        (click)="onPrint()">{{ 'COMMON.LABEL.PRINT' | translate}}</button>
    </div>
  </mat-toolbar-row>
</mat-toolbar>