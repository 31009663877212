<form [formGroup]="form">
  <div class="row">
    <div class="col">
      <mat-form-field appearance="outline"
        class="w-100">
        <mat-label>{{'LOAN.NAME' | translate}}</mat-label>
        <input matInput
          formControlName="name"
          type="text">
      </mat-form-field>
    </div>
    <div class="col-4"
      *ngIf="loan">
      <mat-form-field appearance="outline"
        class="w-100">
        <mat-label>Id</mat-label>
        <input matInput
          readonly
          [value]="loan.id"
          type="text">
      </mat-form-field>
    </div>
  </div>

  <mat-form-field appearance="outline"
    class="w-100">
    <mat-label>{{'LOAN.REFERENCE' | translate}}</mat-label>
    <input matInput
      formControlName="reference"
      type="text">
  </mat-form-field>

  <div class="d-flex">
    <mat-form-field appearance="outline"
      class="w-100">
      <mat-label>{{'LOAN.DATE_OUT' | translate}}</mat-label>
      <input matInput
        [matDatepicker]="dateOutPicker"
        formControlName="date_out">
      <mat-datepicker-toggle matSuffix
        [for]="dateOutPicker"></mat-datepicker-toggle>
      <mat-datepicker #dateOutPicker></mat-datepicker>
    </mat-form-field>
  </div>

  <mat-form-field appearance="outline"
    class="w-100">
    <mat-label>{{'LOAN.NOTE' | translate}}</mat-label>
    <textarea matInput
      formControlName="note"
      type="text"></textarea>
  </mat-form-field>

  <div class="mb-3">
    <mat-checkbox formControlName="is_visible_to_editor"
      *bitfUiRoleManager="{action : eRoleActions.MANAGE_EDITOR_LOANS}">
      <span>{{ 'LOAN.IS_VISIBLE_TO_EDITOR' | translate}}</span>
    </mat-checkbox>
    <mat-checkbox formControlName="is_visible_to_collaborator"
      *bitfUiRoleManager="{action : eRoleActions.MANAGE_COLLABORATOR_LOANS}">
      <span>{{ 'LOAN.IS_VISIBLE_TO_COLLABORATOR' | translate}}</span>
    </mat-checkbox>
    <mat-checkbox formControlName="is_visible_to_ufficio_stile"
      *bitfUiRoleManager="{action : eRoleActions.MANAGE_UFFICIO_STILE_LOANS}">
      <span>{{ 'LOAN.IS_VISIBLE_TO_UFFICIO_STILE' | translate}}</span>
    </mat-checkbox>
    <mat-checkbox formControlName="is_visible_to_viewer"
      *bitfUiRoleManager="{action : eRoleActions.MANAGE_VIEWER_LOANS}">
      <span>{{ 'LOAN.IS_VISIBLE_TO_VIEWER' | translate}}</span>
    </mat-checkbox>
  </div>
</form>