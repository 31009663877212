import {
  TYPOLOGY_1_ENTITY,
  TYPOLOGY_2_ENTITY,
  TYPOLOGY_3_ENTITY,
  TYPOLOGY_4_ENTITY,
} from '@common/core/services';

export const TYPOLOGIES = `
  ${TYPOLOGY_1_ENTITY}
  ${TYPOLOGY_2_ENTITY}
  ${TYPOLOGY_3_ENTITY}
  ${TYPOLOGY_4_ENTITY}
  fragment Typologies on Archive {
    t_1s {
      data {
        ...Typology1Entity
      }
    }
    t_2s {
      data {
        ...Typology2Entity
      }
    }
    t_3s {
      data {
        ...Typology3Entity
      }
    }
    t_4s {
      data {
        ...Typology4Entity
      }
    }
  }`;

const typologyProperties = `
  name
`;
export const TYPOLOGY_NESTED_RELATIONS_ENTITY = `
  fragment NestedTypologies on T1Entity {
    id
    attributes {
      ${typologyProperties}
      children: t_2s (pagination: { limit: 300 }, sort: ["name:ASC"]){
        data {
          id
          attributes {
            ${typologyProperties} 
            children: t_3s(pagination: { limit: 300 }, sort: ["name:ASC"]) { 
              data {
                id
                attributes {
                  ${typologyProperties} 
                  children: t_4s(pagination: { limit: 300 }, sort: ["name:ASC"]) { 
                    data {
                      id
                      attributes {
                        ${typologyProperties} 
                      }
                    }
                  }
                }
              }
            }
          }
        }
      } 
    }
  }
`;
