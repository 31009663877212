import { BitfStore } from '@bitf/core/models/bitf-store.model';
import { Filter, User, Configuration, Archive, Folder, Loan, Sale } from '@models';

// NOTE: default values must be defined in the StoreService, not in the model
// (otherwise the default here will override the super Object.assign())
export class Store extends BitfStore {
  user: User;
  filters: Filter;
  configuration: Configuration;
  galleryItems: Archive[];
  selectedItem: Archive;
  selectedFolder: Folder;
  selectedLoan: Loan;
  selectedSale: Sale;

  constructor(storeData: Partial<Store>) {
    super(storeData);
  }
}
