import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';

import { gql } from 'apollo-angular';

import { BitfGraphQlService } from '@common/libs/bitforce/core/services/graph-ql/bitf-graph-ql.service';

import { IBitfGraphQlRequest, IBitfGraphQlResponse } from '@interfaces';
import { User } from '@models';
import { AuthService } from '../auth.service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class UsersService extends BitfGraphQlService {
  constructor(public injector: Injector) {
    super(injector);
  }

  getMe(): Observable<IBitfGraphQlResponse<User>> {
    const authService = this.injector.get(AuthService);
    return this.getUsers({ filter: [{ id: { eq: authService.authTokenMetaData?.id } }] }).pipe(
      map(response => ({
        ...response,
        content: response.content[0],
      }))
    );
  }

  getUsers(requestParams: IBitfGraphQlRequest = {}): Observable<IBitfGraphQlResponse<User[]>> {
    requestParams.modelMapper = 'usersPermissionsUsers';
    requestParams.query = gql`
      query User($filters: UsersPermissionsUserFiltersInput) {
        usersPermissionsUsers(filters: $filters) {
          data {
            id
            attributes {
              username
              email
              first_name
              last_name
              role {
                data {
                  attributes {
                    name
                    description
                    type
                  }
                }
              }
            }
          }
        }
      }
    `;
    return super.query<User[]>(requestParams);
  }
}
