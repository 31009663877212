import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatTabChangeEvent } from '@angular/material/tabs';

import { Archive } from '@common/core/models';
import { ArchivesService, LoaderService, StoreService, UiRoleManagerService } from '@common/core/services';
import { EBitfUiMessageType } from '@common/enums';
import { marker as bitfToTranslate } from '@biesbjerg/ngx-translate-extract-marker';
import { TranslateService } from '@ngx-translate/core';
import { DialogsService, ToastMessagesService } from '@web/core/services';
import { EItemTabs, EPrintType, eStoreActions } from '@web/enums';
import { tap } from 'rxjs/operators';
import { DynamicItemDataFormComponent } from '../dynamic-item-data-form/dynamic-item-data-form.component';
import { DynamicPrintDialogComponent } from '../dynamic-print-dialog/dynamic-print-dialog.component';
import { environment } from '@env/environment';

@Component({
  selector: 'mpa-item-details-tabs',
  templateUrl: './item-details-tabs.component.html',
  styleUrls: ['./item-details-tabs.component.scss'],
})
export class ItemDetailsTabsComponent implements OnInit {
  @Input() isReadOnly: boolean;

  @ViewChild(DynamicItemDataFormComponent) itemDataForm: DynamicItemDataFormComponent;

  selectedTabIndex = 0;
  enabledTabs: string[] = [];

  constructor(
    public uiRoleManagerService: UiRoleManagerService,
    private loaderService: LoaderService,
    private archivesService: ArchivesService,
    private toastMessagesService: ToastMessagesService,
    private translateService: TranslateService,
    public storeService: StoreService,
    private dialogsService: DialogsService
  ) {}

  ngOnInit(): void {
    if (environment.itemTabs.includes(EItemTabs.IMAGES)) {
      this.enabledTabs.push('images');
    }
    if (environment.itemTabs.includes(EItemTabs.SUIT)) {
      this.enabledTabs.push('suit');
    }
    if (environment.itemTabs.includes(EItemTabs.LINKS)) {
      this.enabledTabs.push('links');
    }
    if (environment.itemTabs.includes(EItemTabs.LOOKBOOK)) {
      this.enabledTabs.push('lookbook');
    }
    if (!this.storeService.store.selectedLoan && environment.itemTabs.includes(EItemTabs.NEIGHBOURS)) {
      this.enabledTabs.push('neighbours');
    }
  }

  onSelectTab(event: MatTabChangeEvent) {
    this.selectedTabIndex = event.index;
  }

  save() {
    if (this.itemDataForm.form.valid) {
      this.loaderService.show();
      const item = new Archive(undefined, this.itemDataForm.form.value);
      this.archivesService
        .updateItem({ body: item })
        .pipe(
          tap(() => this.storeService.setStore(() => {}, eStoreActions.EVENT_UPDATE_ARCHIVES_TOTAL_VALUE))
        )
        .subscribe(response => {
          this.storeService.updateSelectedItem(response.content);

          this.toastMessagesService.show({
            type: EBitfUiMessageType.SUCCESS,
            title: this.translateService.instant(bitfToTranslate('ITEM.TOAST.ITEM_UPDATED'), {
              item: response.content.description,
            }),
          });
        });
    } else {
      this.itemDataForm.form.markAllAsTouched();
    }
  }

  print(printType: EPrintType) {
    this.archivesService
      .getPrintData({
        page: 0,
        filter: [{ id: { eq: this.storeService.store.selectedItem.id } }],
      })
      .pipe(
        tap(response => {
          this.dialogsService.dialog.open(DynamicPrintDialogComponent, {
            width: '700px',
            data: {
              items: response.content,
              actions: [printType],
            },
          });
        })
      )
      .subscribe();
  }
}
