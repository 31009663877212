import { Component, OnInit, ViewChild } from '@angular/core';
import { finalize, switchMap, tap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

import { Archive, Image } from '@common/core/models';
import {
  UiRoleManagerService,
  ImagesService,
  ArchivesService,
  LoaderService,
  StoreService,
  FilesService,
} from '@services';
import { ERoleActions } from '@common/enums';
import { IBitfApiResponse, IBitfGraphQlResponse } from '@common/interfaces';
import { FileUploaderComponent } from '../file-uploader/file-uploader.component';
import { BitfFile } from '@common/libs/bitforce/core/models';
import { bitfPromptToDownloadCorsImage } from '@common/libs/bitforce/utils/bitf-files.utils';

@Component({
  selector: 'mpa-archive-image-manager',
  templateUrl: './archive-image-manager.component.html',
  styleUrls: ['./archive-image-manager.component.scss'],
})
export class ArchiveImageManagerComponent implements OnInit {
  @ViewChild(FileUploaderComponent) fileUploader: FileUploaderComponent;
  get selectedItem(): Archive {
    return this.storeService.store.selectedItem;
  }

  eRoleActions = ERoleActions;
  constructor(
    public uiRoleManagerService: UiRoleManagerService,
    private loaderService: LoaderService,
    private translateService: TranslateService,
    private imagesService: ImagesService,
    private archiveService: ArchivesService,
    private filesService: FilesService,
    public storeService: StoreService
  ) {}

  ngOnInit(): void {}

  onDownloadImage(image: Image) {
    bitfPromptToDownloadCorsImage({ fileUrl: image.url, fileName: image.name });
  }

  onRemoveImage(image: Image) {
    if (!window.confirm(this.translateService.instant('ITEM.DETAIL.CONFIRM_REMOVING_IMAGE'))) {
      return;
    }
    this.loaderService.show();
    this.imagesService
      .delete({ id: image.id })
      .pipe(switchMap(_ => this.archiveService.getItemById({ id: this.storeService.store.selectedItem.id })))
      .subscribe((response: IBitfApiResponse<Archive>) => {
        this.storeService.updateSelectedItem({ images: response.content.images });
      });
  }

  onUpload() {
    const images: BitfFile[] = Array.from(this.fileUploader.uploadableFiles);

    const observableList = images.map(image =>
      this.filesService.createImage({
        variables: { file: image },
        context: { useMultipart: true },
        disableHideLoader: true,
      })
    );

    if (observableList.length > 0) {
      this.loaderService.show();
      this.filesService
        .getThrottledObservables(observableList)
        .pipe(
          switchMap((responses: IBitfGraphQlResponse<Image>[]) => {
            const updateArchive = new Archive(undefined, {
              id: this.selectedItem.id,
              images: this.selectedItem.images,
            });
            updateArchive.images.push(...responses.map(response => response.content));
            return this.archiveService.updateItem({
              disableHideLoader: true,
              body: updateArchive,
            });
          }),
          tap((response: IBitfGraphQlResponse<Archive>) => {
            this.storeService.updateSelectedItem(response.content);
          }),
          finalize(() => {
            this.loaderService.hide();
            this.fileUploader.removeUploadedFiles();
          })
        )
        .subscribe();
    }
  }
}
