import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Sale } from '@common/core/models';

@Component({
  selector: 'mpa-create-update-sale',
  templateUrl: './create-update-sale.component.html',
  styleUrls: ['./create-update-sale.component.scss'],
})
export class CreateUpdateSaleComponent implements OnInit {
  @Input() sale: Sale;
  @Input() isReadOnly: boolean;
  public form: UntypedFormGroup;

  constructor() {}

  ngOnInit(): void {
    this.initForm();
  }

  private initForm() {
    this.form = new UntypedFormGroup({
      name: new UntypedFormControl({ value: undefined, disabled: this.isReadOnly }, Validators.required),
      reference: new UntypedFormControl({ value: undefined, disabled: this.isReadOnly }),
      date: new UntypedFormControl({ value: undefined, disabled: this.isReadOnly }, [Validators.required]),
      note: new UntypedFormControl({ value: undefined, disabled: this.isReadOnly }),
    });

    if (this.sale) {
      this.form.patchValue(this.sale);
    } else {
      this.form.patchValue({
        date: new Date(),
      });
    }
  }
}
