import { bitfFormatDateISO } from '@common/libs/bitforce/utils/bitf-dates.utils';

import { ArchiveSale } from './archive-sale.model';
import { SuperStrapiModel } from './super-strapi.model';

export class Sale extends SuperStrapiModel {
  name: string;
  date: Date;
  note: string;
  reference: string;
  archive_sales: ArchiveSale[];

  get totalSales() {
    return this.archive_sales.reduce((acc, archiveSale) => acc + (archiveSale?.archive?.quantity || 1), 0);
  }

  get totalValue() {
    return this.archive_sales.reduce(
      (acc, archiveSale) => acc + (archiveSale?.archive?.quantity * archiveSale?.archive?.value || 0),
      0
    );
  }

  constructor(data, modelInstance?: Partial<Sale>) {
    super(data, modelInstance);

    if (modelInstance) {
      if (this.date !== null && this.date instanceof Date === false) {
        this.date = new Date(this.date);
      }
      return;
    }

    if (data.attributes?.archive_sales?.data) {
      this.archive_sales = data.attributes.archive_sales.data.map(
        archiveSale => new ArchiveSale(archiveSale)
      );
    }

    if (data.attributes?.date) {
      this.date = new Date(data.attributes?.date);
    }
  }

  get serialised() {
    return {
      id: this.id,
      name: this.name,
      date: this.date && bitfFormatDateISO(this.date),
      note: this.note,
      reference: this.reference,
      archive_sales: !!this.archive_sales
        ? this.archive_sales.map(archiveSale => archiveSale.serialised)
        : this.archive_sales,
    };
  }
}
