import { NgModule, ModuleWithProviders, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { DatePipe } from '@angular/common';
import { RouterModule } from '@angular/router';
import { CommonSharedModule } from '@common/shared/common-shared.module';
import { MATERIAL_MODULES, CDK_MODULES } from './material-modules';
import { ScrollingModule } from '@angular/cdk/scrolling';

import { BitfLoaderComponent } from '@bitf/core/components/ui/loader/bitf-loader.component';
import { BitfMatPaginatorComponent } from '@bitf/core/components/ui/paginator/material/bitf-mat-paginator.component';
import { BitfMatSortComponent } from '@bitf/core/components/ui/sort/material/bitf-mat-sort.component';
import { BitfMatSearchComponent } from '@bitf/core/components/ui/search/material/bitf-mat-search.component';
import { BitfUiRoleManagerDirective } from '@common/libs/bitforce/directives/ui-role-manager/bitf-ui-role-manager.directive';
import { BitfTouchEventDirective } from '@common/libs/bitforce/directives/bitf-touch-events/bitf-touch-event.directive';
import { BitfMatOkCancelDialogComponent } from '@common/libs/bitforce/core/components/ui/ok-cancel-dialog/material/bitf-mat-ok-cancel-dialog.component';
import { BitfMatLoaderComponent } from '@bitf/core/components/ui/loader/material/bitf-mat-loader/bitf-mat-loader.component';
import { BitfMatInfiniteScrollTriggerComponent } from '@common/libs/bitforce/core/components/ui/infinite-scroll/material/bitf-mat-infinite-scroll-trigger/bitf-mat-infinite-scroll-trigger.component';
import { ItemImagesComponent } from './item-images/item-images.component';
import { ViewUpdateItemDialogComponent } from '@web/shared/view-update-item-dialog/view-update-item-dialog.component';
import { ItemTypeSelectorComponent } from './item-type-selector/item-type-selector.component';
import { DynamicGallerySortsComponent } from './dynamic-gallery-sorts/dynamic-gallery-sorts.component';
import { CreateLoanDialogComponent } from './create-loan-dialog/create-loan-dialog.component';
import { CreateSaleDialogComponent } from './create-sale-dialog/create-sale-dialog.component';
import { CreateUpdateLoanComponent } from './create-update-loan/create-update-loan.component';
import { CreateUpdateFolderDialogComponent } from './create-update-folder-dialog/create-update-folder-dialog.component';
import { CreateUpdateSaleComponent } from './create-update-sale/create-update-sale.component';
import { LinkSuitComponent } from './link-suit/link-suit.component';
import { LinkItemsComponent } from './link-items/link-items.component';
import { LinkExternalsComponent } from './link-externals/link-externals.component';
import { LinkLookbooksComponent } from './link-lookbooks/link-lookbooks.component';
import { BulkActionsToolbarComponent } from './bulk-actions-toolbar/bulk-actions-toolbar.component';
import { BulkCopyIntoFolderDialogComponent } from './bulk-copy-into-folder-dialog/bulk-copy-into-folder-dialog.component';
import { BulkMoveIntoFolderDialogComponent } from './bulk-move-into-folder-dialog/bulk-move-into-folder-dialog.component';
import { BulkUpdateItemsDialogComponent } from './bulk-update-items-dialog/bulk-update-items-dialog.component';
import { LinkedItemRowComponent } from './linked-item-row/linked-item-row.component';
import { CopyDataOnLinkComponent } from './copy-data-on-link/copy-data-on-link.component';
import { ArchiveImageManagerComponent } from './archive-image-manager/archive-image-manager.component';
import { BulkAddToLoanDialogComponent } from './bulk-add-to-loan-dialog/bulk-add-to-loan-dialog.component';
import { BulkAddToSaleDialogComponent } from './bulk-add-to-sale-dialog/bulk-add-to-sale-dialog.component';
import { ItemsGalleryComponent } from './items-gallery/items-gallery.component';
import { ItemCardComponent } from './item-card/item-card.component';
import { DynamicFilterPanelComponent } from './dynamic-filter-panel/dynamic-filter-panel.component';
import { HeaderComponent } from './header/header.component';
import { BitfDatePipe } from '@common/libs/bitforce/pipes/bitf-date.pipe';
import { SuperListComponent } from './super-list/super-list.component';
import { FileUploaderComponent } from './file-uploader/file-uploader.component';
import { BitfDndFileComponent } from '@common/libs/bitforce/core/components/ui/dnd-file';
import { BitfBytesPipe } from '@common/libs/bitforce/pipes/bitf-bytes.pipe';
import { DynamicItemDataFormComponent } from './dynamic-item-data-form/dynamic-item-data-form.component';
import { ItemDetailsTabsComponent } from './item-details-tabs/item-details-tabs.component';
import { BulkRemoveFromFolderDialogComponent } from './bulk-remove-from-folder-dialog/bulk-remove-from-folder-dialog.component';
import { ItemImagesGalleryComponent } from './item-images-gallery/item-images-gallery.component';
import { BitfJoinArrayPipe } from '@common/libs/bitforce/pipes/bitf-join-array.pipe';
import { DynamicPrintDialogComponent } from './dynamic-print-dialog/dynamic-print-dialog.component';
import { QrScannerComponent } from './qr-scanner/qr-scanner.component';
import { ItemDetailsComponent } from './item-details/item-details.component';
import { SelectPrintPageDialogComponent } from './select-print-page-dialog/select-print-page-dialog.component';
import { BitfMatFormItemComponent } from '@common/libs/bitforce/core/components/ui/form-item/material/mat-form-item/bitf-mat-form-item.component';
import { BitfMatFormOptionComponent } from '@common/libs/bitforce/core/components/ui/form-item/material/mat-form-option/bitf-mat-form-option.component';
import { DuplicateArchiveComponent } from './duplicate-archive/duplicate-archive.component';
import { DateAdapter } from '@angular/material/core';
import { DateAdapterService } from '@common/core/services';
import { DynamicPrintFoldersDialogComponent } from './dynamic-print-folders-dialog/dynamic-print-folders-dialog.component';
import { DynamicPrintLoansDialogComponent } from './dynamic-print-loans-dialog/dynamic-print-loans-dialog.component';
import { DynamicPrintSalesDialogComponent } from './dynamic-print-sales-dialog/dynamic-print-sales-dialog.component';
import { ViewNeighboursComponent } from './view-neighbours/view-neighbours.component';
import { BulkUpdateItemsLoanDialogComponent } from './bulk-update-items-loan-dialog/bulk-update-items-loan-dialog.component';
import { BulkUpdateItemsSaleDialogComponent } from './bulk-update-items-sale-dialog/bulk-update-items-sale-dialog.component';
import { QuantitySaleDialogComponent } from './quantity-sale-dialog/quantity-sale-dialog.component';

const SHARED_MODULES = [CommonSharedModule, RouterModule, ScrollingModule, MATERIAL_MODULES, CDK_MODULES];
const SHARED_COMPONENTS = [
  BitfLoaderComponent,
  BitfMatPaginatorComponent,
  BitfUiRoleManagerDirective,
  BitfTouchEventDirective,
  BitfMatOkCancelDialogComponent,
  BitfMatLoaderComponent,
  BitfMatSortComponent,
  BitfMatSearchComponent,
  BitfMatInfiniteScrollTriggerComponent,
  BitfDndFileComponent,
  BitfMatFormItemComponent,
  BitfMatFormOptionComponent,
  ViewUpdateItemDialogComponent,
  ItemDetailsTabsComponent,
  ItemTypeSelectorComponent,
  CreateUpdateFolderDialogComponent,
  DynamicGallerySortsComponent,
  LinkSuitComponent,
  CreateLoanDialogComponent,
  CreateSaleDialogComponent,
  CreateUpdateLoanComponent,
  CreateUpdateSaleComponent,
  QuantitySaleDialogComponent,
  ItemImagesGalleryComponent,
  ItemImagesComponent,
  LinkItemsComponent,
  LinkExternalsComponent,
  LinkLookbooksComponent,
  BulkActionsToolbarComponent,
  BulkCopyIntoFolderDialogComponent,
  BulkMoveIntoFolderDialogComponent,
  BulkUpdateItemsDialogComponent,
  BulkUpdateItemsLoanDialogComponent,
  BulkUpdateItemsSaleDialogComponent,
  BulkRemoveFromFolderDialogComponent,
  LinkedItemRowComponent,
  ArchiveImageManagerComponent,
  CopyDataOnLinkComponent,
  DuplicateArchiveComponent,
  BulkAddToLoanDialogComponent,
  BulkAddToSaleDialogComponent,
  ItemsGalleryComponent,
  ItemCardComponent,
  DynamicFilterPanelComponent,
  HeaderComponent,
  SuperListComponent,
  FileUploaderComponent,
  DynamicItemDataFormComponent,
  DynamicPrintDialogComponent,
  DynamicPrintFoldersDialogComponent,
  DynamicPrintLoansDialogComponent,
  DynamicPrintSalesDialogComponent,
  QrScannerComponent,
  ItemDetailsComponent,
  SelectPrintPageDialogComponent,
  ViewNeighboursComponent,
];
const SHARED_DIRECTIVES = [];
const SHARED_PIPES = [BitfDatePipe, BitfBytesPipe, BitfJoinArrayPipe];
const PROVIDERS = [DatePipe, { provide: DateAdapter, useClass: DateAdapterService }];

@NgModule({
  imports: [...SHARED_MODULES],
  exports: [...SHARED_MODULES, ...SHARED_COMPONENTS, ...SHARED_DIRECTIVES, ...SHARED_PIPES],
  declarations: [...SHARED_COMPONENTS, ...SHARED_DIRECTIVES, ...SHARED_PIPES],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [...PROVIDERS],
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [],
    };
  }
}
