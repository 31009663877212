import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ArchiveSale } from '@common/core/models';
import { ArchiveSalesService, LoaderService } from '@common/core/services';
import { EBitfCloseEventStatus, ERoleActions } from '@common/enums';
import { IBitfCloseEvent, IBulkUpdateItemsSaleDialogData } from '@common/interfaces';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'mpa-bulk-update-items-sale-dialog',
  templateUrl: './bulk-update-items-sale-dialog.component.html',
  styleUrls: ['./bulk-update-items-sale-dialog.component.scss'],
})
export class BulkUpdateItemsSaleDialogComponent implements OnInit {
  public form: UntypedFormGroup;
  eRoleActions = ERoleActions;

  constructor(
    public dialogRef: MatDialogRef<BulkUpdateItemsSaleDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: IBulkUpdateItemsSaleDialogData,
    private archiveSalesService: ArchiveSalesService,
    private loaderService: LoaderService
  ) {}

  ngOnInit(): void {
    this.initForm();
  }

  private initForm() {
    this.form = new UntypedFormGroup({
      note_out: new UntypedFormControl(undefined),
    });
  }

  onSave() {
    this.loaderService.show();
    const bulkItem = new ArchiveSale(undefined, this.form.value);
    bulkItem.prepareForBulkUpdate();

    const toUpdate = this.dialogData.bulkItemsIds.map(id => {
      return this.archiveSalesService.updateItem({
        disableHideLoader: true,
        body: bulkItem,
        id,
      });
    });

    this.archiveSalesService
      .getThrottledObservables(toUpdate)
      .pipe(finalize(() => this.loaderService.hide()))
      .subscribe(() => {
        this.dialogRef.close({
          status: EBitfCloseEventStatus.OK,
        } as IBitfCloseEvent<void>);
      });
  }

  onClose() {
    this.dialogRef.close({
      status: EBitfCloseEventStatus.CANCEL,
    } as IBitfCloseEvent<void>);
  }
}
