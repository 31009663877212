<form (ngSubmit)="onLink()"
  [formGroup]="form"
  class="mb-4"
  *ngIf="uiRoleManagerService.canI(eRoleActions.EDIT_ITEM)">
  <h4>{{'ITEM.DETAIL.LINK_NEW_EXTERNAL' | translate}}</h4>

  <div class="d-flex align-items-center gap-2">
    <mat-form-field appearance="outline"
      class="form-field-condensed">
      <mat-label>{{'COMMON.LABEL.LINK' | translate}}</mat-label>
      <input matInput
        placeholder="https://www.google.com"
        formControlName="link">
    </mat-form-field>

    <button mat-raised-button
      color="primary"
      [disabled]="!form.valid"
      type="submit">
      {{'COMMON.LABEL.ADD_LINK' | translate}}
    </button>
  </div>
</form>

<div class="items-list"
  *ngIf="selectedItem.external_links?.length">
  <h4>{{'ITEM.DETAIL.LINKED_EXTERNALS' | translate}}</h4>

  <div class="border-bottom d-flex justify-content-between py-1"
    *ngFor="let item of selectedItem.external_links">
    <div class="d-flex align-items-center">
      <div class="d-flex justify-content-between">
        <div class="d-flex flex-column">
          <span>{{item}}</span>
        </div>
      </div>
    </div>

    <div class="d-flex align-items-center justify-content-end">
      <button mat-icon-button
        *ngIf="uiRoleManagerService.canI(eRoleActions.EDIT_ITEM)"
        color="primary"
        class="mr-3"
        (click)="onRemoveLink(item)">
        <mat-icon>delete</mat-icon>
      </button>

      <a [href]="item"
        target="_blank">
        <button mat-icon-button
          color="primary"
          [matTooltip]="'COMMON.LABEL.OPEN_LINK' | translate">
          <mat-icon>visibility</mat-icon>
        </button>
      </a>
    </div>
  </div>
</div>

<ng-container *ngIf="!selectedItem?.external_links?.length">
  <h4>{{'ITEM.DETAIL.EMPTY_ITEM_EXTERNAL_LINKS' | translate}}</h4>
</ng-container>