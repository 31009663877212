import { AfterViewInit, Component, ElementRef, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { marker as bitfToTranslate } from '@biesbjerg/ngx-translate-extract-marker';

import QrScanner from 'qr-scanner';

import { StoreService } from '@common/core/services';
import { BitfMetadataService } from '@common/libs/bitforce/core/services/metadata/bitf-metadata.service';

@Component({
  selector: 'mpa-qr-scanner',
  templateUrl: './qr-scanner.component.html',
  styleUrls: ['./qr-scanner.component.scss'],
})
export class QrScannerComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('videoElem') videoElem: ElementRef<HTMLVideoElement>;

  qrCodeScanner: QrScanner;
  scannedQrCode: string;
  subscriptions = new Subscription();
  form = new FormGroup({ inventory: new FormControl(null, [Validators.required]) });
  showCamera = false;

  constructor(
    public storeService: StoreService,
    private router: Router,
    private _ngZone: NgZone,
    private bitfMetadataService: BitfMetadataService
  ) {}

  ngOnInit(): void {
    this.bitfMetadataService.setPageTitle(bitfToTranslate('QR_SCANNER.PAGE_TITLE'));
  }

  ngAfterViewInit(): void {
    this.qrCodeScanner = new QrScanner(
      this.videoElem.nativeElement,
      (result: QrScanner.ScanResult) => {
        this.onScanResult(result);
      },
      {
        onDecodeError: () => {},
        highlightScanRegion: true,
      }
    );
  }

  onSearchInventory() {
    this.openItemDetails(this.form.value.inventory);
  }

  onScan() {
    this.showCamera = true;
    this.qrCodeScanner.start();
  }

  onCloseCamera() {
    this.showCamera = false;
    this.qrCodeScanner.stop();
  }

  onScanResult(result: QrScanner.ScanResult) {
    if (!result.data) {
      return;
    }

    this.scannedQrCode = new URL(result.data).searchParams.get('inventory');
    this._ngZone.run(() => {
      this.openItemDetails(this.scannedQrCode);
    });
  }

  openItemDetails(inventory: string) {
    this.router.navigate(['/item'], { queryParams: { inventory } });
  }

  ngOnDestroy(): void {
    this.qrCodeScanner.stop();
    this.qrCodeScanner.destroy();
    this.subscriptions.unsubscribe();
  }
}
