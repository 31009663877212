import { QueryOptions, MutationOptions } from '@apollo/client/core';
import { Observable, of, throwError } from 'rxjs';

import { StoreService } from '@services';
import { BITF_CONFIGS } from '@configs';
import {
  IBitfGraphQlRequest,
  IBitfGraphQlResponse,
  IBitfGraphQlRequestMapper,
  IBitfGraphQlEnvelopeMapper,
  IBitfGraphQlResponseMapper,
  IBitfUiMessages,
  IBitfApiUiMessage,
} from '@interfaces';

export class BitfGraphQlHelper {
  constructor(private storeService: StoreService) {}

  // REQUEST QUERY
  mapQueryRequestParams(requestParams: IBitfGraphQlRequest): QueryOptions {
    return (this.getParser(requestParams, 'requestMapper') as IBitfGraphQlRequestMapper).mapQueryRequest(
      requestParams
    );
  }

  // REQUEST MUTATION
  mapMutationRequestParams(requestParams: IBitfGraphQlRequest): MutationOptions {
    return (this.getParser(requestParams, 'requestMapper') as IBitfGraphQlRequestMapper).mapMutationRequest(
      requestParams
    );
  }

  // ENVELOPE
  mapEnvelope(requestParams: IBitfGraphQlRequest, graphQlResponse: any): IBitfGraphQlResponse<any> {
    const envelopedResponse: IBitfGraphQlResponse<unknown> = (
      this.getParser(requestParams, 'envelopeMapper') as IBitfGraphQlEnvelopeMapper
    ).map(requestParams, graphQlResponse);

    return envelopedResponse;
  }

  // RESPONSE QUERY
  // TODO if possible add an interface for graphQlResponse
  mapQueryResponse<T>(
    requestParams: IBitfGraphQlRequest,
    envelopedResponse: IBitfGraphQlResponse<any>
  ): IBitfGraphQlResponse<T> {
    const responseMapped = (
      this.getParser(requestParams, 'responseMapper') as IBitfGraphQlResponseMapper
    ).mapQueryResponse<T>(requestParams, envelopedResponse);

    return responseMapped as IBitfGraphQlResponse<T>;
  }

  // TODO if possible add an interface for graphQlResponse
  mapMutationResponse<T>(
    requestParams: IBitfGraphQlRequest,
    envelopedResponse: IBitfGraphQlResponse<any>
  ): IBitfGraphQlResponse<T> {
    // this.showUiMessages(envelopedResponse);

    const responseMapped = (
      this.getParser(requestParams, 'responseMapper') as IBitfGraphQlResponseMapper
    ).mapMutationResponse<T>(requestParams, envelopedResponse);

    return responseMapped as IBitfGraphQlResponse<T>;
  }

  handleApolloErrors$(apolloError: Error) {
    // FIXME: in case of http error, both interceptor and ApolloErorr are raised
    // material is not able to show multiple toasts at the same time, it closes the previous one
    // NOTE: we are creating a fake graphQl error response because we are here because an AppolloError
    // exception have been catch
    return of({
      errors: [
        {
          message: apolloError.message,
        },
      ],
    });
  }

  handleUiMessages$(envelopedResponse: IBitfGraphQlResponse<any>) {
    envelopedResponse.metadata.uiMessages.forEach(uiMessage =>
      this.storeService.store.uiMessages$.next({
        type: 'BitfUiMessages',
        strategy: uiMessage.target,
        payload: uiMessage,
      } as IBitfUiMessages)
    );

    let errorsForLog;

    if (envelopedResponse.metadata.uiMessages.length) {
      errorsForLog = envelopedResponse.metadata.uiMessages
        .filter(uiMessage => uiMessage.type === 'ERROR')
        .map(error => error.message)
        .join(', ');
      if (errorsForLog) {
        console.log(errorsForLog);
        return throwError(new Error(errorsForLog));
      }
    }
    return of(envelopedResponse);
  }

  revertOptimisticUpdates$(error: Error, mutateThisObjectOptimistically, backupProps) {
    if (mutateThisObjectOptimistically) {
      Object.assign(mutateThisObjectOptimistically, backupProps);
    }
    return throwError(error);
  }

  private getParser(
    requestParams: IBitfGraphQlRequest,
    type: 'requestMapper' | 'envelopeMapper' | 'responseMapper'
  ): IBitfGraphQlRequestMapper | IBitfGraphQlEnvelopeMapper | IBitfGraphQlResponseMapper {
    const parserStrategyName = requestParams.apiParser || BITF_CONFIGS.parsers.defaultGraphQlParser;
    const parser = BITF_CONFIGS.parsers.parserStrategies[parserStrategyName];
    if (!parser) {
      throw new Error(`parser not present`);
    }
    return parser[type];
  }
}
