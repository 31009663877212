export enum EArchiveType {
  FASHION = 'fashion',
  MULTIMEDIA = 'multimedia',
  CELLAR = 'cellar',
}

export enum EShortAppName {
  MPA = 'MP',
  PVS = 'PVS',
  CEL = 'CEL',
}

export enum EAppSections {
  FOLDER = 'FOLDER',
  LOAN = 'LOAN',
  SALE = 'SALE',
  UPLOAD = 'UPLOAD',
  QR_SCANNER = 'QR_SCANNER',
}

export enum EItemTabs {
  IMAGES = 'images',
  SUIT = 'suit',
  LINKS = 'links',
  LOOKBOOK = 'lookbook',
  NEIGHBOURS = 'neighbours',
}
