import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Folder } from '@common/core/models';
import { LoaderService } from '@common/core/services';
import { FoldersService } from '@common/core/services/graph-ql/folders.service';

import { EBitfCloseEventStatus } from '@common/enums';
import { IBitfCloseEvent, IBitfGraphQlResponse, ICreateUpdateFolderDialogData } from '@common/interfaces';

@Component({
  selector: 'mpa-bulk-remove-from-folder-dialog',
  templateUrl: './bulk-remove-from-folder-dialog.component.html',
  styleUrls: ['./bulk-remove-from-folder-dialog.component.scss'],
})
export class BulkRemoveFromFolderDialogComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<BulkRemoveFromFolderDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: ICreateUpdateFolderDialogData,
    private foldersService: FoldersService,
    private loaderService: LoaderService
  ) {}

  ngOnInit(): void {}

  onCancel() {
    this.dialogRef.close({
      status: EBitfCloseEventStatus.CANCEL,
    } as IBitfCloseEvent<void>);
  }

  onOk() {
    const newFolder = new Folder({}, this.dialogData.folder);
    newFolder.archives = newFolder.archives.filter(
      archive => !this.dialogData.items.find(_archive => _archive.id === archive.id)
    );

    this.loaderService.show();
    this.foldersService
      .updateItem({
        body: newFolder,
      })
      .subscribe((response: IBitfGraphQlResponse<Folder>) => {
        this.dialogRef.close({
          status: EBitfCloseEventStatus.OK,
          data: response.content,
        } as IBitfCloseEvent<Folder>);
      });
  }
}
