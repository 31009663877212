import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Archive, ArchiveSale, Sale } from '@common/core/models';
import {
  ArchiveSalesService,
  ArchivesService,
  LoaderService,
  SalesService,
  StoreService,
} from '@common/core/services';
import { EBitfApiSortDirection, EBitfCloseEventStatus, EBitfUiMessageType } from '@common/enums';
import { IBitfCloseEvent, IBitfGraphQlResponse, ICreateUpdateSaleDialogData } from '@common/interfaces';
import { marker as bitfToTranslate } from '@biesbjerg/ngx-translate-extract-marker';
import { TranslateService } from '@ngx-translate/core';
import { ToastMessagesService } from '@web/core/services';
import { tap, finalize, switchMap } from 'rxjs/operators';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';

@Component({
  selector: 'mpa-bulk-add-to-sale-dialog',
  templateUrl: './bulk-add-to-sale-dialog.component.html',
  styleUrls: ['./bulk-add-to-sale-dialog.component.scss'],
})
export class BulkAddToSaleDialogComponent implements OnInit {
  public form: UntypedFormGroup;
  public sales: Sale[] = [];
  public filteredSales: Sale[] = [];
  public selectedSale: Sale;

  constructor(
    public dialogRef: MatDialogRef<BulkAddToSaleDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: ICreateUpdateSaleDialogData,
    private archiveSalesService: ArchiveSalesService,
    private archivesService: ArchivesService,
    private salesService: SalesService,
    private toastMessagesService: ToastMessagesService,
    private translateService: TranslateService,
    private loaderService: LoaderService,
    private storeService: StoreService
  ) {}

  ngOnInit(): void {
    this.initForm();
    this.initSalesList();
  }

  onSelectSale(event: MatAutocompleteSelectedEvent) {
    this.selectedSale = event.option.value;
  }

  onSave() {
    const sale = this.form.value.sale as Sale;

    const validItems = [...this.dialogData.items].filter(item => !!item.quantity);

    if (!validItems.length) {
      this.dialogRef.close({
        status: EBitfCloseEventStatus.OK,
      } as IBitfCloseEvent<void>);
      return;
    }

    const observableList = validItems.map(item => {
      const archive = new Archive(undefined, {
        ...item,
        value: item.value || 0,
        quantity: item.selectedQuantity,
        is_sold: true,
        sale,
      });

      return this.archivesService.duplicateArchive(archive, true).pipe(
        switchMap((updatedArchiveResponse: IBitfGraphQlResponse<Archive>) => {
          const archiveSale = new ArchiveSale(undefined, {
            archive: updatedArchiveResponse.content,
            sale,
          });
          return this.archiveSalesService.createItem({
            body: archiveSale,
            disableHideLoader: true,
          });
        }),
        switchMap(_ => {
          let quantity = !item.quantity || item.quantity === null ? null : item.quantity;
          if (quantity != null) {
            quantity = quantity < item.selectedQuantity ? 0 : quantity - item.selectedQuantity;
          }

          return this.archivesService.updateItem({
            id: item.id,
            body: new Archive(undefined, {
              id: item.id,
              quantity,
            }),
            disableHideLoader: true,
          });
        }),
        // NOTE: we've to update the gallery items with the new archive_sales
        // to eventually update the quantity
        tap((r: IBitfGraphQlResponse<Archive>) => {
          this.storeService.updateGalleryItem({ id: r.content.id, disableHideLoader: true });
        })
      );
    });

    this.loaderService.show();
    this.archiveSalesService
      .getThrottledObservables(observableList)
      .pipe(
        finalize(() => {
          this.loaderService.hide();

          this.toastMessagesService.show({
            title: this.translateService.instant(bitfToTranslate('SALE.ITEMS_ADDED'), {
              sale: this.form.value.sale.name,
            }),
            type: EBitfUiMessageType.SUCCESS,
          });

          this.dialogRef.close({
            status: EBitfCloseEventStatus.OK,
          } as IBitfCloseEvent<void>);
        })
      )
      .subscribe();
  }

  onClose() {
    this.dialogRef.close({
      status: EBitfCloseEventStatus.CANCEL,
    } as IBitfCloseEvent<void>);
  }

  displayFn(sale): string {
    return sale?.name ? `ID:${sale.id} - ${sale.name}` : '';
  }

  onClearSaleInput() {
    this.form.patchValue({ sale: '' }, { emitEvent: false });
    this.filteredSales = this.sales.filter(option => option.name.toLowerCase().includes(''));
  }

  private initForm() {
    this.form = new UntypedFormGroup({
      sale: new UntypedFormControl(undefined, Validators.required),
    });

    this.form.controls.sale.valueChanges.subscribe(saleSearch => {
      if (typeof saleSearch === 'string') {
        this.filteredSales = this.sales.filter(
          option =>
            option.name.toLowerCase().includes(saleSearch.toLowerCase()) ||
            option.id.toString().includes(saleSearch.toLowerCase())
        );
        if (this.filteredSales.length === 1) {
          this.selectedSale = this.filteredSales[0];
          this.form.patchValue({ sale: this.selectedSale }, { emitEvent: false });
        } else {
          this.selectedSale = undefined;
        }
      } else if (saleSearch instanceof Sale) {
        // If the sale is selected clicking in the list
        this.selectedSale = this.filteredSales[0];
      }
    });
  }

  private initSalesList() {
    this.salesService
      .getList({
        page: 0,
        size: 100000,
        sorting: [
          {
            property: 'createdAt',
            direction: EBitfApiSortDirection.DESC,
          },
        ],
      })
      .subscribe(res => {
        this.sales = res.content;
        this.filteredSales = [...res.content];
      });
  }
}
