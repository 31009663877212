import { SuperStrapiModel } from './super-strapi.model';

export class Image extends SuperStrapiModel {
  name: string;
  // Present if the media it's image
  formats?: {
    // Default format used from strapi UI
    thumbnail: ImageFormat;
    xsmall: ImageFormat;
    small: ImageFormat;
    medium: ImageFormat;
    large: ImageFormat;
    xlarge: ImageFormat;
  };
  width: number;
  height: number;
  ext: string;
  placeholder: string;
  url: string;
  zoomImage: ImageFormat;

  constructor(data?: any) {
    if (!data) {
      data = placeholderImageData;
    }
    super(data);

    if (this.formats) {
      this.formats = {
        thumbnail: new ImageFormat(this.formats.thumbnail),
        xsmall: new ImageFormat(this.formats.xsmall),
        small: new ImageFormat(this.formats.small),
        medium: new ImageFormat(this.formats.medium),
        large: new ImageFormat(this.formats.large),
        xlarge: new ImageFormat(this.formats.xlarge),
      };
    }

    this.zoomImage = this.getImageByFormat('xlarge');
  }

  getImageByFormat(_size: string = 'xlarge'): ImageFormat {
    const sizes = ['xlarge', 'large', 'medium', 'small', 'xsmall', 'thumbnail'];
    const checkSizes = sizes.slice(sizes.indexOf(_size));

    const bestImageSize = checkSizes.find(size => this.formats && this.formats[size]?.url);
    let bestImage: ImageFormat;
    if (bestImageSize && this.formats[bestImageSize]) {
      bestImage = this.formats[bestImageSize];
    } else if (this.url?.length) {
      bestImage = new ImageFormat({ url: this.url });
    } else {
      bestImage = new ImageFormat({ url: '/assets/img/placeholder-image.png' });
    }
    return bestImage;
  }

  get serialised() {
    return {
      id: this.id,
      name: this.name,
    };
  }
}

export class ImageFormat {
  url: string;
  ext?: string;
  hash?: string;
  mime?: 'image/jpeg' | '';
  name?: string;
  path?: string;
  size?: number;
  width?: number;
  height?: number;

  get isLandscape(): boolean {
    // NOTE: thumbnail is always present
    if (this.width > this.height) {
      return true;
    }
    return false;
  }
  get isPortrait(): boolean {
    if (this.height > this.width) {
      return true;
    }
    return false;
  }
  get isSquare(): boolean {
    if (this.height === this.width) {
      return true;
    }
    return false;
  }

  getResizedSizes({ width, height, widthCap }: { width?: number; widthCap?: number; height?: number }): {
    width: number;
    height: number;
  } {
    const sizes = { width: 100, height };

    if (height) {
      sizes.height = height;
      sizes.width = (height / this.height) * this.width;
      // NOTE: in this case the calculated width is too much fixing the height(for landscape picture)
      // so we resize setting the width to the widthCap
      if (widthCap && sizes.width > widthCap) {
        if (sizes.width > widthCap) {
          sizes.width = widthCap;
          sizes.height = (widthCap / this.width) * this.height;
        }
      }
    }

    return sizes;
  }

  constructor(data) {
    Object.assign(this, data);
  }
}

const placeholderImageData = {
  id: 'placeholder',
  attributes: {
    name: 'placeholder',
    formats: {
      large: {
        ext: '.png',
        url: 'assets/img/placeholder-image.png',
        width: 800,
        height: 600,
      },
      small: {
        ext: '.png',
        url: location.protocol + '//' + location.host + '/assets/img/placeholder-image.png',
        width: 800,
        height: 600,
      },
      medium: {
        ext: '.png',
        url: location.protocol + '//' + location.host + '/assets/img/placeholder-image.png',
        width: 800,
        height: 600,
      },
      xlarge: {
        ext: '.png',
        url: location.protocol + '//' + location.host + '/assets/img/placeholder-image.png',
        width: 800,
        height: 600,
      },
      xsmall: {
        ext: '.png',
        url: location.protocol + '//' + location.host + '/assets/img/placeholder-image.png',
        width: 800,
        height: 600,
      },
      thumbnail: {
        ext: '.png',
        url: location.protocol + '//' + location.host + '/assets/img/placeholder-image.png',
        width: 800,
        height: 600,
      },
    },
    width: 800,
    height: 600,
    ext: '.png',
    placeholder: '',
  },
};
