import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UiRoleManagerService } from '@common/core/services';
import { ERoleActions } from '@common/enums';
import { IPrintDialogData, IPrintData } from '@common/interfaces';
import { EArchiveType, EPrintType } from '@web/enums';
import { environment } from '@env/environment';
import { marker as bitfToTranslate } from '@biesbjerg/ngx-translate-extract-marker';
import { SuperPrint } from '@web/dynamic-modules/shared/print-strategies/super-print';
@Component({
  selector: 'mpa-dynamic-print-dialog',
  templateUrl: './dynamic-print-dialog.component.html',
  styleUrls: ['./dynamic-print-dialog.component.scss'],
})
export class DynamicPrintDialogComponent implements OnInit {
  printForm: FormGroup;
  eRoleActions = ERoleActions;
  ePrintType = EPrintType;
  printTypologyLabel: string;

  constructor(
    public uiRoleManagerService: UiRoleManagerService,
    public dialogRef: MatDialogRef<DynamicPrintDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: IPrintDialogData,
    private formBuilder: FormBuilder
  ) {}

  ngOnInit(): void {
    this.initForm();

    switch (environment.archiveType) {
      case EArchiveType.FASHION:
        this.printTypologyLabel = bitfToTranslate('FASHION.PRINT_TYPOLOGIES');
        break;
      case EArchiveType.MULTIMEDIA:
        this.printTypologyLabel = bitfToTranslate('MULTIMEDIA.PRINT_TYPOLOGIES');
        break;
      case EArchiveType.CELLAR:
        this.printTypologyLabel = bitfToTranslate('CELLAR.PRINT_TYPOLOGIES');
        break;
    }
  }

  async onPrint() {
    let component: SuperPrint = null;
    const { loan, sale, items, title } = this.dialogData;

    const {
      layout,
      showValue,
      showTitle,
      showNote,
      showTypologies,
      showImg,
      showAllImages,
      skipNumberOfLabels,
    } = this.printForm.value;

    const printData: IPrintData = {
      layout,
      items,
      loan,
      sale,
      title,
      showTitle,
      showValue,
      showNote,
      showTypologies,
      showImg,
      showAllImages,
      skipNumberOfLabels,
    };
    switch (layout) {
      case EPrintType.DETAILS:
        switch (environment.archiveType) {
          case EArchiveType.FASHION:
            component = new (
              await import('@web/dynamic-modules/fashion/print-strategies/fashion-print-details')
            ).FashionPrintDetails();
            break;
          case EArchiveType.MULTIMEDIA:
            component = new (
              await import('@web/dynamic-modules/multimedia/print-strategies/multimedia-print-details')
            ).MultimediaPrintDetails();
            break;
          case EArchiveType.CELLAR:
            component = new (
              await import('@web/dynamic-modules/cellar/print-strategies/cellar-print-details')
            ).CellarPrintDetails();
            break;
        }
        break;

      case EPrintType.GRID_1:
      case EPrintType.GRID_2_LANDSCAPE:
      case EPrintType.GRID_2:
      case EPrintType.GRID_3:
        switch (environment.archiveType) {
          case EArchiveType.FASHION:
            component = new (
              await import('@web/dynamic-modules/fashion/print-strategies/fashion-print-grid')
            ).FashionPrintGrid();
            break;
          case EArchiveType.MULTIMEDIA:
            component = new (
              await import('@web/dynamic-modules/multimedia/print-strategies/multimedia-print-grid')
            ).MultimediaPrintGrid();
            break;
          case EArchiveType.CELLAR:
            component = new (
              await import('@web/dynamic-modules/cellar/print-strategies/cellar-print-grid')
            ).CellarPrintGrid();
            break;
        }
        break;

      case EPrintType.LABEL:
        switch (environment.archiveType) {
          case EArchiveType.FASHION:
            component = new (
              await import('@web/dynamic-modules/fashion/print-strategies/fashion-print-label')
            ).FashionPrintLabel();
            break;
          case EArchiveType.MULTIMEDIA:
            component = new (
              await import('@web/dynamic-modules/multimedia/print-strategies/multimedia-print-label')
            ).MultimediaPrintLabel();
            break;
          case EArchiveType.CELLAR:
            component = new (
              await import('@web/dynamic-modules/cellar/print-strategies/cellar-print-label')
            ).CellarPrintLabel();
            break;
        }
        break;

      case EPrintType.LIST:
        switch (environment.archiveType) {
          case EArchiveType.FASHION:
            component = new (
              await import('@web/dynamic-modules/fashion/print-strategies/fashion-print-list')
            ).FashionPrintList();
            break;
          case EArchiveType.MULTIMEDIA:
            component = new (
              await import('@web/dynamic-modules/multimedia/print-strategies/multimedia-print-list')
            ).MultimediaPrintList();
            break;
          case EArchiveType.CELLAR:
            component = new (
              await import('@web/dynamic-modules/cellar/print-strategies/cellar-print-list')
            ).CellarPrintList();
            break;
        }
        break;

      case EPrintType.LOAN_LIST:
        switch (environment.archiveType) {
          case EArchiveType.FASHION:
            component = new (
              await import('@web/dynamic-modules/fashion/print-strategies/fashion-print-loan-list')
            ).FashionPrintLoanList();
            break;
          case EArchiveType.MULTIMEDIA:
            component = new (
              await import('@web/dynamic-modules/multimedia/print-strategies/multimedia-print-loan-list')
            ).MultimediaPrintLoanList();
            break;
          case EArchiveType.CELLAR:
            component = new (
              await import('@web/dynamic-modules/cellar/print-strategies/cellar-print-loan-list')
            ).CellarPrintLoanList();
            break;
        }
        break;

      case EPrintType.LOAN_DELIVERY_NOTE:
        switch (environment.archiveType) {
          case EArchiveType.FASHION:
            component = new (
              await import('@web/dynamic-modules/fashion/print-strategies/fashion-print-loan-delivery-note')
            ).FashionPrintLoanDeliveryNote();
            break;
          case EArchiveType.MULTIMEDIA:
            component = new (
              await import(
                '@web/dynamic-modules/multimedia/print-strategies/multimedia-print-loan-delivery-note'
              )
            ).MultimediaPrintLoanDeliveryNote();
            break;
          case EArchiveType.CELLAR:
            component = new (
              await import('@web/dynamic-modules/cellar/print-strategies/cellar-print-loan-delivery-note')
            ).CellarPrintLoanDeliveryNote();
            break;
        }
        break;

      case EPrintType.SALE_LIST:
        switch (environment.archiveType) {
          case EArchiveType.FASHION:
            component = new (
              await import('@web/dynamic-modules/fashion/print-strategies/fashion-print-sale-list')
            ).FashionPrintSaleList();
            break;
          case EArchiveType.MULTIMEDIA:
            component = new (
              await import('@web/dynamic-modules/multimedia/print-strategies/multimedia-print-sale-list')
            ).MultimediaPrintSaleList();
            break;
          case EArchiveType.CELLAR:
            component = new (
              await import('@web/dynamic-modules/cellar/print-strategies/cellar-print-sale-list')
            ).CellarPrintSaleList();
            break;
        }
        break;

      case EPrintType.SALE_DELIVERY_NOTE:
        switch (environment.archiveType) {
          case EArchiveType.FASHION:
            component = new (
              await import('@web/dynamic-modules/fashion/print-strategies/fashion-print-sale-delivery-note')
            ).FashionPrintSaleDeliveryNote();
            break;
          case EArchiveType.MULTIMEDIA:
            component = new (
              await import(
                '@web/dynamic-modules/multimedia/print-strategies/multimedia-print-sale-delivery-note'
              )
            ).MultimediaPrintSaleDeliveryNote();
            break;
          case EArchiveType.CELLAR:
            component = new (
              await import('@web/dynamic-modules/cellar/print-strategies/cellar-print-sale-delivery-note')
            ).CellarPrintSaleDeliveryNote();
            break;
        }
        break;
    }
    component.print(printData);

    this.dialogRef.close();
  }

  private initForm() {
    this.printForm = this.formBuilder.group({
      layout: [this.dialogData.actions[0], Validators.required],
      showTitle: [false],
      showValue: [false],
      showNote: [false],
      showTypologies: [false],
      showImg: [true],
      showAllImages: [false],
      skipNumberOfLabels: 0,
    });
  }
}
