<div class="items-list">
  <h4>{{'ITEM.DETAIL.NEIGHBOURS' | translate}}</h4>

  <span *ngIf="!isLoading && neighbours?.length === 0">
    {{ 'ITEM.DETAIL.NO_NEIGHBOURS' | translate}}
  </span>

  <div class="d-flex justify-content-center"
    *ngIf="isLoading">
    <mat-spinner class="mb-3"
      [diameter]="32"></mat-spinner>
  </div>

  <mpa-linked-item-row *ngFor="let item of neighbours"
    [showDeleteButton]="false"
    size="big"
    [isHighlighted]="item.id === selectedItem.id"
    [item]="item"></mpa-linked-item-row>
</div>