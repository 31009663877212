<div class="dialog-stretch">
  <div class="d-flex justify-content-between py-2 px-3">
    <h2 class="mb-0 mat-headline-6 w-100 d-flex align-items-center">
      {{ "ITEM.EDIT_BULK_TITLE" | translate: {count: dialogData.bulkItemsIds.length} }}
    </h2>

    <div>
      <button mat-icon-button
        class="ml-3"
        (click)="onClose()">
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </div>

  <mat-dialog-content>
    <mpa-dynamic-item-data-form
      [isBulkUpdate]="this.dialogData.bulkItemsIds?.length > 1"></mpa-dynamic-item-data-form>
  </mat-dialog-content>

  <mat-dialog-actions class="d-flex justify-content-between border-top mt-3">
    <div>
      <span class="mat-caption px-4">{{'COMMON.LABEL.BULK_UPDATE_INFOS' | translate}}</span>
    </div>

    <div>
      <mat-checkbox color="primary"
        class="mr-4"
        [(ngModel)]="concatChanges">
        <span class="toolbar-label">{{ 'COMMON.LABEL.BULK_UPDATE_CONCAT_CHANGES' | translate}}</span>
      </mat-checkbox>

      <button mat-flat-button
        color="primary"
        class="text-uppercase"
        (click)="onSave()">
        {{ 'ITEM.DETAIL.SAVE_CHANGES' | translate }}
      </button>
    </div>
  </mat-dialog-actions>
</div>