<div class="d-flex flex-column">

  <div class="d-flex justify-content-between p-3 form-density-2">
    <h2 class="d-flex align-items-center mat-headline-6 mb-0">{{ item?.description | translate }}
      <span class="mat-caption pl-3">
        {{ item?.inventory | translate}}
      </span>
    </h2>

    <div>
      <button mat-icon-button
        *ngIf="dialogData"
        (click)="dialogData.isZoom = !dialogData.isZoom">
        <mat-icon *ngIf="!dialogData.isZoom">zoom_out_map</mat-icon>
        <mat-icon *ngIf="dialogData.isZoom">zoom_in_map</mat-icon>
      </button>
      <button mat-icon-button
        [disabled]="buttonsDisabled"
        class="ml-3"
        (click)="onLoadPreviousItem()">
        <mat-icon>chevron_left</mat-icon>
      </button>
      <button mat-icon-button
        [disabled]="buttonsDisabled"
        (click)="onLoadNextItem()">
        <mat-icon>chevron_right</mat-icon>
      </button>
      <button mat-icon-button
        class="ml-3"
        (click)="onClose()">
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </div>

  <mat-dialog-content class="p-0"
    [ngClass]="{'zoom': dialogData?.isZoom}">
    <mpa-item-details [isZoom]="dialogData?.isZoom"
      [isReadOnly]="isReadOnly"
      *ngIf="storeService.store.selectedItem"></mpa-item-details>
  </mat-dialog-content>

  <mat-dialog-actions *ngIf="!dialogData.isZoom"
    class="border-top mt-3 justify-content-between">
    <ng-container *ngIf="!dialogData.isZoom">
      <div class="col-auto">
        <button mat-stroked-button
          class="mr-3 text-uppercase"
          (click)="onPrint(ePrintType.DETAILS)">
          {{ 'ITEM.DETAIL.PRINT_CARD' | translate }}
        </button>

        <button mat-stroked-button
          class=" text-uppercase"
          (click)="onPrint(ePrintType.LABEL)">
          {{ 'ITEM.DETAIL.PRINT_LABEL' | translate }}
        </button>
      </div>

      <div class="col-auto">
        <button mat-flat-button
          *ngIf="!isReadOnly && ((uiRoleManagerService.canI(eRoleActions.EDIT_ITEM) || uiRoleManagerService.canI(eRoleActions.EDIT_VALUE)) && itemDetailsComponent?.isItemFormsTab)"
          color="primary"
          class="text-uppercase"
          (click)="onSave()">
          {{ 'ITEM.DETAIL.SAVE_CHANGES' | translate }}
        </button>
      </div>
    </ng-container>
  </mat-dialog-actions>
</div>